import {Pipe, PipeTransform} from '@angular/core';
import {DateTime} from 'luxon';

@Pipe({
  standalone: true,
  name: 'moment'
})
export class MomentPipe implements PipeTransform {
  transform(value: DateTime, format: string): any {
      return value.toFormat(format);
  }
}
