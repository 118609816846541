<div *ngIf="false" xmlns="http://www.w3.org/1999/html">
  <input type="hidden" i18n-placeholder="@@__booking_success_title"
         placeholder="{{ '__booking_success_title' | translate}}">
  <input type="hidden" i18n-placeholder="@@__booking_success_title_confirmed"
         placeholder="{{ '__booking_success_title_confirmed' | translate}}">
  <input type="hidden" i18n-placeholder="@@__booking_finished"
         placeholder="{{ '__booking_finished' | translate}}">
  <input type="hidden" i18n-placeholder="@@__booking_verification"
         placeholder="{{ '__booking_verification' | translate}}">
  <input type="hidden" i18n-placeholder="@@__booking_success_doctena_link_title"
         placeholder="{{ '__booking_success_doctena_link_title' | translate}}">
  <input type="hidden" i18n-placeholder="@@__booking_finished"
         placeholder="{{ '__booking_finished' | translate}}">
</div>

<div class="formElement">
  <mat-card appearance="outlined" id="dba-booking-success-text" class="col-12 alert alert-success"
            *ngIf="!appointmentAlreadyAccepted">
    <mat-card-header>
      <mat-card-title>
        <h4>
          {{ '__booking_success_title' | translate }}
        </h4>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p i18n="@@__booking_success_description_first">
        {{ '__booking_success_description_first' | translate }}
      </p>
      <p i18n="@@__booking_success_description_second">
        {{ '__booking_success_description_second' | translate }}
      </p>
      <p i18n="@@__booking_success_description_third">
        {{ '__booking_success_description_third' | translate }}
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" id="dba-booking-success-confirmed-text" class="col-12 alert alert-success"
            *ngIf="appointmentAlreadyAccepted">
    <mat-card-header>
      <mat-card-title>
        <h4>
          {{ '__booking_success_title_confirmed' | translate }}
        </h4>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p i18n="@@__booking_confirmed_description">
        {{ '__booking_confirmed_description' | translate }}
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" class="col-12 alert alert-doctena booking-cpp" *ngIf="false">
    <div class="booking-cpp__text">
      <mat-card-header>
        <mat-card-title>
          <h4>
            {{ '__booking_success_doctena_link_title' | translate }}
          </h4>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p class="booking-cpp__description" i18n="@@__booking_success_doctena_link_text">
          {{ '__booking_success_doctena_link_text' | translate }}
        </p>
      </mat-card-content>
    </div>
    <mat-card-actions align="end">
    <a [href]="doctenaUrl" target="_blank">
      <button class="btn btn-success" i18n="@@__booking_success_doctena_link_button">
        {{ '__booking_success_doctena_link_button' | translate }}
      </button>
    </a>
    </mat-card-actions>
  </mat-card>
</div>
