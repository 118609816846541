import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {BingliService} from '../service/bingli/bingli.service';
import {PatientService} from '../service/patient/patient.service';
import {TranslateService} from '@ngx-translate/core';
import {BookingService} from '../service/booking/booking.service';
import {Patient} from '../models/patient';
import BlueBirdPromise from 'bluebird';

@Component({
  selector: 'app-bingli-form',
  templateUrl: './bingli-form.component.html',
  styleUrls: ['./bingli-form.component.scss']
})
export class BingliFormComponent implements OnInit {
  // Event emitter to provide the reason of visit bingli to the parent component
  @Output() reasonOfVisitBingli = new EventEmitter<boolean>;
  // Event emitter to notify the parent component that the survey is completed
  @Output() onSurveyComplete = new EventEmitter<boolean>;
  @Input() features: any;
  @Input() showBingliForm: boolean;
  @Input() preselectBingliRov = [];
  // Provide status when the booking rules are done
  @Input() isBookingRulesSelected: boolean;
  @Input() entityName: string;
  @Input() entityEid: string;
  @Input() featureCode: string;
  @Input() consentSignBy: string;
  @Input() consentSignContact: string;

  bingliFormUrl: SafeResourceUrl;
  bingliSurveyId: string;
  bingliDoctenaConsent = false;
  isConsentBingliProcessData = false;
  constructor(public bingliService: BingliService,
              public sanitizer: DomSanitizer,
              public patientService: PatientService,
              public translateService: TranslateService,
              public bookingService: BookingService) {
    this.showBingliForm = false;
  }

  ngOnInit(): void {
    this.consentSignContact = this.consentSignContact ? '(' + this.consentSignContact + ')' : '';
  }

  /**
   * Manage Bingli feature if the agenda has this feature
   * @param features
   * @private
   */
  private manageBingliFeature() {
    if (this.isConsentBingliProcessData && this.features.hasOwnProperty(this.featureCode) && this.features[this.featureCode].hasOwnProperty('client_ref')) {
      const patientInformation = Patient.patientDataToBingliAdaptor(this.patientService),
        body = {
          clientRef: this.features['triage']['client_ref'],
          patientInformation: patientInformation,
          filteredRov: this.preselectBingliRov.map((rov) => rov.triageKey),
          languageIso: this.translateService.currentLang.toLowerCase(),
          entityName: this.entityName,
          entityEid: this.entityEid,
          featureCode: this.featureCode
        };

      // POST to retrieve survey_id & URL from CPP
      this.bingliService.postBingliSurvey(body)
        .then(response => this.successCallBackPostBingliSurvey(response))
        .catch(error => console.error(error));
      window.addEventListener('message', (event) => {
        switch (event.data) {
          case 'survey:completed':
          case 'finished':
            this.onBingliSurveyCompleted();
            break;
          case 'stop_flow':
            window.location.reload();
            break;
          default:
            break;
        }
      });
    }
  }

  /**
   * When Bingli survey is completed, we retrieve patient data from Bingli API
   * @private
   */
  private onBingliSurveyCompleted() {
    this.showBingliForm = false;
    this.bingliService.getBingliSurvey(this.bingliSurveyId)
      .then(response => this.successCallBackGetBingliSurvey(response))
      .catch(error => console.error(error));
  }

  /**
   * On get Bingli survey request success
   * @param data
   * @private
   */
  private successCallBackGetBingliSurvey(data) {
    // Get patient data from Bingli API response
    if (data.patient) {
      const formattedData = Patient.patientDataToDoctenaAdaptor(data.patient);
      this.bookingService.setPatientData({...this.bookingService.patientData, ...formattedData});
    }
    this.reasonOfVisitBingli.emit(data);
    this.onSurveyComplete.emit(true);
  }

  /**
   * On post Bingli survey after consent request success
   * @param data
   * @private
   */
  private successCallBackPostBingliSurvey(data) {

    const tab: BlueBirdPromise<any>[] = [];
    // consent for process data by Bingli
    tab.push(this.bingliService.postConsentBingliSurvey(data.surveyId, {
      'consentType': 'process_data'
    }).catch(error => console.error(error)));

    // when all consent have been sent to Bingli, we open the Bingli form
    return BlueBirdPromise.all(tab).then(async () => {
      this.bingliSurveyId = data.surveyId;
      this.bookingService.setSurveyId(data.surveyId);
      this.bingliFormUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.patientInterviewUrl + '&pin=' + data.pin);
      this.showBingliForm = true;
    }).catch(error => console.error(error));
  }

  consentBingliDoctena() {
    this.bingliDoctenaConsent = true;
    this.manageBingliFeature();
  }

  consentBingliProcessData(event) {
    this.isConsentBingliProcessData = event.checked;
  }
}
