<div *ngIf="false" xmlns="http://www.w3.org/1999/html">
  <input type="hidden" i18n-placeholder="@@__file_upload_placeHolder"
         placeholder="{{ '__file_upload_placeHolder' | translate}}">
  <input type="hidden" i18n-placeholder="@@__file_upload_too_much_files"
         placeholder="{{ '__file_upload_too_much_files' | translate}}">
  <input type="hidden" i18n-placeholder="@@__file_upload_max_file_size"
         placeholder="{{ '__file_upload_max_file_size' | translate}}">
  <input type="hidden" i18n-placeholder="@@__attachment_label"
         placeholder="{{ '__attachment_label' | translate}}">
</div>

<form [formGroup]="uploadGroup">
    <div>
      <p *ngIf="placeholder" class="placeholder-file-upload">
        <b>{{ placeholder | translate }}
        </b></p>
      <p *ngIf="description" class="description-file-upload">
        {{ description | translate }}
      </p>
    </div>
  <mat-form-field [appearance]="appearance" class="d-inline-block field-upload" [subscriptSizing]="'dynamic'" [ngClass]="{'field-upload-disabled' : (this.selectedFiles.length >= this.fileMaxNumber)}">
    <div class="file-upload-input">
      <mat-icon svgIcon="upload"></mat-icon>
      <ngx-mat-file-input #fileUpload [multiple]="acceptMultipleFiles" [accept]="fileAccept"
                          [formControlName]="controlName" [disabled]="this.selectedFiles.length >= this.fileMaxNumber"
                          [placeholder]="placeholder | translate" [required]="required"
                          (change)="onChangeEvent($event)">
      </ngx-mat-file-input>
    </div>
    <mat-error *ngIf="fileTooBig">
      {{ '__file_upload_max_file_size' | translate | sprintf: (fileMaxSize | byteFormat)}}
    </mat-error>
    <mat-error *ngIf="tooMuchFiles">
      {{ '__file_upload_too_much_files' | translate }}
    </mat-error>
    <mat-error *ngIf="uploadGroup.controls[controlName].hasError('required')">
      {{ '__legend_field_required' | translate }}
    </mat-error>
  </mat-form-field>

  <div class=" d-inline-block float-right info-block">
    <p [ngClass]="{'error-file': tooMuchFiles}">
    {{ selectedFiles.length ?? 0 }}/{{fileMaxNumber}} {{'__attachment_label' | translate}}
    </p>
    <ul *ngIf="selectedFiles.length">
      <li *ngFor="let file of selectedFiles; let i = index;">
        <span class="filename" *ngIf="i < fileMaxNumber" [ngClass]="{'error-file': (file.size > fileMaxSize)}">
          {{ file.name }}
        </span>
        <button mat-icon-button (click)="removeFile(file)" *ngIf="i < fileMaxNumber">
          <mat-icon>clear</mat-icon>
        </button>
      </li>
      <li *ngIf="selectedFiles.length > fileMaxNumber" class="too-much-file-item">
        <span>...</span>
      </li>
    </ul>
  </div>
</form>
