/*
	WEEK SELECTOR COMPONENT
	Week-selector manipulates dates with 3 main variables :

	nbDays (moved here from calendar) : define if we see 1, 5 or 7 days
	selectedDay : Define date of Datepicker or first day of the week view
	weekDays : Array of Moment objects used for the agenda headers.
	Week selector emit these variables to its parents.
	It's been customized so we can change its value using the arrow, toggle the calendar by clicking anywhere.
	The week selector is designed to not let the user dig in the past #Yolo
*/


import {Component, OnInit} from '@angular/core';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import { DateTime } from 'luxon';
import {CalendarService} from '../service/calendar/calendar.service';
import {TranslateService} from '@ngx-translate/core';

// Custom Date Formats for DatePicker
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'dddd, Do MMM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@Component({
  selector: 'app-week-selector',
  templateUrl: './week-selector.component.html',
  styleUrls: ['./week-selector.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})

export class WeekSelectorComponent implements OnInit {

  weekDays: Array<any>;

  constructor(
    public calendarService: CalendarService,
    public translateService: TranslateService,
  ) {
    calendarService.weekDays$.subscribe((newWeekDays: Array<any>) => {
      this.weekDays = newWeekDays;
    });
  }

  ngOnInit() {
    this.calendarService.setWeekDays(this.calendarService.selectedDay.value, this.calendarService.nbDays);
  }

  /**
   * Adjust the date according to the calendar format (so the number of days)
   */
  previous() {
    let newDate = this.calendarService.selectedDay.value.minus({days: this.calendarService.nbDays});
    // prevent from exploring the past by bringing you back to the present moment
    if (newDate < DateTime.now()) {
      newDate = this.calendarService.today;
    }
    this.calendarService.changeDate(newDate);
  }

  /**
   * Adjust the date according to the calendar format (so the number of days)
   */
  next() {
    this.calendarService.changeDate(this.calendarService.selectedDay.value.plus({days: this.calendarService.nbDays}));
  }

  isPreviousVisible(): boolean {
    return this.calendarService.selectedDay && !this.calendarService.selectedDay.value.hasSame(DateTime.now(), 'day');
  }

  isWeekListVisible(): boolean {
    return !!this.calendarService.weekDays && !!this.calendarService.nbDays;
  }

  /**
   * Return the right format depending on the translation.
   *
   * @param date  moment  Moment object to format
   */
  getDateFormat(date): string {
    const locale = this.translateService.currentLang;

    switch (locale) {
      case 'de':
        return date.toFormat('dd.LL.yy');
      default:
        return date.toFormat('dd/LL/yy');
    }
  }

}
