import {Component, Input} from '@angular/core';
import {MatCard, MatCardActions, MatCardContent, MatCardHeader, MatCardTitle} from '@angular/material/card';
import {NgIf} from '@angular/common';
import {TranslatePipe} from '../pipe/translate/translate.pipe';
import {TranslateService} from '@ngx-translate/core';
import {CalendarService} from '../service/calendar/calendar.service';

@Component({
  selector: 'app-booking-finish-step',
  standalone: true,
  imports: [
    MatCard,
    NgIf,
    TranslatePipe,
    MatCardHeader,
    MatCardContent,
    MatCardTitle,
    MatCardActions
  ],
  templateUrl: './booking-finish-step.component.html',
  styleUrl: './booking-finish-step.component.scss'
})
export class BookingFinishStepComponent {

  /**
   * Doctena url
   */
  doctenaUrl: string;

  /**
   * Appointment already accepted
   */
  @Input() appointmentAlreadyAccepted = false;


  constructor(public translateService: TranslateService,
              public calendarService: CalendarService) {
    const countryIso = this.calendarService?.selectedAgenda?.practice?.country_iso;
    this.doctenaUrl = `https://www.doctena.${countryIso}/${this.translateService.currentLang}`;
  }
}
