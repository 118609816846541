
/**
 * Author:      Damien Bernard
 * DateTime:    19/10/10
 * Description: This component is a reusable modal in which you can pass data
 * that will be used in the view. Check the template to see how the data is used.
 */


import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-booking-modal',
  templateUrl: './booking-modal.component.html',
  styleUrls: ['./booking-modal.component.scss']
})
export class BookingModalComponent implements OnInit {

  constructor(
    public dialog: MatDialogRef<BookingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  onClose(): void {
    this.dialog.close();
  }
}
