import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {Location, NgIf} from '@angular/common';
import {TranslatePipe} from '../pipe/translate/translate.pipe';
import {find, isEmpty} from 'lodash';
import {CalendarService} from '../service/calendar/calendar.service';
import {TranslateService} from '@ngx-translate/core';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatError, MatFormField, MatHint} from '@angular/material/form-field';
import {SprintfPipe} from '../pipe/sprintf/sprintf.pipe';
import {environment} from '../../environments/environment';
import {bookingLoginDoctenaAccount, hidePatientRelation} from '../routing/routing.component';
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio';
import {PatientService} from '../service/patient/patient.service';
import {BookingService} from '../service/booking/booking.service';
import {PatientLoginComponent} from '../patient-login/patient-login.component';
import {MatInput} from '@angular/material/input';
import {Doctor} from '../models/doctor';
import {MatButton} from '@angular/material/button';
import {MatStepperNext, MatStepperPrevious} from '@angular/material/stepper';
import {FieldsetComponent} from '../fieldset/fieldset.component';
import {CustomFieldsResponse} from '../models/customFields';

@Component({
  selector: 'app-booking-consultation-step',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    TranslatePipe,
    MatCheckbox,
    MatError,
    SprintfPipe,
    MatFormField,
    MatRadioButton,
    MatRadioGroup,
    PatientLoginComponent,
    MatHint,
    MatInput,
    MatButton,
    MatStepperNext,
    MatStepperPrevious,
    FieldsetComponent
  ],
  templateUrl: './booking-consultation-step.component.html',
  styleUrl: './booking-consultation-step.component.scss'
})
export class BookingConsultationStepComponent implements OnInit {

  constructor(public calendarService: CalendarService,
              public translateService: TranslateService,
              private formBuilder: FormBuilder,
              public patientService: PatientService,
              private _location: Location,
              public bookingService: BookingService) {

    this.countryIso = this.calendarService?.selectedAgenda?.practice?.country_iso;
    this.bookingService.majorityAgeMin = environment.underageDefinition[this.countryIso];
    this.selectedReason = '';
    this.isPristine = false;
  }

  /**
   * Booking login doctena account
   */
  bookingLoginDoctenaAccount = bookingLoginDoctenaAccount;

  /**
   * Hide patient relation
   */
  hidePatientRelation = hidePatientRelation;

  /**
   * Selected reason
   */
  selectedReason: any;

  /**
   * Teleconsultation info url
   */
  teleconsultationInfoUrl: string;

  /**
   * Is reschedule
   */
  isReschedule: boolean;

  /**
   * Country iso
   */
  countryIso: string;

  /**
   * Is pristine
   */
  isPristine: boolean;

  /**
   * Show patient returning input
   */
  showPatientReturningInput = true;

  /**
   * Patient note enabled
   */
  patientNoteEnabled = false;

  /**
   * Custom fields
   */
  customFields: CustomFieldsResponse;

  /**
   * User data
   */
  userData: any;

  /**
   * Form group
   */
  @Input() formGroup: FormGroup = this.formBuilder.group({});

  /**
   * Initialize the component
   */
  ngOnInit(): void {

    const languageSelected = this.translateService.currentLang,
      initReason = (this.selectedReason?.name || ''),
      bookingRules = this.calendarService?.bookingParams['bookingRules'],
      hasTeleConsult = this.calendarService?.selectedAgenda?.teleconsultation && this.selectedReason?.teleconsultation,
      controls = [
        {
          name: 'isDoctenaUser',
          control: this.formBuilder.control('')
        },
        {
          name: 'returningPatient',
          control: this.formBuilder.control('false', Validators.required)
        },
        {
          name: 'reason',
          control: this.formBuilder.control(initReason)
        },
        {
          name: 'patientNote',
          control: this.formBuilder.control('')
        }
      ];

    this.userData = this.bookingService.formattedUserData;

    this.bookingService.formattedUserDataSubject.subscribe({
      next: (userData) => {
        this.userData = userData;
      }
    });

    this.isReschedule = (typeof this.calendarService.appointmentReschedule !== 'undefined');

    this.patientNoteEnabled = this.calendarService?.selectedAgenda?.doctor?.portalVersion !== Doctor.PortalVersionEnum.Doxter;

    this.teleconsultationInfoUrl = environment.teleconsultationInfo[languageSelected];

    if (this.calendarService?.customMessage?.hasReadCheckbox) {
      controls.push({
        name: 'customMessageAgreement',
        control: this.formBuilder.control(false, Validators.requiredTrue)
      });
    }

    // TeleConsult checkbox if teleConsult is enabled
    if (hasTeleConsult) {
      controls.push({
        name: 'teleConsultationValidation',
        control: this.formBuilder.control(false, Validators.requiredTrue)
      });
    }

    // We initialize our forms with default values and validators
    controls.forEach(control => this.formGroup.addControl(control.name, control.control));

    // Generate form fields from customFields data from CPP API
    this.customFields = this.bookingService.initCustomFields(this.formGroup, ['appointment']);

    // If a booking rules specifying if the patient is a returning patient has been filled
    // We switch the returningPatient input accordingly
    let practiceRelation: any;
    practiceRelation = bookingRules ? find(bookingRules, {name: 'practiceRelation'}) : null;

    // If the practiceRelation is set to 'EXISTING' or feature patientStop is enable we set the returningPatient input to true
    if (practiceRelation || this.calendarService.availableFeatures?.patientStop) {
      this.showPatientReturningInput = false;
      const returningPatientValue = ((practiceRelation?.selectedValue === 'EXISTING') || this.calendarService.availableFeatures?.patientStop) ? 'true' : 'false';
      this.formGroup.controls.returningPatient.patchValue(returningPatientValue);
    }

    if (this.calendarService?.bookingParams['reasonOfVisit']) {
      this.calendarService.bookingParams['reasonOfVisit'].forEach((reason: any) => {
        if (this.calendarService.selectedAgenda.externalId === reason.agendaEid) {
          this.selectedReason = reason;
        }
      });
    }

    // If user is rescheduling, we autofill the form with the data of the appointment
    if (this.isReschedule) {
      const rescheduleData = this.calendarService.appointmentReschedule;

      this.bookingService.fillFormWithData(this.formGroup, Object.assign(rescheduleData, {
        patientNote: rescheduleData.patientNote || '',
      }), true);
    }

    // If userData we fill the form with the data
    if (this.userData) {
      this.bookingService.fillFormWithData(this.formGroup, this.userData, true);
    }
  }

  /**
   * Return doctor's remark
   */
  getDoctorRemark() {
    if (!this.calendarService.selectedAgenda) {
      return null;
    }

    // First we retrieve remarks that are only linked to this practice
    const agenda = this.calendarService.selectedAgenda,
      remarks = (agenda.webBookingText || []).filter(remark => {
        return !remark.practice_eid || agenda.practice.externalId === remark.practice_eid;
      });

    // If we have results, then we return the correct language value
    if (!isEmpty(remarks)) {
      const language = this.translateService.currentLang;
      return remarks[0].messages[language] || remarks[0].messages['*'];
    }

    return null;
  }

  /**
   * Check if Bingli form was filled
   */
  isBingli() {
    return (this.bookingService.hasOwnProperty('surveyId') && this.bookingService.surveyId != null);
  }

  /**
   * Show login patient
   */
  showLoginPatient() {
    return !this.isBingli() && !(
      this.patientService?.patient ||
      this.isReschedule ||
      (Object.keys(this.userData).length > 0) ||
      !bookingLoginDoctenaAccount
    );
  }

  /**
   * If patient logged we fill the form with his data
   *
   * @param isLogged
   */
  setIsLogged(isLogged: boolean) {
    if (isLogged) {
      this.bookingService.fillFormWithData(this.formGroup, this.bookingService.patientData);
    }
  }

  /**
   * On click next
   */
  onClickNext() {
    this.isPristine = true;
  }

  /**
   * On click back
   */
  onClickBack() {
    this._location.back();
    this.calendarService.resetFilters();
  }
}
