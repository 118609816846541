import {Component, OnInit, Input} from '@angular/core';
import {Agenda} from '../models/agenda';
import {CalendarService} from '../service/calendar/calendar.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @Input() agendas: Array<Agenda>;
  @Input() practiceAgendas: Array<any>;
  @Input() specialitiesAliases: Array<any> = [];
  availabilityOrder: Array<any>;
  hidePicture = this.route.snapshot.paramMap.get('showPicture') === '0';

  constructor(public calendarService: CalendarService,
              private route: ActivatedRoute) {
    this.availabilityOrder = [];
    this.calendarService.slots$.subscribe((newSlots: Array<any>) => {
      this.availabilityOrder = newSlots;
      this.agendas = [].concat(this.agendas); // Force update of custom pipe
    });
  }

  ngOnInit() {
    // Do nothing
    return;
  }

  /**
   * Check if the doctor is displayable when a reason of visit is selected
   *
   * @param agenda
   */
  isDisplayable(agenda: Agenda) {
    if (this.calendarService.bookingParams['reasonOfVisit']) {
      return typeof this.calendarService.bookingParams['reasonOfVisit'].find(reason => agenda.externalId === reason.agendaEid) !== 'undefined';
    } else {
      return true;
    }
  }
}
