<ul class="list-container" [transition-group]="'flip-list'">
	<li class="doctor-list-item"
		*ngFor="let agenda of agendas | orderPracticeAgendas:practiceAgendas:availabilityOrder" transition-group-item>

		<app-doctor-details [agenda]="agenda" [isPracticeView]="true" *ngIf="isDisplayable(agenda)"
                        [specialitiesAliases]="specialitiesAliases" [hasDoctorImage]="!hidePicture">
		</app-doctor-details>

	</li>
</ul>
