<app-alert></app-alert>
<app-loader></app-loader>
<div *ngIf="isDisplayable()">
  <div class="practice" id="calendar-view">
    <div id="patient-doctena-login" *ngIf="showPatientLogin() && (!isAccountRequestComplete && !isLogged)">
      <p> {{ '__i_want_to_book_an_appointment'| translate }}</p>
      <mat-radio-group class="example-radio-group">
        <mat-radio-button value="0" class="booking-with-doctena-radio-button"
                          (click)="isDoctenaUser(true)">
          {{ '__booking_with_doctena_account'| translate }}
        </mat-radio-button>
        <!-- Login form -->
        <app-patient-login *ngIf="showPatientLogin()"
                           (isLogged)="setIsLogged($event)"
                           [countryIso]="countryIso"
                           [consultationGroup]="consultationGroup"
                           [showPatientLoginForm]="showPatientLoginForm">
        </app-patient-login>
        <mat-radio-button value="1" class="booking-without-doctena-radio-button" (click)="isDoctenaUser(false)">
          {{ '__booking_without_doctena_account'| translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngIf="(isAccountRequestComplete || isLogged || !showPatientLogin())">
      <div class="flex-container">
        <div class="item-flex">
          <app-filters
            (isBookingRulesSelected)="setStatusBookingRulesSelected($event)"
            (reasonOfVisitWithTriageKey)="setReasonOfVisitDoctenaWithTriageKey($event)"
            (isBingliRovSelected)="setIsBingliRovSelected($event)"
            [showBingliForm]="!completeBingliSurvey"
            [preselectRov]="preselectBingliRov"
            [practiceEid]="practiceEid"
            [isDisabled]="isBookingRulesSelected && bingliFeature"
            [isPracticeView]="isPracticeView">
          </app-filters>
        </div>
      </div>
      <!-- BINGLI PART --->
      <div *ngIf="showBingliForm && bingliFeature && reasonOfVisitDoctenaWithTriageKey.length && isBingliRovSelected">
        <!-- Bingli Form  -->
        <app-bingli-form (onSurveyComplete)="isCompleteSurvey($event)"
                         (reasonOfVisitBingli)="extractReasonOfVisitBingli($event)"
                         [features]="practiceFeatures"
                         [entityName]="'practice'"
                         [entityEid]="practiceEid"
                         [featureCode]="'triage'"
                         [preselectBingliRov]="reasonOfVisitDoctenaWithTriageKey"
                         [consentSignBy]="practiceName"
                         [consentSignContact]="practicePhone">
        </app-bingli-form>
      </div>
      <div class="sticky-top" *ngIf="completeBingliSurvey">
        <a
          *ngIf="isInitiatedByDoctorView()"
          class="back-button link"
          (click)="goBackToDoctorView()"
          i18n="@@__back_doctor_profile">
          <mat-icon>arrow_back</mat-icon>
          <span>{{ '__back_doctor_profile' | translate }}</span>
        </a>
        <app-week-selector [ngClass]="{'hide-picture': hidePicture}"
                           *ngIf="isDisplayable()"></app-week-selector>
      </div>

      <app-list
        *ngIf="completeBingliSurvey"
        [agendas]="agendas"
        [practiceAgendas]="practiceAgendas"
        [specialitiesAliases]="specialitiesAliases">
      </app-list>

    </div>
  </div>
  <div class="powered-by">
    <p *ngIf="!hidePowerBy">
      {{ '__powered_by' | translate }}
      <a rel="nofollow" [href]="profileUrl" target="_blank">
        Doctena
      </a>
      -
    </p>
    <a i18n="Privacy policy@@__privacy_settings" [href]="policyUrl" target="_blank" rel="nofollow">
      {{ '__privacy_settings' | translate }}
    </a>
  </div>
  <app-cookie-info *ngIf="!isHideCookieBanner()" [countryIso]="countryIso"></app-cookie-info>
</div>
