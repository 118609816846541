import {Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FiltersService} from '../service/filters/filters.service';
import { HttpParams } from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {Agenda} from '../models/agenda';
import {environment} from '../../environments/environment';
import {CalendarService} from '../service/calendar/calendar.service';
import {Doctor} from '../models/doctor';
import {preSelectedReason, rovOrdering, showMessageNotificationWebNoSlot} from '../routing/routing.component';
import {each} from 'lodash';

declare let $: any;

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, OnChanges {

  bookingRules = [];
  customMessage = null;
  allReasonOfVisits = [];
  reasonOfVisits = [];
  selectedReasonOfVisits: object | boolean;
  portalVersion: number;
  hasTerminlandGateway: boolean;
  teleconsultationInfoUrl: string;
  teleconsultationDiscoverPdf: string;
  touchedFilters = {};
  rovWithTriageKey = [];
  rovOrdering: string | string[];
  showMessageNotificationWebNoSlot = showMessageNotificationWebNoSlot;

  @Output() isBookingRulesSelected = new EventEmitter<boolean>;
  @Output() reasonOfVisitWithTriageKey = new EventEmitter<object>;
  @Output() isBingliRovSelected = new EventEmitter<boolean>;
  @Input() agenda: Agenda;
  @Input() practiceEid: string;
  @Input() groupEid: string;
  @Input() isPracticeView = false;
  @Input() isGroupView = false;
  @Input() readOnly: boolean;
  @Input() filterParams: HttpParams;
  @Input() showBingliForm = false;
  @Input() preselectRov = null;
  @Input() isDisabled = false;

  constructor(private filtersService: FiltersService,
              public translateService: TranslateService,
              public calendarService: CalendarService) {
    this.readOnly = false;

    // Init services
    this.calendarService.languageIso = this.translateService.currentLang;
  }

  /**
   * Component's init function
   */
  ngOnInit() {
    this.isBookingRulesSelected.emit(false);
    if (!this.isPracticeView && !this.isGroupView) {
      this.portalVersion = this.agenda.doctor.portalVersion;
      this.hasTerminlandGateway = this.agenda.doctor.terminlandGateway;
    }
    this.selectedReasonOfVisits = null;
    this.getBookingRules();
    this.calendarService.customMessage = null;
    this.teleconsultationInfoUrl = environment.teleconsultationInfo[this.translateService.currentLang];
    this.teleconsultationDiscoverPdf = environment.teleconsultationDiscoverPdf[this.translateService.currentLang];
    this.rovOrdering = rovOrdering ? [rovOrdering] : ['position', 'name'];
  }

  /**
   * On change event
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.preselectRov?.currentValue) {
      // For preselecting reason of visit add all in the dropdown list including Bingli
      this.reasonOfVisits = this.allReasonOfVisits;
      this.preSelectReasonOfVisit(changes.preselectRov.currentValue.id);
    }
  }

  /**
   * Get booking rules from API
   */
  getBookingRules(): void {
    switch (true) {
      case (this.isPracticeView):
        this.filtersService.getBookingRulesPractice(this.practiceEid, response => this.getBookingRulesCallback(response));
        break;
      case (this.isGroupView):
        this.filtersService.getBookingRulesGroup(this.groupEid, response => this.getBookingRulesCallback(response), this.filterParams);
        break;
      default:
        this.filtersService.getBookingRulesAgenda(this.agenda.externalId, response => this.getBookingRulesCallback(response));
        break;
    }
  }

  /**
   * Get reasons of visits fromAPI depending of params
   */
  getReasonOfVisits(): void {
    let params = new HttpParams(); // Add params of select rules if we have it
    params = params.append('language', this.translateService.currentLang);
    this.calendarService.customMessage = null;

    if (this.calendarService.hasBookingRules && !this.hasTerminlandGateway) {
      params = this.urlBookingRulesParams(params);
    }

    // Concat filters params
    if (this.filterParams) {
      params = this.filterParams.get('speciality') ? params.append('speciality', this.filterParams.get('speciality')) : params;
      params = this.filterParams.get('practice') ? params.append('practice', this.filterParams.get('practice')) : params;
      params = this.filterParams.get('doctor') ? params.append('doctor', this.filterParams.get('doctor')) : params;
    }

    switch (true) {
      case (this.isPracticeView):
        this.filtersService.getReasonOfVisitsPractice(this.practiceEid, response => this.getReasonOfVisitsCallback(response), params);
        break;
      case (this.isGroupView):
        this.filtersService.getReasonOfVisitsGroup(this.groupEid, response => this.getReasonOfVisitsCallback(response), params);
        break;
      default:
        this.filtersService.getReasonOfVisitsAgenda(this.agenda.externalId, response => this.getReasonOfVisitsCallback(response), params);
        break;
    }
  }

  /**
   * Callback for reason of visits call
   *
   * @param response
   */
  getReasonOfVisitsCallback(response) {
    // If there are some reasons available
    if ((response.doctorsreasons || typeof response.doctorsreasons !== 'undefined') && Object.keys(response.doctorsreasons).length > 0) {
      this.allReasonOfVisits = Object.keys(response.doctorsreasons).map(function (itm) {
        return response.doctorsreasons[itm];
      });

      this.calendarService.hasReasonsOfVisits = true;
      // remove reasons linked with Bingli in the dropdown list
      this.removeBingliVisitReasons();

      // Pre-select reason if we have only one reason
      if (this.reasonOfVisits.length === 1 && !this.showBingliForm) {
        this.preSelectReasonOfVisit(this.reasonOfVisits[0].reasonData[0].id);
        return;
      }

      // Pre-select reason if we reschedule an appointment
      if (typeof this.calendarService.appointmentReschedule !== 'undefined' && this.reasonOfVisits.length > 1) {
        this.preSelectReasonOfVisit(this.calendarService.appointmentReschedule.doctorReason.id);
        return;
      }

      // Pre-select reason if parameter is define
      if (preSelectedReason) {
        this.preSelectReasonOfVisit(preSelectedReason);
        return;
      }

      // Add Bingli 'Other' reason in the dropdown list
      this.addBingliVisitReasons();

      // If no reasons
    } else {
      this.reasonOfVisits = [];
      this.selectedReasonOfVisits = null;
      this.calendarService.hasReasonsOfVisits = false;
      this.getCustomMessage();
    }

    // Allow to get availabilities without reason for Doxter and Sanmax and Terminland gateway
    if (
      (this.hasTerminlandGateway === true) ||
      (this.portalVersion === Doctor.PortalVersionEnum.Doxter) ||
      (this.portalVersion === Doctor.PortalVersionEnum.Sanmax)
    ) {
      this.selectedReasonOfVisits = true;
      this.calendarService.hasReasonsOfVisits = true;
      this.setBookingParams();
      this.calendarService.getAvailabilities();
    }
  }

  /**
   * Pre-select reason of visit depending of the appointment to reschedule or preSelectedReason param
   *
   * @param selectedReason
   */
  preSelectReasonOfVisit(selectedReason) {
    this.reasonOfVisits.forEach((reasonOfVisit, key) => {
      if (reasonOfVisit.reasonData.length && (!reasonOfVisit.reasonData.isBingli)) {
        const hasFoundReason = reasonOfVisit.reasonData.find((reason) => {
          // Check on ID and externalId
          return [reason.externalId, reason.id].includes(selectedReason);
        });

        // If a reason is found we get availabilities
        if (hasFoundReason) {
          this.selectedReasonOfVisits = reasonOfVisit.reasonData;
          this.touchedFilters['reasonOfVisit'] = true;
          this.setBookingParams();
          this.calendarService.getAvailabilities();
          this.getCustomMessage();
        }
      }
    });
  }

  /**
   * Pre-select booking rules depending of the appointment to reschedule
   */
  preSelectBookingRules() {
    this.bookingRules.forEach((bookingRule) => {
      switch (bookingRule.name) {
        // From PRO
        case 'insuranceType':
          if (this.calendarService.appointmentReschedule.insurance > 1) {
            bookingRule.selectedValue = this.getInsuranceTypeValue(this.calendarService.appointmentReschedule.insurance);
            this.touchedFilters[bookingRule.name] = true;
            this.displayBookingRuleByName('practiceRelation');
          }
          break;
        // From PRO
        case 'practiceRelation':
          bookingRule.selectedValue = (this.calendarService.appointmentReschedule.returningPatient === false) ? 'NEW' : 'EXISTING';
          this.touchedFilters[bookingRule.name] = true;
          break;
        // From other
        case 'insurance':
          if (this.calendarService.appointmentReschedule.insurance > 1) {
            if (this.portalVersion === Doctor.PortalVersionEnum.Doxter) {
              bookingRule.selectedValue = (this.calendarService.appointmentReschedule.insurance === 2) ? 'GKV' : 'PKV';
            } else {
              bookingRule.selectedValue = (this.calendarService.appointmentReschedule.insurance === 2) ? 'public' : 'private';
            }
            this.touchedFilters[bookingRule.name] = true;
            this.displayBookingRuleByName('newPatient');
          }
          break;
        // From other
        case 'newPatient':
          bookingRule.selectedValue = (this.calendarService.appointmentReschedule.returningPatient === false) ? 1 : 0;
          this.touchedFilters[bookingRule.name] = true;
          break;
        default:
          break;
      }
    });
  }

  /**
   * Get insuranceType value form appointment value
   *
   * @param appointmentInsurance
   */
  protected getInsuranceTypeValue(appointmentInsurance) {
    switch (appointmentInsurance) {
      case 2:
        return 'PUBLIC';
      case 4:
        return 'PRIVATE';
      case 8:
        return 'SELFPAYING';
      case 32:
        return 'TRADEASSOCIATION';
      default:
        return null;
    }
  }

  /**
   * Callback for booking rules call
   *
   * @param response
   */
  getBookingRulesCallback(response) {
    const component = this;
    if ((response.activeTypes || typeof response.activeTypes !== 'undefined') && response.activeTypes.length > 0) {
      this.calendarService.hasBookingRules = true;
      response.activeTypes.forEach(function (bookingRule, key) {
        component.generateBookingRules(bookingRule, key);
      });

      if (typeof this.calendarService.appointmentReschedule !== 'undefined') {
        this.preSelectBookingRules();
        if (this.hasAllRulesSelected()) {
          this.isBookingRulesSelected.emit(true);
          this.getReasonOfVisits();
        }
      }
    } else {
      this.isBookingRulesSelected.emit(true);
      this.getReasonOfVisits();
    }
  }

  /**
   * Check type of booking rule active
   *
   * @param bookingRule
   * @param key
   */
  generateBookingRules(bookingRule, key) {
    // Get values available for booking Rule
    let name, message = '';
    const type = bookingRule.typeValueDataType;
    const showBookingRule = (key === 0);

    switch (bookingRule.typeName) {
      case 'PATIENT_INSURANCE':
      case 'TERMINLAND_INSURANCE':
      case 'DOXTER_INSURANCE':
        name = bookingRule.inputProperty;
        message = '__select_insurance_explanation';
        break;
      case 'PATIENT_NEW_EXISTING':
      case 'TERMINLAND_PATIENT':
        name = bookingRule.inputProperty;
        message = '__select_patient_explanation';
        break;
      default:
        break;
    }

    const values = this.generateValuesBookingRule(bookingRule, name);

    this.bookingRules.push({
      name: name,
      type: type,
      message: message,
      values: values,
      selectedValue: null,
      show: showBookingRule

    });
  }

  /**
   * Check values for booking rule active
   *
   * @param bookingRule
   * @param name
   */
  generateValuesBookingRule(bookingRule, name) {
    let values = null;

    switch (bookingRule.typeValueDataType) {
      case 'ENUM':
        values = [];
        bookingRule.typeValues.forEach(function (value, key) {
          const keyName = ('__name_' + name + '_' + value).toLowerCase();
          values.push(
            {
              name: keyName,
              data: value
            }
          );
        });
        break;
      default:
        break;
    }

    return values;
  }

  /**
   * Method to manage booking rules updates
   *
   * @param bookingRuleName
   */
  onChangeBookingRules(bookingRuleName: string) {
    this.calendarService.resetAvailabilities();
    this.touchedFilters[bookingRuleName] = true; // Hide placeholder & show tick
    this.touchedFilters['reasonOfVisit'] = false; // Show placeholder & hide tick
    const methodName = 'change' + bookingRuleName.charAt(0).toUpperCase() + bookingRuleName.slice(1);
    if (this[methodName]) {
      this[methodName]();
    }

    if (this.hasAllRulesSelected()) {
      this.calendarService.bookingParams.bookingRules = this.bookingRules;
      this.isBookingRulesSelected.emit(true);
      this.getReasonOfVisits();
    }
  }

  /**
   * When the user change the insurance
   */
  changeInsuranceType() {
    if (this.hasAllRulesSelected() && this.selectedReasonOfVisits) {
      this.selectedReasonOfVisits = null;
      this.setBookingParams();
      this.calendarService.slots = [];
    }

    this.displayBookingRuleByName('practiceRelation');
  }

  /**
   * When the user change the insurance for Terminland
   */
  changeInsurance() {
    if (this.hasAllRulesSelected() && this.selectedReasonOfVisits) {
      this.selectedReasonOfVisits = null;
      this.setBookingParams();
      this.calendarService.slots = [];
    }

    this.displayBookingRuleByName('newPatient');
  }

  /**
   * Event when a reason is selected on the filter
   *
   * @param e
   */
  onChangeReasonOfVisits(e?) {
    if (e.value[0]?.isBingli) {
      this.isBingliRovSelected.emit(true);
      this.touchedFilters['reasonOfVisit'] = true;
      return;
    }
    this.isBingliRovSelected.emit(false);
    this.touchedFilters['reasonOfVisit'] = true;
    this.setBookingParams();
    this.calendarService.getAvailabilities();
    this.getCustomMessage();
  }

  getCustomMessage() {
    if (!this.isPracticeView && !this.isGroupView) {
      this.calendarService.getCustomMessage(
        this.agenda.externalId,
        ((this.selectedReasonOfVisits || [])[0] || {}).externalId,
        this.translateService.currentLang
      );
    }
  }

  /**
   * Get selected value by booking rule name
   *
   * @param name
   */
  getBookingRuleSelectedValueByName(name) {
    let selected = null;
    this.bookingRules.forEach(function (bookingRule, key) {
      if (bookingRule.name === name) {
        selected = bookingRule.selectedValue.data;
      }
    });
    return selected;
  }

  /**
   * Set booking params depending of filters
   */
  setBookingParams() {
    if (this.calendarService.hasBookingRules) {
      this.calendarService.bookingParams.bookingRules = this.bookingRules;
    }

    this.calendarService.bookingParams.reasonOfVisit = this.selectedReasonOfVisits;
  }

  /**
   * Get booking rules to url params
   */
  urlBookingRulesParams(params: HttpParams): HttpParams {

    this.bookingRules.forEach(function (bookingRule, key) {
      params = params.append(bookingRule.name, bookingRule.selectedValue);
    });

    return params;
  }

  /**
   * Check if all params are selected
   *
   * @returns {boolean}
   */
  hasAllRulesSelected() {
    let allRulesSelected = true;

    this.bookingRules.forEach((bookingRule) => {
      if (allRulesSelected && bookingRule.selectedValue === null) {
        allRulesSelected = false;
      }
    });

    this.calendarService.filtersFilled = allRulesSelected;
    return allRulesSelected;
  }

  /**
   * Display booking rule by name
   *
   * @param bookingRuleName
   */
  displayBookingRuleByName(bookingRuleName) {
    this.bookingRules.forEach(function (bookingRule, key) {
      if (bookingRule.name === bookingRuleName) {
        bookingRule.show = true;
      }
    });
  }

  /**
   * Method to know if we can show the ROV list
   *
   * @returns {boolean|*}
   */
  showRovList() {

    // Skip ROV selection if only one ROV is only one is available and it's bingli
    if (this.hasAllRulesSelected() && this.reasonOfVisits.length === 1 && this.rovWithTriageKey.length) {
      this.isBingliRovSelected.emit(true);
      this.touchedFilters['reasonOfVisit'] = true;
      return false;
    }

    if (this.calendarService.hasBookingRules) {
      return this.hasAllRulesSelected() && this.reasonOfVisits.length > 0
        && this.calendarService.hasReasonsOfVisits && (this.portalVersion !== Doctor.PortalVersionEnum.Sanmax);
    }
    return this.calendarService.hasReasonsOfVisits && this.reasonOfVisits.length > 0
      && (this.portalVersion !== Doctor.PortalVersionEnum.Sanmax);
  }

  /**
   * Method to remove Bingli visit reasons
   * @private
   */
  private removeBingliVisitReasons() {
    this.rovWithTriageKey = [];
    const rovWithoutTriageKey = [];

    this.allReasonOfVisits.forEach((reason) => {
      if (reason.reasonData[0]['triageKey']) {
        this.rovWithTriageKey.push(reason.reasonData[0]);
      } else {
        rovWithoutTriageKey.push(reason);
      }
    });

    this.reasonOfVisitWithTriageKey.emit(this.rovWithTriageKey);
    this.reasonOfVisits = rovWithoutTriageKey;
  }

  /**
   * Method to add Bingli visit reasons to dropdown list
   * @private
   */
  private addBingliVisitReasons() {
    if (this.showBingliForm && this.rovWithTriageKey.length > 0) {
      this.reasonOfVisits.push({
        'name': '__bingli_diagnosis',
        'reasonData': [{
          'isBingli': true
        }],
        'position': 1000
      });
    }
  }
}
