import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class FiltersService extends HttpService {

  constructor(http: HttpClient) {
    super(http);
  }

  /**
   * Get booking rules for an agenda
   *
   * @param eid
   * @param callback
   */
  getBookingRulesAgenda(eid: string, callback) {
    return this.get(this.apiUrl + '/bookingRulesAgenda/' + eid).subscribe(callback);
  }

  /**
   * Get booking rules for a practice
   *
   * @param eid
   * @param callback
   */
  getBookingRulesPractice(eid: string, callback) {
    return this.get(this.apiUrl + '/bookingRulesPractice/' + eid).subscribe(callback);
  }

  /**
   * Get booking rules for a group
   *
   * @param eid
   * @param callback
   */
  getBookingRulesGroup(eid: string, callback, params?: HttpParams) {
    if (typeof params === 'undefined') {
      params = new HttpParams();
    }

    return this.get(this.apiUrl + '/bookingRulesGroup/' + eid, params).subscribe(callback);
  }

  /**
   * Get ROVs for an agenda
   *
   * @param eid
   * @param callback
   * @param params
   */
  getReasonOfVisitsAgenda(eid: string, callback, params?: HttpParams) {
    if (typeof params === 'undefined') {
      params = new HttpParams();
    }

    params = params.append('status', 'active');
    return this.get(this.apiUrl + '/agendaCalendarRov/' + eid, params).subscribe(callback);
  }

  /**
   * Get ROVs for a practice
   *
   * @param eid
   * @param callback
   * @param params
   */
  getReasonOfVisitsPractice(eid: string, callback, params?: HttpParams) {
    if (typeof params === 'undefined') {
      params = new HttpParams();
    }

    params = params.append('status', 'active');
    return this.get(this.apiUrl + '/practiceCalendarRov/' + eid, params).subscribe(callback);
  }

  /**
   * Get ROVs for a group
   *
   * @param eid
   * @param callback
   * @param params
   */
  getReasonOfVisitsGroup(eid: string, callback, params?: HttpParams) {
    if (typeof params === 'undefined') {
      params = new HttpParams();
    }

    params = params.append('status', 'active');
    return this.get(this.apiUrl + '/groupCalendarRov/' + eid, params).subscribe(callback);
  }
}
