import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {NgIf} from '@angular/common';
import {TranslatePipe} from '../pipe/translate/translate.pipe';
import {MatButton} from '@angular/material/button';
import {MatStepperNext, MatStepperPrevious} from '@angular/material/stepper';
import {MatFormField} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {BookingService} from '../service/booking/booking.service';
import { HttpErrorResponse } from '@angular/common/http';
import {Alert} from '../models/alert';
import {AlertService} from '../service/alert/alert.service';
import {CalendarService} from '../service/calendar/calendar.service';
import {SprintfPipe} from '../pipe/sprintf/sprintf.pipe';

@Component({
  selector: 'app-booking-confirmation-step',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    TranslatePipe,
    MatButton,
    MatStepperNext,
    MatStepperPrevious,
    MatFormField,
    MatInput,
    SprintfPipe
  ],
  templateUrl: './booking-confirmation-step.component.html',
  styleUrl: './booking-confirmation-step.component.scss'
})
export class BookingConfirmationStepComponent implements OnInit {

  /**
   * Constructor
   *
   * @param formBuilder
   * @param alertService
   * @param calendarService
   * @param bookingService
   */
  constructor(private formBuilder: FormBuilder,
              public alertService: AlertService,
              public calendarService: CalendarService,
              public bookingService: BookingService) {
    this.resendSms = false;
  }

  resendSms: boolean;

  /**
   * Form group
   */
  @Input() formGroup: FormGroup = this.formBuilder.group({});

  ngOnInit(): void {
    this.formGroup.addControl('code', this.formBuilder.control('', Validators.required));
    setTimeout(() => {
      this.resendSms = true;
    }, 10000);
  }

  /**
   * Action to resend the sms code
   */
  resendSmsCode() {
    this.resendSms = false;
    this.bookingService.resendSmsCode(this.bookingService.currentAppointment.id, response => this.resendSmsCodeSuccessCallback(response),
      response => this.resendSmsCodeErrorCallback(response));
  }

  /**
   * Callback action for resendSmsCode call
   *
   * @param response
   */
  resendSmsCodeSuccessCallback(response) {
    // Do nothing
    return;
  }

  /**
   * Callback action for resendSmsCode call
   *
   * @param response
   */
  resendSmsCodeErrorCallback(response: HttpErrorResponse) {
    const alert = new Alert();
    alert.type = 'error';
    alert.text = '__sms_not_send';
    this.alertService.setAlert(alert);
  }

  /**
   * Get the resend button label
   */
  resendButtonLabel() {
    return this.calendarService.availableFeatures?.patientStop ? '__appointment_resend_code' : '__appointment_resend_sms';
  }

  /**
   * Get the help text
   */
  helpText() {
    return this.calendarService.availableFeatures?.patientStop ? '__patientStop_feature_booking_verification_text' : '__confirmation_code_help';
  }

}
