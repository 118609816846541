<!-- Consent Doctena Page  -->
<div *ngIf="!bingliDoctenaConsent"
     style="border: 1px solid #ccc;padding: 30px;border-radius: 5px;background:#f7f7f7; position: relative;">
  <span [innerHtml]="'__mybingli_description_1' | translate | sprintf: consentSignBy: consentSignContact: consentSignContact"></span>

  <div class="formElement formElement--alert semi-mg-top">
    <mat-checkbox value="angular" (change)="consentBingliProcessData($event)">
      <label i18n="@@__consent_bingli_process_data">
        {{'__consent_bingli_process_data' | translate}}
      </label>
    </mat-checkbox>
  </div>
  <br>
  <span [innerHtml]="'__mybingli_description_2' | translate | sprintf: consentSignBy"></span>
  <p>
    <button class="btn-success" id="mybingli-btn-consent" mat-button [disabled]="!isConsentBingliProcessData"
            (click)="consentBingliDoctena()">{{ '__btn_doctena_bingli_consent_page'| translate }}
    </button>
  </p>

</div>

<div *ngIf="bingliDoctenaConsent && showBingliForm">
  <iframe id="mybingli-survey" title="MyBingli survey"
          [src]='bingliFormUrl'></iframe>
</div>
