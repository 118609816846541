
<!-- TRANSLATIONS FOR VALIDATORS -->
<div *ngIf="false">
  <input type="hidden"
    i18n-placeholder="@@__legend_field_required"
    placeholder="{{ '__legend_field_required' | translate }}">
  <!-- TODO: Put a placeholder for number of characters inside this error text -->
  <input type="hidden"
    i18n-placeholder="@@__name_min_char_legend"
    placeholder="{{ '__name_min_char_legend' | translate}}">
  <input type="hidden"
    i18n-placeholder="@@__legend_valid_date"
    placeholder="{{ '__legend_valid_date' | translate}}">
</div>

<span *ngIf="parentForm" [formGroup]="parentForm">
  <!-- ****** TYPE RADIO INPUT ****** -->
  <div *ngIf="field && field.ui_properties.type === 'radio'"
    class="formElement no-mg-bottom" [ngClass]="{'float-right': isOdd }">
    <mat-label *ngIf="field.ui_properties.label" class="col-12 control-label"
      [ngClass]="{'font-weight-bold': !hasParentGroup}">
      {{ field.ui_properties.label }}
    </mat-label>

    <mat-radio-group class="col-12" formControlName="{{ field.name }}">
      <mat-radio-button
        *ngFor="let option of field.ui_properties.value_options | keyvalue"
        value="{{ option.key }}"
        class="mg-right">
        {{ option.value }}
      </mat-radio-button>
      <mat-error class="col-1 d-inline-block"
        *ngIf="!isPristine && parentForm.controls[field.name].hasError('required')">
        *
      </mat-error>
    </mat-radio-group>

    <mat-error class="col-12"
      *ngIf="!isPristine && parentForm.controls[field.name].hasError('required')">
      {{ '__legend_field_required' | translate}}
    </mat-error>
  </div>

  <!-- ****** TYPE CHECKBOX ****** -->
  <div *ngIf="field && field.ui_properties.type === 'checkbox'"
    class="formElement no-mg-bottom" [ngClass]="{'float-right': isOdd }">
    <mat-checkbox [required]="field?.validators?.required" #checkbox [labelPosition]="'after'"
                  [ngClass]="{'font-weight-bold': !hasParentGroup}" class="col-12" formControlName="{{ field.name }}"
                  [value]="checkbox.checked ? field.ui_properties.checked_value : field.ui_properties.unchecked_value">
        {{ field.ui_properties.label }}
      {{ parentForm.controls[field.name].hasError('required') ? ' *' : '' }}
    </mat-checkbox>
    <mat-error class="col-12"
      *ngIf="!isPristine && parentForm.controls[field.name].hasError('required')">
      {{ '__legend_field_required' | translate}}
    </mat-error>
  </div>


  <!-- ******* TYPE TEXT OR NUMBER ******* -->
  <mat-form-field class="col-12 col-md-5 d-inline-block" [ngClass]="{'float-right': isOdd }"
    *ngIf="field && ['text', 'number'].indexOf(field.ui_properties.type) !== -1">
    <mat-label *ngIf="field.ui_properties.label"
      class="col-12 control-label">
      {{ field.ui_properties.label }}
    </mat-label>

    <input matInput type="{{ field.ui_properties.type }}"
      placeholder="{{ field.ui_properties.placeholder + (parentForm.controls[field.name]?.hasError('required') ? ' *' : '') }}"
      formControlName="{{ field.name }}" (keydown)="onKeyDown($event, parentForm.value[field.name])"
      [required]="field?.validators?.required">

    <mat-error *ngIf="parentForm.controls[field.name]?.hasError('required')">
      {{ '__legend_field_required' | translate}}
    </mat-error>

    <!-- TODO: mat error for minimum characters -->
    <!-- TODO: Mat error for invalid pattern -->
  </mat-form-field>

  <!-- ******* TYPE TEXTAREA ******* -->
  <mat-form-field
    class="col-12 col-md-5 d-inline-block" [ngClass]="{'float-right': isOdd }"
    *ngIf="field && field.ui_properties.type === 'textarea'">
    <mat-label *ngIf="field.ui_properties.label" class="col-12 control-label" >
      {{ field.ui_properties.label }}
    </mat-label>

    <textarea matInput
      placeholder="{{ field.ui_properties.placeholder + (parentForm.controls[field.name].hasError('required') ? ' *' : '') }}"
      formControlName="{{ field.name }}" (keydown)="onKeyDown($event, parentForm.value[field.name])"
      [required]="field?.validators?.required">
    </textarea>

    <mat-error *ngIf="parentForm.controls[field.name].hasError('required')">
      {{ '__legend_field_required' | translate}}
    </mat-error>

    <!-- TODO: mat error for minimum characters -->
    <!-- TODO: Mat error for invalid pattern -->
  </mat-form-field>


  <!-- ******* TYPE SELECT ******* -->
  <mat-form-field class="col-12 col-md-5 d-inline-block" [ngClass]="{'float-right': isOdd }"
    *ngIf="field && field.ui_properties.type === 'select'" >

    <mat-label *ngIf="field.ui_properties.label" class="col-12 control-label">
      {{ field.ui_properties.label }}
    </mat-label>

    <mat-select
      placeholder="{{ field.ui_properties.placeholder + (parentForm.controls[field.name].hasError('required') ? ' *' : '') }}"
      [required]="field?.validators?.required" [formControl]="parentForm.controls[field.name]">
      <mat-option *ngFor="let option of field.ui_properties.value_options | keyvalue" [value]="option.key">
        {{ option.value }}
      </mat-option>
    </mat-select>

    <mat-error *ngIf="parentForm.controls[field.name].hasError('required')">
      {{ '__legend_field_required' | translate}}
    </mat-error>
  </mat-form-field>


  <!-- ******* TYPE DATE ******* -->
  <mat-form-field  class="col-12 col-md-5 d-inline-block" [ngClass]="{'float-right': isOdd }"
    *ngIf="field && field.ui_properties.type === 'date'">
    <mat-label *ngIf="field.ui_properties.label">
      {{ field.ui_properties.label }}
    </mat-label>
    <input matInput formControlName="{{ field.name }}"
      placeholder="{{'DD'+datePickerDelimeter+'MM'+datePickerDelimeter+'YYYY'}}"
      [required]="field?.validators?.required" (dateChange)="onDateChange($event)"
      (keydown)="onKeyDown($event, parentForm.value[field.name])" [matDatepicker]="picker">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>

    <mat-error *ngIf="parentForm.controls[field.name].hasError('required')">
      {{ '__legend_field_required' | translate}}
    </mat-error>

    <mat-error *ngIf="!parentForm.controls[field.name].hasError('required') &&
      parentForm.controls[this.field.name].value && parentForm.controls[field.name].invalid">
      {{ '__legend_valid_date' | translate}}
    </mat-error>
  </mat-form-field>

</span>
