import BlueBirdPromise from 'bluebird';
import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {HttpService} from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class PracticeService extends HttpService {

  constructor(http: HttpClient) {
    super(http);
  }

  /**
   * Get all agendas for a practice
   * @param eid
   * @param callback
   * @param params
   */
  getAgendas(eid, callback, params?: HttpParams) {
    if (typeof params === 'undefined') {
      params = new HttpParams();
    }

    params = params.append('weight', 'large_without_slot');
    this.get(this.apiUrl + '/practiceAgendas/' + eid, params)
      .subscribe(data => {
        callback(this.stripUselessData(data));
      });
  }


  /**
   * Get all agendas with features for a practice
   * @param eid
   * @param params
   */
  getAgendasWithFeatures(eid, params?: HttpParams) {
    return new BlueBirdPromise<any>((resolve, reject) => {
      if (typeof params === 'undefined') {
        params = new HttpParams();
      }

      params = params.append('weight', 'large_without_slot');
      return this.get(this.apiUrl + '/practiceAgendasWithFeatures/' + eid, params)
        .subscribe({
          next: (data) => resolve(this.stripUselessData(data)),
          error: (err) => reject(err)
        });
    });
  }
}
