<section>
  <form class="tel-form" [formGroup]="formGroup()" [ngClass]="{ 'is-focused': isFocused() }">
    <mat-form-field appearance="fill" class="prefix-form-field">
      <mat-select formControlName="matriculeIso"
        [placeholder]="(!isLoading() && textLabels().codePlaceholder) || ''"
        #singleSelect
        panelClass="matricule-mat-select-panel"
        [required]="required()"
        (selectionChange)="onCountryChange()"
      >
        <mat-select-trigger>
          <div class="country-option">
            <div class="country-option-flag-container">
              <div class="country-option-flag" [ngClass]="getCountryClass(this.formGroup().get('matriculeIso').value)"></div>
            </div>
            <div>
              {{ this.formGroup().get('matriculeIso')?.value?.toUpperCase() || '' }}
            </div>
          </div>
        </mat-select-trigger>

        @if (enableSearch()) {
          <mat-option>
            <ngx-mat-select-search
              [formControl]="countryIsoFilter"
              [placeholderLabel]="textLabels().searchPlaceholderLabel || ''"
              [noEntriesFoundLabel]="textLabels().noEntriesFoundLabel || ''"
            >
              <svg
                ngxMatSelectSearchClear
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-x"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M18 6l-12 12"/>
                <path d="M6 6l12 12"/>
              </svg>
            </ngx-mat-select-search>
          </mat-option>
        }

        @for (country of filteredCountries | async; track $index) {
          <mat-option [value]="country.iso2">
            <div
              class="country-option"
              style="display: flex; align-items: center; font-size: 14px"
            >
              <div class="country-option-flag-container">
                <div
                  class="country-option-flag"
                  [ngClass]="country.flagClass"
                ></div>
              </div>
              <div>{{ country.name }}</div>
            </div>
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="number-form-field">
      @if (textLabels().mainLabel) {
        <mat-label>{{ textLabels().mainLabel }}</mat-label>
      }
      <input
        matInput
        formControlName="matricule"
        autocomplete="off"
        (focus)="onInputFocus()"
        (blur)="onInputBlur()"
        [required]="required()"
        (input)="handleMatriculeChange($event)"
        #matriculeInput
      />
    </mat-form-field>
  </form>

  <ng-template #phoneIcon>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-phone"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path
        d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"
      />
    </svg>
  </ng-template>

  @if (textLabels().hintLabel) {
    <mat-hint>{{ textLabels().hintLabel }}</mat-hint>
  }
  @if (this.formGroup().get('matricule')?.hasError('required') &&
  textLabels().requiredError &&
  !this.formGroup().get('matricule')?.untouched) {
    <mat-error>{{ textLabels().requiredError }}</mat-error>
  }
  @if (this.formGroup().get('matricule')?.hasError('invalidSsn') && textLabels().invalidSsnError) {
    <mat-error>{{ textLabels().invalidSsnError }}</mat-error>
  }
</section>
