import { Injectable } from '@angular/core';
import {Alert} from '../../models/alert';
import {Observable, of} from 'rxjs';

@Injectable()
export class AlertService {

  alert: Alert;

  getAlert(): Observable<Alert> {
    return of(this.alert);
  }

  setAlert(alert: Alert) {
    this.alert = alert;
  }
}
