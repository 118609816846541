<!-- Google Tag Manager (noscript) -->
<noscript *ngIf="gtmTrackingUrl">
	<iframe [src]="gtmTrackingUrl" [title]="'Google Tag Manager'"
		height="0" width="0" style="display:none;visibility:hidden"></iframe>
</noscript>
<!-- End Google Tag Manager (noscript) -->

<div class="scrolling-wrapper">
	<div
    [ngClass]="{
      'container': !hasFullWidth,
      'fluid-container': hasFullWidth,
      'main-container': true
    }"
  >
	  <app-routing></app-routing>
	</div>
</div>
