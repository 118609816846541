<div
	*ngIf="reviews[doctorEid] && reviews[doctorEid].showRatings && ratingsType !== 'none'"
	class="ratings">
	<div
		*ngIf="ratingsType === 'recommendation' && reviews[doctorEid].recommendationsCount > 0"
		class="recommendations-counter">
		<div class="doctor-recommendation">
			<span class="doctor-recommendation-number">
				{{reviews[doctorEid].recommendationsCount}}
			</span>
			<img src="./assets/reviews/heart.svg" alt="Heart rating">
		</div>
	</div>

	<div
		*ngIf="ratingsType === 'rating' && reviews[doctorEid].ratingsCount > 0">
		<div class="star-rater">
			<svg
				*ngFor="let star of getFullStarsNumber() ; index as i"
				class="star-container full-star-container"
				xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 488">
					<path class="star-path" d="M512 182c-2-4-6-6-11-6l-166-15-33-77-33-76c-2-5-7-8-12-8s-10 3-12 8l-66 153-166 15c-7 0-13 6-13 13 0 4 2 9 6 11l125 110-37 162a13 13 0 0 0 20 14l32-19 111-66 143 85a13 13 0 0 0 20-14l-37-162 125-110c6-4 8-12 4-18z" />
			</svg><svg
				*ngIf="hasHalfStar()"
				class="star-container half-star-container"
				xmlns="http://www.w3.org/2000/svg" viewBox="0 0 257 488">
					<path class="star-path"
						d="M244 8l-66 153-166 15a13 13 0 0 0-7 24l125 110-37 162a13 13 0 0 0 20 14l32-19 111-66V0a13 13 0 0 0-12 8z" />
			</svg>
			<span class="ratings-number">
				{{reviews[doctorEid].ratingsCount}}
			</span>
		</div>
	</div>

</div>
