import {Component, Input, Output, EventEmitter, Inject} from '@angular/core';
import { CustomField } from '../models/customFields';
import {FormGroup, ReactiveFormsModule, UntypedFormGroup} from '@angular/forms';
import {DateTime} from 'luxon';
import {KeyValuePipe, NgClass, NgForOf, NgIf} from '@angular/common';
import {TranslatePipe} from '../pipe/translate/translate.pipe';
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio';
import {MatError, MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatInput} from '@angular/material/input';
import {MatOption, MatSelect} from '@angular/material/select';
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {TranslateService} from '@ngx-translate/core';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ],
  standalone: true,
  imports: [
    NgIf,
    TranslatePipe,
    ReactiveFormsModule,
    NgClass,
    KeyValuePipe,
    MatRadioButton,
    NgForOf,
    MatRadioGroup,
    MatError,
    MatCheckbox,
    MatFormField,
    MatInput,
    MatSelect,
    MatOption,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDatepicker,
    MatLabel,
    MatSuffix
  ]
})
export class FieldComponent {
  @Input() field: CustomField;
  @Input() parentForm: UntypedFormGroup;
  @Input() isPristine: boolean;
  @Input() hasParentGroup: boolean;
  @Input() isOdd: boolean;
  @Output() keyDownEvent = new EventEmitter<any>();

  /**
   * Date picker delimeter
   */
  datePickerDelimeter: string;

  /**
   * Constructor
   *
   * @param translateService
   * @param dateFormats
   */
  constructor(public translateService: TranslateService,
              @Inject(MAT_DATE_FORMATS) private dateFormats: any) {
    this.datePickerDelimeter = this.translateService.currentLang === 'de' ? '.' : '/';
    this.dateFormats.display.dateInput = `DD${this.datePickerDelimeter}MM${this.datePickerDelimeter}YYYY`;
  }

  /**
   * On key down event
   *
   * @param e
   * @param inputValue
   */
  onKeyDown(e: any, inputValue: any) {
    this.keyDownEvent.emit({e, inputValue});
  }

  /**
   * On date change
   *
   * @param e
   */
  onDateChange(e: any) {
    const newValue = DateTime.fromFormat(e.targetElement.value, `dd${this.datePickerDelimeter}LL${this.datePickerDelimeter}yyyy`),
      jsDate = newValue && (typeof newValue.toJSDate === 'function') ? newValue.toJSDate() : newValue;
    // We synchronize the date picker with the input value
    this.parentForm.controls[this.field.name].patchValue(jsDate);
  }
}
