import {Component, DoCheck, OnInit} from '@angular/core';
import {Agenda} from '../models/agenda';
import {Doctor} from '../models/doctor';
import {Practice} from '../models/practice';
import {AgendaService} from '../service/agenda/agenda.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {HelpersService} from '../service/helpers/helpers.service';
import {DoctorService} from '../service/doctor/doctor.service';
import {CalendarService} from '../service/calendar/calendar.service';
import {AppointmentService} from '../service/appointment/appointment.service';
import { HttpParams } from '@angular/common/http';
import {AuthenticationService} from '../service/authentication/authentication.service';
import {TrackingService} from '../service/tracking/tracking.service';
import {Alert} from '../models/alert';
import {AlertService} from '../service/alert/alert.service';
import {hideCookieBanner} from '../routing/routing.component';

@Component({
  selector: 'app-reschedule',
  templateUrl: './reschedule.component.html',
  styleUrls: ['./reschedule.component.scss']
})
export class RescheduleComponent implements OnInit, DoCheck {

  id: string = this.route.snapshot.paramMap.get('id');
  doctor: Doctor;
  practice: Practice;
  agenda: Agenda;
  isPracticeView = false;
  hidePicture = this.route.snapshot.paramMap.get('showPicture') === '0';
  initComponent: boolean;
  countryIso: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private helpers: HelpersService,
              private doctorService: DoctorService,
              private agendaService: AgendaService,
              public translateService: TranslateService,
              public calendarService: CalendarService,
              public appointmentService: AppointmentService,
              public trackingService: TrackingService,
              public authenticationService: AuthenticationService,
              public alertService: AlertService) {
    this.initComponent = false;
  }

  ngDoCheck() {
    if (!this.initComponent) {
      this.initComponent = true;
      // Get bearer for the app
      this.authenticationService.authApp().subscribe(result => {
        if (result !== true) {
          // Login failed
          const alert = new Alert();
          alert.type = 'error';
          alert.text = '__title_error_occurred';
          this.alertService.setAlert(alert);
          return;
        }

        // Load appointment data
        this.appointmentService.getAppointment(this.id, response => this.getAppointmentCallback(response));
      });
    }
  }

  ngOnInit() {
    if (!this.helpers.isGUID(this.id)) {
      console.error('The URL is not valid. The practice\'s id should be a GUID.');
      // Go to "Page not found"
      this.router.navigate([''], {relativeTo: this.route, skipLocationChange: true});
    }
  }

  private getAppointmentCallback(response) {
    if (response.id || typeof response.id !== 'undefined') {
      this.calendarService.appointmentReschedule = response;

      let params = new HttpParams(); // Add params of select rules if we have it
      params = params.append('language', this.translateService.currentLang);

      // Load agenda data
      this.agendaService.getAgenda(this.calendarService.appointmentReschedule.agenda_externalId,
        responseAgenda => this.getAgendaCallback(responseAgenda), params);

      return;
    }

    this.router.navigate([''], {relativeTo: this.route, skipLocationChange: true});
  }

  private getAgendaCallback(response) {
    if (response.id || typeof response.id !== 'undefined') {
      this.agenda = response;
      this.doctor = response.doctor;
      this.practice = response.practice;
      this.countryIso = this.practice.country_iso;

      this.calendarService.addAgenda(this.agenda);
    }
  }
  isHideCookieBanner() {
    return hideCookieBanner;
  }
}
