import {Injectable} from '@angular/core';

@Injectable()
export class CookieService {

  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  /**
   * Get cookie value for a specific cookie name
   *
   * @param cookieName
   */
  getCookie(cookieName) {
    const cookieFullName = cookieName + '=',
      cookies = document.cookie.split(';');

    let cookieValue = '';
    cookies.forEach(cookie => {
      if (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }

      if (cookie.includes(cookieFullName)) {
        cookieValue = cookie.substring(cookieFullName.length, cookie.length);
      }
    });

    return cookieValue;
  }
}
