import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../service/loader/loader.service';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  standalone: true,
  imports: [
    MatProgressSpinner,
    NgIf
  ],
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  constructor(public loaderService: LoaderService) { }

  ngOnInit() {
    // Do nothing
    return;
  }

}
