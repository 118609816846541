import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {extendedColorBg} from '../routing/routing.component';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from '../service/cookie/cookie.service';

@Component({
  selector: 'app-cookie-info',
  templateUrl: './cookie-info.component.html',
  styleUrls: ['./cookie-info.component.scss']
})
export class CookieInfoComponent implements OnInit {

  policyUrl: string;
  extendedColorBg: string;
  showCookieInfo = false;
  hideCookie = false;

  @Input() countryIso: string;

  constructor(
    public translateService: TranslateService,
    public cookieService: CookieService
  ) {
  }

  ngOnInit() {
    const languageCode = this.translateService.currentLang;
    this.policyUrl = environment.policy[languageCode];

    // Check if cookies has already been accepted
    this.showCookieInfo = this.cookieService.getCookie('cookieAccepted') ? false : true;
    this.extendedColorBg = extendedColorBg;
  }

  hideCookieInfo() {
    const self = this;

    // Accept cookie for a year (legal limit is 13 months)
    this.cookieService.setCookie('cookieAccepted', true, 360);
    this.hideCookie = true; // Trigger transition

    // Let time for animation before deleting cookie from DOM,
    setTimeout(
      () => self.showCookieInfo = false,
      1000
    );
  }
}
