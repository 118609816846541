import {ActivatedRoute, Router} from '@angular/router';
import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Agenda} from '../models/agenda';
import {Doctor} from '../models/doctor';
import {Practice} from '../models/practice';
import {CalendarComponent} from '../calendar/calendar.component';
import {extendedColorBg, hideColleaguesAvailabilities, showMessageNotificationWebNoSlot} from '../routing/routing.component';
import {CalendarService} from '../service/calendar/calendar.service';
import {DoctorService} from '../service/doctor/doctor.service';
import {TranslateService} from '@ngx-translate/core';
import {UtilitiesService} from '../service/utilities/utilities.service';

@Component({
  selector: 'app-doctor-details',
  templateUrl: './doctor-details.component.html',
  styleUrls: ['./doctor-details.component.scss']
})
export class DoctorDetailsComponent implements OnInit, AfterViewInit {

  doctor: Doctor;
  practice: Practice;
  extendedColorBg: string;
  avatarVersion: string;
  avatarPicture: string;
  showMessageNotificationWebNoSlot = showMessageNotificationWebNoSlot;
  @Input() agenda: Agenda;
  @Input() isPracticeView: boolean;
  @Input() hasDoctorImage = true;
  @Input() specialitiesAliases: Array<any> = [];

  @ViewChild(CalendarComponent, {static: false})
  private calendarComponent: CalendarComponent;

  constructor(
    public calendarService: CalendarService,
    private doctorService: DoctorService,
    public translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    public utilitiesService: UtilitiesService,
  ) {
  }

  ngOnInit() {
    this.extendedColorBg = extendedColorBg;
    this.doctor = this.agenda.doctor;
    this.practice = this.agenda.practice;

    this.doctorService.getAvatarPicture(this.doctor.externalId,
      response => this.getAvatarPictureSuccessCallback(response),
      response => this.getAvatarPictureErrorCallback(response));
  }

  ngAfterViewInit() {
    this.calendarService.setCalendarFormat(window.innerWidth);
  }

  getAvatarPictureSuccessCallback(response) {
    if (response.length === 0 || response.profile === 'undefined' || response.profile.remote_id === 'undefined') {
      this.setDefaultAvatar();
      return;
    }
    this.avatarPicture = this.utilitiesService
      .getCloudinaryImageWithParams(response.profile.ssl, 'c_thumb,f_auto,g_face,h_300,w_300,z_0.7');
  }

  getAvatarPictureErrorCallback(response) {
    this.setDefaultAvatar();
  }

  private extractVersion(cloudinaryUrl) {
    // Get avatar version to invalidate cache from Cloudinary
    const regex = /v\d*/g;
    const versionArray = cloudinaryUrl.match(regex);
    this.avatarVersion = null;

    if (versionArray !== null && versionArray.length > 0) {
      const index = versionArray.length === 1 ? 0 : 1;
      this.avatarVersion = versionArray[index].replace('v', '');
    }
  }

  private setDefaultAvatar() {
    this.avatarPicture = this.utilitiesService
      .getCloudinaryImageWithParams(this.doctor.avatarUrl, 'c_thumb,f_auto,g_face,h_300,w_300,z_0.7');
  }

  /**
   * Will navigate to the doctor's practice views, if it has several doctors
   */
  loadPracticeView() {
    this.router.navigate([
      this.route.snapshot.parent.paramMap.get('language'),
      'practice',
      this.practice.externalId,
      {agendaId: this.agenda.externalId}
    ]);
  }

  /**
   * This function will check that all conditions are required in order to display
   * the link that leads to the practice view of the current agenda.
   */
  shouldShowPracticeLink() {
    // The practice link should only be shown on doctor view
    if (hideColleaguesAvailabilities || this.isPracticeView || (typeof this.calendarService.appointmentReschedule !== 'undefined')) {
      return false;
    }

    /*
      We check that at least 2 agendas in the practice (that belong to different doctors)
      have a valid status to be shown (not offline (0), private (2) or draft (8))
    */
    const doctorCount = {};
    this.practice.agendas.forEach((agenda) => {
      if (Agenda.displayableStatuses.includes(agenda.status.toString() as any)) {
        doctorCount[agenda.doctor.id] = true;
      }
    });

    return Object.keys(doctorCount).length > 1;
  }

  /**
   * Get doctor's speciality name
   */
  getSpecialityName() {
    if (this.specialitiesAliases.length > 0) {
      const specialityAlias = this.specialitiesAliases.find(alias => alias.eid === this.doctor.mainSpeciality.externalId);
      return specialityAlias ? (specialityAlias[this.translateService.currentLang] ?? this.doctor.mainSpeciality.name) :
        this.doctor.mainSpeciality.name;
    }

    return this.doctor.mainSpeciality.name;
  }
}
