import {Speciality} from './speciality';

export class User {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  role: Array<User.RoleEnum>;
  status: User.StatusEnum;
  externalId: string;
  language: string;
  createdDate: Date;
  updatedDate: Date;
  mainSpeciality: Speciality;
  avatar: string;
  avatarUrl: string;
}

export namespace User {
  export enum RoleEnum {
    DOCTOR = <any> 'doctor',
    PATIENT = <any> 'patient',
  }

  export enum StatusEnum {
    Offline = <any> '0',
    Online = <any> '1',
    Private = <any> '2',
    SemiPrivate = <any> '4',
  }
}
