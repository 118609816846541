<!--List of generated translation by booking rules generation-->
<div *ngIf="false" xmlns="http://www.w3.org/1999/html">
  <input type="hidden" i18n-placeholder="@@__legend_field_required"
         placeholder="{{ '__legend_field_required' | translate}}">
  <input type="hidden" i18n-placeholder="@@__name_min_char_legend"
         placeholder="{{ '__name_min_char_legend' | translate}}">
  <input type="hidden" i18n-placeholder="@@__legend_valid_email"
         placeholder="{{ '__legend_valid_email' | translate}}">
  <input type="hidden" i18n-placeholder="@@__error_decrypt_user_data"
         placeholder="{{ '__error_decrypt_user_data' | translate}}">
  <input type="hidden" i18n-placeholder="@@__legend_valid_date"
         placeholder="{{ '__legend_valid_date' | translate}}">
  <input type="hidden" i18n-placeholder="@@__legend_valid_number"
         placeholder="{{ '__legend_valid_number' | translate}}">
  <input type="hidden" i18n-placeholder="@@__legend_mobile_number_required"
         placeholder="{{ '__legend_mobile_number_required' | translate}}">
  <input type="hidden" i18n-placeholder="@@__booking_gdpr_shortdesc"
         placeholder="{{ '__booking_gdpr_shortdesc' | translate}}">
  <input type="hidden" i18n-placeholder="@@__matricule_error_invalid"
         placeholder="{{ '__matricule_error_invalid' | translate}}">
  <input type="hidden" i18n-placeholder="@@__core_service_processing_link"
         placeholder="{{ '__core_service_processing_link' | translate}}">
  <input type="hidden" i18n-placeholder="@@__form_appointment_matricule"
         placeholder="{{ '__form_appointment_matricule' | translate}}">
  <input type="hidden" i18n-placeholder="@@__form_appointment_matricule_be"
         placeholder="{{ '__form_appointment_matricule_be' | translate}}">
  <input type="hidden" i18n-placeholder="@@__form_appointment_matricule_de"
         placeholder="{{ '__form_appointment_matricule_de' | translate}}">
  <input type="hidden" i18n-placeholder="@@__form_appointment_matricule_ch"
         placeholder="{{ '__form_appointment_matricule_ch' | translate}}">
  <input type="hidden" i18n-placeholder="@@__form_appointment_matricule_nl"
         placeholder="{{ '__form_appointment_matricule_nl' | translate}}">
  <input type="hidden" i18n-placeholder="@@__gender_other"
         placeholder="{{ '__gender_other' | translate}}">
  <input type="hidden" i18n-placeholder="@@__gender_other_de"
         placeholder="{{ '__gender_other_de' | translate}}">
</div>

<app-alert></app-alert>
<app-loader></app-loader>

<div class="booking" *ngIf="calendarService.selectedSlot && calendarService.selectedAgenda">
  <mat-horizontal-stepper
    [linear]="isLinear"
    (click)="waitBeforeNewStep()"
    (selectionChange)="changeStep($event)"
    #stepper>

    <!--    Implement Binfli 'anamnesis' feature-->
    <app-bingli-form *ngIf="false">
    </app-bingli-form>

    <!-- STEP 1 - BOOKING DETAILS -->
    <mat-step [editable]="!isCompleted" [stepControl]="consultationGroup">
      <form [formGroup]="consultationGroup">
        <ng-template matStepLabel
                     i18n="@@__booking_patient_info_step1">{{'__booking_patient_info_step1' | translate}}</ng-template>

        <!-- Doctor's remark (GO Doctor) -->
        <div class="col-12" *ngIf="getDoctorRemark()">
          <p class="no-mg-bottom" i18n="@@__remark_from_practitioner">
            <strong>{{'__remark_from_practitioner' | translate}}</strong>
          </p>
          <p>{{getDoctorRemark()}}</p>
        </div>
        <!-- Doctor custom message -->
        <div class="alert alert-info" *ngIf="calendarService.customMessage">
          <p class="semi-mg-bottom"><strong i18n="@@__custom_notification_title">
            {{ '__custom_notification_title' | translate | sprintf: calendarService.customMessage.practitionerName }}
          </strong></p>

          <p class="no-mg-bottom newlines">{{ calendarService.customMessage.message }}</p>

          <!-- Optional checkbox for agreement with custom message -->
          <div class="formElement formElement--alert semi-mg-top"
               *ngIf="calendarService.customMessage && calendarService.customMessage.hasReadCheckbox">
            <mat-checkbox formControlName="customMessageAgreement">
              <label i18n="@@__custom_notification_has_read_checkbox_label">
                {{'__custom_notification_has_read_checkbox_label' | translate}}
                <mat-error class="d-inline-block"
                           *ngIf="!firstStepPristine && consultationGroup.controls.customMessageAgreement.hasError('required')">
                  *
                </mat-error>
              </label>
            </mat-checkbox>
            <mat-error
              *ngIf="!firstStepPristine && consultationGroup.controls.customMessageAgreement.hasError('required')">
              {{ '__legend_field_required' | translate}}
            </mat-error>
          </div>
        </div>

        <!-- Video consultation warning -->
        <div class="alert alert-success"
             *ngIf="calendarService.selectedAgenda.teleconsultation && selectedReason.teleconsultation">
          <div class="formElement formElement--alert semi-mg-top">
            <mat-checkbox formControlName="teleConsultationValidation">
              <label i18n="@@__teleconsultation_booking_warning">
                {{'__teleconsultation_booking_warning' | translate}}
                <mat-error class="d-inline-block"
                           *ngIf="!firstStepPristine && consultationGroup.controls.teleConsultationValidation.hasError('required')">
                  *
                </mat-error>
              </label>
            </mat-checkbox>
            <mat-error
              *ngIf="!firstStepPristine && consultationGroup.controls.teleConsultationValidation.hasError('required')">
              {{ '__legend_field_required' | translate}}
            </mat-error>
            <a [href]="teleconsultationInfoUrl" target="_blank" i18n="@@__learn_more">
              {{ '__learn_more' | translate }}
            </a>
          </div>
        </div>

        <!-- Radio field : Is Doctena user ? -->
        <div class="formElement" [hidden]="(patientService.patient || isReschedule || userData || !bookingLoginDoctenaAccount)"
             *ngIf="!isBingli()">
          <label class="col-12 control-label" for="isDoctenaUser" i18n="@@form_appointment_doctena_user">
            {{'form_appointment_doctena_user' | translate}}
          </label>
          <mat-radio-group
            class="col-12" id="isDoctenaUser" formControlName="isDoctenaUser">
            <mat-radio-button value="" [checked]="true"
                              i18n="@@__label_no">{{'__label_no' | translate}}</mat-radio-button>
            <mat-radio-button value="true" i18n="@@__label_yes">{{'__label_yes' | translate}}</mat-radio-button>
          </mat-radio-group>
          <br/><br/>
          <!-- Login form -->
          <app-patient-login
            (isLogged)="setIsLogged($event)"
            [countryIso]="countryIso"
            [consultationGroup]="consultationGroup"
            [showPatientLoginForm]="consultationGroup.value['isDoctenaUser'] && !patientService.patient">
          </app-patient-login>
        </div>

        <!-- Returning patient radio input -->
        <div [hidden]="!showPatientReturningInput || hidePatientRelation" class="formElement">
          <label class="col-12 control-label" for="returningPatient"
                 i18n="@@__booking_form_appointment_returning_patient">{{'__booking_form_appointment_returning_patient' | translate}}</label>
          <mat-radio-group class="col-12" id="returningPatient" formControlName="returningPatient">
            <mat-radio-button
              [checked]="consultationGroup.value['returningPatient'] === 'false'"
              value="false"
              i18n="@@__label_new_patient">
              {{'__label_new_patient' | translate}}
            </mat-radio-button>
            <mat-radio-button
              [checked]="consultationGroup.value['returningPatient'] === 'true'"
              value="true"
              i18n="@@__label_returning_patient">
              {{'__label_returning_patient' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <!-- Reason of visit -->
        <div class="formElement">
          <label class="col-12 control-label" for="reason"
                 i18n="@@__form_appointment_reason"> {{'__form_appointment_reason' | translate}}</label>
          <mat-form-field id="reason" class="col-md-12 col-lg-8 col-xl-7 d-block">
            <input matInput [value]="selectedReason.name" disabled>
          </mat-form-field>

          <mat-form-field class="col-md-12 col-lg-8 col-xl-7 d-block patient-note" *ngIf="patientNoteEnable">
            <textarea
              class="patient-note-textarea"
              matInput
              #message
              maxlength="500"
              formControlName="patientNote"
              i18n-placeholder="@@__form_appointment_reason_note_placeholder"
              placeholder="{{ message.value ? '' : '__form_appointment_reason_note_placeholder' | translate}}">
            </textarea>
            <mat-hint align="end">{{message.value.length}} / 500</mat-hint>
          </mat-form-field>
        </div>

        <!-- CUSTOM FIELDS FOR APPOINTMENT -->
        <app-fieldset
          *ngIf="customFields.appointment"
          [fields]="customFields.appointment.fields"
          [parentForm]="consultationGroup"
          [isPristine]="firstStepPristine"
          (keyDownEvent)="monitorKeyInput($event)"
        >
        </app-fieldset>

        <div class="col-12 action-buttons">
          <button (click)="onClickBack()" class="btn-secondary float-left" mat-button matStepperPrevious
                  i18n="@@__label_back">{{'__label_back' | translate}}</button>
          <button (click)="onClickNext(0)" class="btn-success float-right" mat-button matStepperNext
                  i18n="@@__label_next"> {{'__label_next' | translate}}</button>
        </div>
      </form>
    </mat-step>

    <!-- STEP 2 : patient details -->
    <mat-step [editable]="!isCompleted" [stepControl]="personnalGroup">
      <form class="form-step-user" [formGroup]="personnalGroup">
        <ng-template matStepLabel
                     i18n="@@__booking_patient_info_step2">{{'__booking_patient_info_step2' | translate}}</ng-template>

        <h5 class="col-12" i18n="@@__form_information_patient_legend">
          {{ '__form_information_patient_legend' | translate}}
        </h5>

        <!-- patient name -->
        <div class="formElement">
          <label class="col-12 no-mg-bottom" i18n="@@__name_patient">
            <strong>{{ '__name_patient' | translate }}</strong>
          </label>

          <!-- patient first name -->
          <mat-form-field class="col-12 col-md-5 d-inline-block">
            <input matInput i18n-placeholder="@@__user_first_name" placeholder="{{ '__user_first_name' | translate}}"
                   formControlName="patientFirstName" required [readonly]="isBingli()"
                   (keydown)="monitorKeyInput($event, personnalGroup.value['patientFirstName'])">
            <mat-error
              *ngIf="personnalGroup.controls.patientFirstName.hasError('required') && !personnalGroup.controls.patientFirstName.hasError('pattern')">
              {{ '__legend_field_required' | translate}}
            </mat-error>
            <mat-error
              *ngIf="!personnalGroup.controls.patientFirstName.hasError('required') && personnalGroup.controls.patientFirstName.hasError('pattern')">
              {{ '__name_min_char_legend' | translate}}
            </mat-error>
          </mat-form-field>

          <!-- patient last name -->
          <mat-form-field class="col-12 col-md-5 d-inline-block float-right">
            <input matInput i18n-placeholder="@@__user_last_name" placeholder="{{ '__user_last_name' | translate}}"
                   formControlName="patientLastName" required [readonly]="isBingli()"
                   (keydown)="monitorKeyInput($event, personnalGroup.value['patientLastName'])">
            <mat-error
              *ngIf="personnalGroup.controls.patientLastName.hasError('required') && !personnalGroup.controls.patientLastName.hasError('pattern')">
              {{ '__legend_field_required' | translate}}
            </mat-error>
            <mat-error
              *ngIf="!personnalGroup.controls.patientLastName.hasError('required') && personnalGroup.controls.patientLastName.hasError('pattern')">
              {{ '__name_min_char_legend' | translate}}
            </mat-error>
          </mat-form-field>
        </div>

        <!-- gender radio input -->
        <div class="formElement">
          <mat-form-field id="genderInputForm" class="col-12 col-md-5">
            <mat-select id="patientGender" formControlName="patientGender" class="mat-select- -no-underline"
                        i18n-placeholder="@@__label_gender"
                        placeholder="{{'__label_gender' | translate}}*" required>
              <mat-option value="male">{{'__gender_male' | translate}}</mat-option>
              <mat-option value="female">{{'__gender_female' | translate}}</mat-option>
              <mat-option value="other">{{getGenderOtherLabel() | translate}}</mat-option>
              <mat-option value="prefer_not_to_say">{{'__gender_prefer_not_to_say' | translate}}</mat-option>
            </mat-select>
            <mat-error class="col-12"
                       *ngIf="personnalGroup.controls.patientGender.hasError('required')">
              {{ '__legend_field_required' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <!-- birthday datepicker -->
        <div class="formElement">
          <mat-form-field id="patientBirthday" class="col-12 col-md-5">
            <mat-label i18n="@@__form_appointment_birthday"> {{'__form_appointment_birthday' | translate}}</mat-label>
            <input
              matInput
              formControlName="patientBirthday" required
              placeholder="{{'DD'+datePickerDelimeter+'MM'+datePickerDelimeter+'YYYY'}}"
              [readonly]="isBingli()"
              [max]="calendarService.today"
              [matDatepicker]="picker"
              (dateChange)="onDateChange($event)"
              (keydown)="monitorKeyInput($event, personnalGroup.value['patientBirthday'])">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker [disabled]="isBingli()"></mat-datepicker>
            <mat-error *ngIf="personnalGroup.controls.patientBirthday.hasError('required')">
              {{ '__legend_field_required' | translate}}
            </mat-error>
            <mat-error
              *ngIf="!personnalGroup.controls.patientBirthday.hasError('required') && personnalGroup.controls.patientBirthday.invalid">
              {{ '__legend_valid_date' | translate}}
            </mat-error>

          </mat-form-field>
        </div>

        <!-- matricule -->
        <div
          class="formElement matriculeFormElement col-12 col-md-5"
          *ngIf="customFields.matricule || userData">
          <input
            *ngIf="customFields.matricule || userData"
            type="matricule"
            id="matriculeInput"
            class="col-12 col-md-3"
            #matriculeselect
            formControlName="matricule"
            matInput
            [maxlength]="customFields.matricule.fields[0]?.ui_properties?.max ?? null"
            placeholder="{{getMatriculeLabel() | translate}}"
            [required]="customFields.matricule.fields[0]?.validators?.required ?? false"
            (input)="handleMatriculeChange($event)"
          >
          <div class="col-12 col-md-6 mat-input-underline mat-form-field-underline">
            <span class="mat-input-ripple mat-form-field-ripple"></span>
          </div>
          <mat-error
            class="col-12"
            *ngIf="(customFields.matricule || userData) && !secondStepPristine && personnalGroup.controls.matricule.hasError('required')"
          >
            {{ '__legend_field_required' | translate }}
          </mat-error>
          <mat-error
            class="col-12"
            *ngIf="(customFields.matricule || userData) && personnalGroup.value['matricule'] && personnalGroup.controls.matricule.hasError('minlength')">
            {{ '__name_min_char_legend' | translate }}
          </mat-error>
          <mat-error
            class="col-12"
            *ngIf="(customFields.matricule || userData) && personnalGroup.value['matricule'] && (personnalGroup.controls.matricule.hasError('pattern') || personnalGroup.controls.matricule.hasError('invalid'))">
            {{ '__matricule_error_invalid' | translate }}
          </mat-error>
        </div>

        <!-- CUSTOM FIELDS FOR PATIENT -->
        <app-fieldset *ngIf="customFields.patient" [fields]="customFields.patient.fields"
                      [parentForm]="personnalGroup" [isPristine]="secondStepPristine"
                      (keyDownEvent)="monitorKeyInput($event)">
        </app-fieldset>

        <!-- User name -->
        <div *ngIf="personnalGroup.controls.userFirstName" class="major-person-information">

          <h5 class="major-person-title"
              i18n="@@__form_appointment_contact_legend">{{ '__form_appointment_contact_legend' | translate}}</h5>

          <p class="major-person-desc"
             i18n="@@__form_appointment_message_underage">{{ '__form_appointment_message_underage' | translate | sprintf: String(majorityAgeMin)}}</p>

          <div class="formElement">

            <!-- User first name -->
            <mat-form-field class="col-12 col-md-5 d-inline-block">
              <input matInput i18n-placeholder="@@__user_first_name" placeholder="{{ '__user_first_name' | translate}}"
                     formControlName="userFirstName" required
                     (keydown)="monitorKeyInput($event, personnalGroup.value['userFirstName'])">
              <mat-error
                *ngIf="personnalGroup.controls.userFirstName.hasError('required') && !personnalGroup.controls.userFirstName.hasError('pattern')">
                {{ '__legend_field_required' | translate}}
              </mat-error>
              <mat-error
                *ngIf="!personnalGroup.controls.userFirstName.hasError('required') && personnalGroup.controls.userFirstName.hasError('pattern')">
                {{ '__name_min_char_legend' | translate}}
              </mat-error>
            </mat-form-field>

            <!-- User last name -->
            <mat-form-field class="col-12 col-md-5 d-inline-block float-right">
              <input matInput i18n-placeholder="@@__user_last_name" placeholder="{{ '__user_last_name' | translate}}"
                     formControlName="userLastName" required
                     (keydown)="monitorKeyInput($event, personnalGroup.value['userLastName'])">
              <mat-error
                *ngIf="personnalGroup.controls.userLastName.hasError('required') && !personnalGroup.controls.userLastName.hasError('pattern')">
                {{ '__legend_field_required' | translate}}
              </mat-error>
              <mat-error
                *ngIf="!personnalGroup.controls.userLastName.hasError('required') && personnalGroup.controls.userLastName.hasError('pattern')">
                {{ '__name_min_char_legend' | translate}}
              </mat-error>
            </mat-form-field>

          </div>
        </div>

        <!-- Email -->
        <div class="formElement">
          <mat-form-field class="col-12 col-md-5">
            <input matInput type="email" id="userEmail" i18n-placeholder="@@__form_appointment_email"
                   placeholder="{{ '__form_appointment_email' | translate}}" formControlName="userEmail"
                   required (keydown)="monitorKeyInput($event, personnalGroup.value['userEmail'])">
            <mat-error
              *ngIf="personnalGroup.controls.userEmail.hasError('email') && !personnalGroup.controls.userEmail.hasError('required')">
              {{ '__legend_valid_email' | translate}}
            </mat-error>
            <mat-error *ngIf="personnalGroup.controls.userEmail.hasError('required')">
              {{ '__legend_field_required' | translate}}
            </mat-error>
          </mat-form-field>
        </div>

        <!-- mobile phone number -->
        <div
          class="formElement mobileFormElement col-12 col-md-5"
          [ngClass]="{'mat-form-field-invalid':(!secondStepPristine && personnalGroup.controls.userMobile.hasError('required'))}"
        >
          <input type="tel" id="userMobileInput" class="col-12 col-md-3" #phoneSelect formControlName="userMobile"
                 matInput (keydown)="monitorKeyInput($event, personnalGroup.value['userMobile'])">
          <div class="col-12 col-md-6 mat-input-underline mat-form-field-underline">
            <span class="mat-input-ripple mat-form-field-ripple"></span>
          </div>
          <mat-error class="col-12"
                     *ngIf="!secondStepPristine && personnalGroup.controls.userMobile.hasError('required')">
            {{ '__legend_field_required' | translate}}
          </mat-error>
          <mat-error class="col-12"
                     *ngIf="(!personnalGroup.controls.userMobile.hasError('required') && !isValidNumber()) || (!personnalGroup.controls.userMobile.hasError('required') && personnalGroup.controls.userMobile.hasError('pattern'))">
            {{ '__legend_valid_number' | translate}}
          </mat-error>
          <mat-error class="col-12"
                     *ngIf="!personnalGroup.controls.userMobile.hasError('required') && isValidNumber() && !personnalGroup.controls.userMobile.hasError('pattern') && !isMobileNumber()">
            {{ '__legend_mobile_number_required' | translate}}
          </mat-error>
        </div>

        <!-- CUSTOM QUESTIONNAIRE or CUSTOM QUESTIONS -->
        <div class="custom-questions" *ngIf="customFields.questions">
          <h5 i18n="@__form_appointment_questions_legend" class="col-12 mg-top">
            {{ '__form_appointment_questions_legend' | translate}}
          </h5>
          <app-fieldset *ngIf="customFields.questions" [fields]="customFields.questions.fields"
                        [parentForm]="personnalGroup" [isPristine]="secondStepPristine"
                        (keyDownEvent)="monitorKeyInput($event)">
          </app-fieldset>
        </div>

        <!-- Checkbox create patient account -->
        <div class="formElement" *ngIf="!patientService.patient && !isReschedule && !userData && bookingLoginDoctenaAccount">
          <mat-checkbox class="col-12" formControlName="createPatientAccount">
            <label i18n="@@__form_appointment_new_account">
              {{'__form_appointment_new_account' | translate}}
            </label>
          </mat-checkbox>
        </div>

        <!-- Checkbox LMA -->
        <div class="formElement"
             *ngIf="patientService.patient && calendarService.selectedAgenda.subscribed_lma && isSlotInOneDay">
          <mat-checkbox class="col-12" formControlName="notifyLma">
            <label i18n="@@__be_notified_earlier_availability">
              {{'__be_notified_earlier_availability' | translate}}
            </label>
          </mat-checkbox>
        </div>
        <br>
        <!-- CGU + Policy Agreements -->
        <div class="formElement termsFormElement">
            <span i18n="@@__signup_gdpr_shortdesc_v2"
                  [innerHTML]="'__signup_gdpr_shortdesc_v2' | translate | sprintf: coreProcessingTags[0] : coreProcessingTags[1]">
            </span>
          <br><br>
          <div id="core_service_processing" *ngIf="isShowCoreProcessing">
            <table>
              <!--              Activity -->
              <tr>
                <th i18n="@@__core_service_processing_activity_title"
                    [innerHTML]="'__core_service_processing_activity_title' | translate"></th>
                <th i18n="@@__core_service_processing_data_characteristics_title"
                    [innerHTML]="'__core_service_processing_data_characteristics_title' | translate"></th>
                <th i18n="@@__core_service_processing_legal_basis_title"
                    [innerHTML]="'__core_service_processing_legal_basis_title' | translate"></th>
              </tr>
              <!--              Patient data -->
              <tr>
                <td i18n="@@__processing_activity_patient_data"
                    [innerHTML]="'__processing_activity_patient_data' | translate"></td>
                <td i18n="@@__data_characteristics_processing_activity_patient_data"
                    [innerHTML]="'__data_characteristics_processing_activity_patient_data' | translate"></td>
                <td i18n="@@__legal_basis_processing_activity_patient_data"
                    [innerHTML]="'__legal_basis_processing_activity_patient_data' | translate"></td>
              </tr>
              <!--              Medical history questions -->
              <tr>
                <td i18n="@@__processing_activity_medical_history"
                    [innerHTML]="'__processing_activity_medical_history' | translate"></td>
                <td i18n="@@__data_characteristics_processing_medical_history"
                    [innerHTML]="'__data_characteristics_processing_medical_history' | translate"></td>
                <td i18n="@@__legal_basis_processing_medical_history"
                    [innerHTML]="'__legal_basis_processing_medical_history' | translate"></td>
              </tr>
              <!--              Reason of visits -->
              <tr>
                <td i18n="@@__processing_activity_reason_of_visit"
                    [innerHTML]="'__processing_activity_reason_of_visit' | translate"></td>
                <td i18n="@@__data_characteristics_processing_activity_reason_of_visit"
                    [innerHTML]="'__data_characteristics_processing_activity_reason_of_visit' | translate"></td>
                <td i18n="@@__legal_basis_processing_activity_reason_of_visit"
                    [innerHTML]="'__legal_basis_processing_activity_reason_of_visit' | translate"></td>
              </tr>
              <!--              Contract data -->
              <tr>
                <td i18n="@@__processing_activity_verification_of_your_contract_data"
                    [innerHTML]="'__processing_activity_verification_of_your_contract_data' | translate"></td>
                <td i18n="@@__data_characteristics_processing_activity_verification_of_your_contract_data"
                    [innerHTML]="'__data_characteristics_processing_activity_verification_of_your_contract_data' | translate"></td>
                <td i18n="@@__legal_basis_processing_activity_verification_of_your_contract_data"
                    [innerHTML]="'__legal_basis_processing_activity_verification_of_your_contract_data' | translate"></td>
              </tr>
              <!--              Notifications -->
              <tr>
                <td i18n="@@__processing_activity_notifications"
                    [innerHTML]="'__processing_activity_notifications' | translate"></td>
                <td i18n="@@__data_characteristics_processing_activity_notifications"
                    [innerHTML]="'__data_characteristics_processing_activity_notifications' | translate"></td>
                <td i18n="@@__legal_basis_processing_activity_notifications"
                    [innerHTML]="'__legal_basis_processing_activity_notifications' | translate"></td>
              </tr>
              <!--              Feedback -->
              <tr>
                <td i18n="@@__processing_activity_feedback"
                    [innerHTML]="'__processing_activity_feedback' | translate"></td>
                <td i18n="@@__data_characteristics_processing_activity_feedback"
                    [innerHTML]="'__data_characteristics_processing_activity_feedback' | translate"></td>
                <td i18n="@@__legal_basis_processing_activity_feedback"
                    [innerHTML]="'__legal_basis_processing_activity_feedback' | translate"></td>
              </tr>
              <!--              Health insurance -->
              <tr>
                <td i18n="@@__processing_activity_health_insurance"
                    [innerHTML]="'__processing_activity_health_insurance' | translate"></td>
                <td i18n="@@__data_characteristics_processing_health_insurance"
                    [innerHTML]="'__data_characteristics_processing_health_insurance' | translate"></td>
                <td i18n="@@__legal_basis_processing_health_insurance"
                    [innerHTML]="'__legal_basis_processing_health_insurance' | translate"></td>
              </tr>
              <!--              Video consultation -->
              <tr>
                <td i18n="@@__processing_video_consultation"
                    [innerHTML]="'__processing_video_consultation' | translate"></td>
                <td i18n="@@__data_characteristics_video_consultation"
                    [innerHTML]="'__data_characteristics_video_consultation' | translate"></td>
                <td i18n="@@__legal_basis_processing_video_consultation"
                    [innerHTML]="'__legal_basis_processing_video_consultation' | translate"></td>
              </tr>
            </table>
            <p i18n="@@__processing_activity_notes_v2" [innerHTML]="'__processing_activity_notes_v2' | translate"></p>
          </div>
        </div>
        <div class="formElement formElement--alert semi-mg-top">
          <mat-checkbox formControlName="cguAgreement">
            <label i18n="@@__signup_gdpr_shortdesc_v2_termAndConditions"
                   [innerHTML]="'__signup_gdpr_shortdesc_v2_termAndConditions' | translate | sprintf:
                termsLinksTags[0] : termsLinksTags[4]">
              <mat-error class="col-1 d-inline-block"
                         *ngIf="!secondStepPristine && personnalGroup.controls.cguAgreement.hasError('required')">*
              </mat-error>
            </label>
          </mat-checkbox>
          <mat-error
            *ngIf="!secondStepPristine && personnalGroup.controls.cguAgreement.hasError('required')">
            {{ '__legend_field_required' | translate}}
          </mat-error>
        </div>
        <div class="formElement formElement--alert semi-mg-top">
          <mat-checkbox formControlName="privacyPolicyAgreement">
            <label i18n="@@__signup_gdpr_shortdesc_v2_policy"
                   [innerHTML]="'__signup_gdpr_shortdesc_v2_policy' | translate | sprintf:
              termsLinksTags[1] : termsLinksTags[4]">
              <mat-error class="col-1 d-inline-block"
                         *ngIf="!secondStepPristine && personnalGroup.controls.privacyPolicyAgreement.hasError('required')">
                *
              </mat-error>
            </label>
          </mat-checkbox>
          <mat-error
            *ngIf="!secondStepPristine && personnalGroup.controls.privacyPolicyAgreement.hasError('required')">
            {{ '__legend_field_required' | translate}}
          </mat-error>
        </div>
        <br>
        <span
          i18n="@@__signup_gdpr_shortdesc_v2_bottom"
          [innerHTML]="'__signup_gdpr_shortdesc_v2_bottom' | translate | sprintf:
          termsLinksTags[3]">
        </span>
        <br><br>
        <div class="col-12">
          <button class="btn-secondary float-left" mat-button matStepperPrevious
                  i18n="@@__label_back">{{'__label_back' | translate}}</button>
          <button (click)="onClickNext(1)" class="btn-success float-right" mat-button matStepperNext
                  i18n="@@__label_next">{{'__label_next' | translate}}</button>
        </div>
      </form>
    </mat-step>

    <!-- STEP 3 -->
    <mat-step [editable]="!isCompleted" [stepControl]="confirmationGroup">
      <form [formGroup]="confirmationGroup">
        <ng-template matStepLabel i18n="@@__booking_verification">{{'__booking_verification' | translate}}</ng-template>
        <div class="formElement">
          <div class="col-12 col-md-6 col-lg-4 d-inline-block">
            <label class="col-12 control-label"
                   i18n="@@__form_verify_code_legend">{{'__form_verify_code_legend' | translate}}</label>
            <mat-form-field class="col-11">
              <input type="number" matInput formControlName="code" required
                     (keydown)="monitorKeyInput($event, confirmationGroup.value.code, 'true')">
            </mat-form-field>
            <br>
            <small class="col-12 form-text text-muted"
                   i18n="@@__confirmation_code_help">{{'__confirmation_code_help' | translate}}</small>
          </div>
          <div class="col-12 col-md-5 d-inline-block resendSms" *ngIf="resendSms">
            <span i18n="@@__appointment_sms_not_received">{{'__appointment_sms_not_received' | translate}}</span>
            <a rel="nofollow" href="javascript:void(0)" i18n="@@__appointment_resend_sms"
               (click)="resendSmsCode()">{{'__appointment_resend_sms' | translate}}</a>
          </div>
        </div>

        <div class="col-12">
          <button class="btn-secondary float-left" mat-button matStepperPrevious
                  i18n="@@__label_back">{{'__label_back' | translate}}</button>
          <button (click)="onClickNext(2)" class="btn-success float-right" mat-button matStepperNext
                  i18n="@@__label_next"> {{'__label_next' | translate}}</button>
        </div>
      </form>
    </mat-step>

    <!-- STEP 4 -->
    <mat-step [completed]="isCompleted">
      <ng-template matStepLabel i18n="@@__booking_finished">{{'__booking_finished' | translate}}</ng-template>
      <div class="formElement">
        <mat-card appearance="outlined" id="dba-booking-success-text" class="col-12 alert alert-success"
                  *ngIf="!appointmentAlreadyAccepted && isCompleted">
          <h4 i18n="@@__booking_success_title">{{'__booking_success_title' | translate}}</h4>
          <p i18n="@@__booking_success_description_first">{{'__booking_success_description_first' | translate}}</p>
          <p i18n="@@__booking_success_description_second">{{'__booking_success_description_second' | translate}}</p>
          <p i18n="@@__booking_success_description_third">{{'__booking_success_description_third' | translate}}</p>
        </mat-card>

        <mat-card appearance="outlined" id="dba-booking-success-confirmed-text" class="col-12 alert alert-success"
                  *ngIf="appointmentAlreadyAccepted && isCompleted">
          <h4 i18n="@@__booking_success_title_confirmed">
            {{'__booking_success_title_confirmed' | translate}}
          </h4>
          <p i18n="@@__booking_confirmed_description">
            {{'__booking_confirmed_description' | translate}}
          </p>
        </mat-card>

        <mat-card appearance="outlined" class="col-12 alert alert-doctena booking-cpp" *ngIf="false">
          <div class="booking-cpp__text">
            <h4 i18n="@@__booking_success_doctena_link_title">
              {{'__booking_success_doctena_link_title' | translate}}
            </h4>
            <p class="booking-cpp__description" i18n="@@__booking_success_doctena_link_text">
              {{'__booking_success_doctena_link_text' | translate}}
            </p>
          </div>
          <a
            [href]="'https://' + translateService.currentLang + '.doctena.' + countryIso"
            target="_blank">
            <button class="btn btn-success" i18n="@@__booking_success_doctena_link_button">
              {{'__booking_success_doctena_link_button' | translate}}
            </button>
          </a>
        </mat-card>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
