import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HelpersService} from '../service/helpers/helpers.service';
import {PracticeService} from '../service/practice/practice.service';
import {AgendaService} from '../service/agenda/agenda.service';
import {TranslateService} from '@ngx-translate/core';
import { HttpParams } from '@angular/common/http';
import {CalendarService} from '../service/calendar/calendar.service';
import {Agenda} from '../models/agenda';
import {environment} from '../../environments/environment';
import {TrackingService} from '../service/tracking/tracking.service';
import {ReviewsService} from '../service/reviews/reviews.service';
import {
  gatewayName,
  homeLoginDoctenaAccount,
  hidePowerBy,
  ignoreDoctors,
  hideCookieBanner
} from '../routing/routing.component';
import async from 'async';
import {Alert} from '../models/alert';
import {AuthenticationService} from '../service/authentication/authentication.service';
import {AlertService} from '../service/alert/alert.service';
import {UtilitiesService} from '../service/utilities/utilities.service';
import {FormBuilder, UntypedFormGroup} from '@angular/forms';
import {BingliTrait} from '../trait/bingli.trait';

@Component({
  selector: 'app-practice',
  templateUrl: './practice.component.html',
  styleUrls: ['./practice.component.scss']
})
export class PracticeComponent extends BingliTrait implements OnInit {
  practiceEid: string = this.route.snapshot.paramMap.get('id');
  agendas: Array<Agenda>;
  isPracticeView = true;
  practiceAgendas: Array<any>;
  profileUrl: string;
  countryIso: string;
  policyUrl: string;
  hidePowerBy = hidePowerBy;
  homeLoginDoctenaAccount = homeLoginDoctenaAccount;
  specialitiesAliases: Array<any> = [];
  consultationGroup: UntypedFormGroup;
  showPatientLoginForm: boolean;
  isLogged = false;
  bingliFeature = false;
  showBingliForm = false;
  isBookingRulesSelected = false;
  practiceFeatures = [];
  isAccountRequestComplete = false;
  practiceName = '';
  practicePhone = '';
  hidePicture = this.route.snapshot.paramMap.get('showPicture') === '0';

  constructor(private _formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private helpers: HelpersService,
              private agendaService: AgendaService,
              private practiceService: PracticeService,
              public translateService: TranslateService,
              public calendarService: CalendarService,
              public trackingService: TrackingService,
              public reviewsService: ReviewsService,
              public authenticationService: AuthenticationService,
              public alertService: AlertService,
              public utilitiesService: UtilitiesService) {
    super();
    this.agendas = [];
    this.practiceAgendas = [];
    this.profileUrl = 'https://www.doctena.com';
  }

  ngOnInit() {
    if (!this.helpers.isGUID(this.practiceEid)) {
      console.error('The URL is not valid. The practice\'s id should be a GUID.');
      // Go to "Page not found"
      this.router.navigate([''], {relativeTo: this.route, skipLocationChange: true});
    }

    this.consultationGroup = this._formBuilder.group({isDoctenaUser: false});

    // Get bearer for the app
    this.authenticationService.authApp().subscribe(result => {
      if (result !== true) {
        // Login failed
        const alert = new Alert();
        alert.type = 'error';
        alert.text = '__title_error_occurred';
        this.alertService.setAlert(alert);
        return;
      }

      if (gatewayName) {
        this.utilitiesService.getGatewaySpecialitiesAliasName(gatewayName,
          response => this.getGatewaySpecialitiesAliasNameCallback(response), error => this.getGatewaySpecialitiesAliasNameError());
        return;
      }

      this.loadPractice();
    });
  }

  /**
   * Load practice data
   *
   * @private
   */
  private loadPractice() {
    // Get practice agendas
    let params = new HttpParams(); // Add params of select rules if we have
    params = params.append('language', this.translateService.currentLang);
    this.practiceService.getAgendasWithFeatures(this.practiceEid, params)
      .then(result => this.getPracticeAgendasCallback(result, params))
      .catch(error => console.error(error));
  }

  /**
   * utilisesService - getGatewaySpecialitiesAliasName callback
   *
   * @param specialitiesAliases
   * @private
   */
  private getGatewaySpecialitiesAliasNameCallback(specialitiesAliases: Array<any>) {
    if (specialitiesAliases && specialitiesAliases.length > 0) {
      this.specialitiesAliases = specialitiesAliases;
    }

    this.loadPractice();
  }

  /**
   * utilisesService - getGatewaySpecialitiesAliasName error callback
   *
   * @private
   */
  private getGatewaySpecialitiesAliasNameError() {
    const alert = new Alert();
    alert.type = 'warning';
    alert.text = '__title_error_occurred';
    this.alertService.setAlert(alert);

    this.loadPractice();
  }

  /**
   * Callback for practiceAgendas call
   *
   * @param response
   * @param {HttpParams} params
   */
  private getPracticeAgendasCallback(response, params: HttpParams) {
    this.countryIso = response.practiceCountryIso;
    this.practiceFeatures = response.features;
    this.calendarService.availableFeatures = response.features;
    // Filter by features
    if (this.practiceFeatures) {
      // Feature Bingli
      if (this.practiceFeatures.hasOwnProperty('triage') && this.practiceFeatures['triage'].hasOwnProperty('client_ref')) {
        // If one of agenda using bingli feature, we'll display the bingli survey
        if (!this.bingliFeature) {
          this.bingliFeature = true;
          this.completeBingliSurvey = false;
        }
      } else {
        // In case of wrong setup (ROV and self service) -  no bingli feature, but the rov have triageKey
        // create a central check for both
        this.completeBingliSurvey = true;
      }
    }

    if ((response.practiceAgendas || typeof response.practiceAgendas !== 'undefined') && response.practiceAgendas.length > 0) {
      let practiceAgendas = response.practiceAgendas;
      // If url params contains ignoreDoctors parameter, we exclude
      // the designated doctors from the list of doctors (using their ids)
      if (ignoreDoctors) {
        const ignoredEids = ignoreDoctors.split(',');
        practiceAgendas = practiceAgendas.filter(agenda =>
          ignoredEids.indexOf('' + agenda.doctor.externalId) === -1 // The quotes are for string comparison
        );
      }

      this.practiceAgendas = practiceAgendas;
      const self = this;
      // Get agenda for every practiceAgenda and once it's done, send request for reviews
      async.each(this.practiceAgendas, (agenda, callback) => {
        this.practiceName = agenda.practice.name;
        this.practicePhone = agenda.practice.phone;
        self.agendaService.getAgenda(
          agenda.externalId,
          responseAgenda => self.getAgendasCallback(responseAgenda, callback),
          params
        );
      }, function (err) {

        if (err) {
          console.error(err);
        }

        // Load reviews data with doctor eids
        const doctorsEids = response.practiceAgendas.map(agenda => agenda.doctor.externalId);
        self.reviewsService.getReviews(doctorsEids)
          .then(result => self.getReviewsCallback(result))
          .catch(error => console.error(error));
      });
    }
  }

  /**
   * Callback method for agenda call
   *
   * @param {Agenda} response
   */
  private getAgendasCallback(response: Agenda, asyncCallback) {
    if (response.id || typeof response.id !== 'undefined') {
      response.doctor.mainSpeciality = response.mainSpeciality;
      const languageCode = this.translateService.currentLang;
      this.policyUrl = environment.policy[languageCode];
      this.agendas.push(response);
      this.calendarService.addAgenda(response);
      this.profileUrl = this.calendarService.urlProfile('practice');
      if (this.trackingService.selectedAgenda === null) {
        this.trackingService.selectedAgenda = response;
      }
      this.trackingService.eventRequestWidgetLoaded('practice');
      asyncCallback(); // Signals to async that the function is finished
    }
  }

  isDisplayable() {
    return this.agendas.length > 0 && (this.agendas.length === this.practiceAgendas.length);
  }

  isHideCookieBanner() {
    return hideCookieBanner;
  }

  /**
   * If the URL has an agendaId in its route Params, returns true
   */
  isInitiatedByDoctorView() {
    return this.route.snapshot.params.agendaId;
  }

  /**
   * If the initial view is a doctorView, we can go back to it by calling this function
   */
  goBackToDoctorView() {
    this.router.navigate([
      this.route.snapshot.parent.paramMap.get('language'),
      'doctor',
      this.route.snapshot.params.agendaId
    ]);
  }

  private getReviewsCallback(response) {
    this.reviewsService.storeReviews(response);
  }

  /** Manage the patient login button */
  isDoctenaUser(status) {
    if (status) {
      this.showPatientLoginForm = true;
      return this.consultationGroup.patchValue({isDoctenaUser: true});
    }
    this.showPatientLoginForm = false;
    this.isAccountRequestComplete = true;
    return this.consultationGroup.patchValue({isDoctenaUser: false});
  }

  /**
   * Check if booking rules are all selected
   * @param status
   * @private
   */
  setStatusBookingRulesSelected(status: boolean) {
    this.isBookingRulesSelected = status;
    if (status) {
      this.showBingliForm = true;
    }
  }

  setIsLogged(isLogged: boolean) {
    this.isLogged = isLogged;
    this.showPatientLoginForm = !this.showPatientLoginForm;
  }

  // Manage the patient login button
  showPatientLogin() {
    return !(this.bingliFeature && this.isBookingRulesSelected) && homeLoginDoctenaAccount;
  }
}
