<div *ngIf="alertService.alert" [ngClass]="{ 'alert': alertService.alert, 'alert-success': alertService.alert.type === 'success', 'alert-warning': alertService.alert.type === 'warning', 'alert-danger': alertService.alert.type === 'error' }">
  {{ alertService.alert.text | translate }}
</div>

<!--List of generated translations for alerts-->
<div *ngIf="false">
  <input type="hidden" i18n-placeholder="@@__error_on_birthday"
         placeholder="{{ '__error_on_birthday' | translate}}">
  <input type="hidden" i18n-placeholder="@@__max_appointment_for_doctor"
         placeholder="{{ '__max_appointment_for_doctor' | translate}}">
  <input type="hidden" i18n-placeholder="@@__already_waiting_appointment_speciality"
         placeholder="{{ '__already_waiting_appointment_speciality' | translate}}">
  <input type="hidden" i18n-placeholder="@@__booking_error_title"
         placeholder="{{ '__booking_error_title' | translate}}">
  <input type="hidden" i18n-placeholder="@@__booking_slot_not_free_error_description"
         placeholder="{{ '__booking_slot_not_free_error_description' | translate}}">
  <input type="hidden" i18n-placeholder="@@__booking_code_error"
         placeholder="{{ '__booking_code_error' | translate}}">
  <input type="hidden" i18n-placeholder="@@__sms_not_send"
         placeholder="{{ '__sms_not_send' | translate}}">
  <input type="hidden" i18n-placeholder="@@__title_error_occurred"
         placeholder="{{ '__title_error_occurred' | translate}}">
  <input type="hidden" i18n-placeholder="@@__login_credentials_error"
         placeholder="{{ '__login_credentials_error' | translate}}">
  <input type="hidden" i18n-placeholder="@@__me_request_error"
         placeholder="{{ '__me_request_error' | translate}}">
  <input type="hidden" i18n-placeholder="@@__powered_by"
         placeholder="{{ '__powered_by' | translate}}">
</div>
