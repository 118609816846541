import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Agenda} from '../../models/agenda';
import {disableAnalyticsCookies, gaTrackingId, gtmTrackingId} from '../../routing/routing.component';
import {capitalize} from 'lodash';

declare let ga: Function;
declare let _paq: any;
declare let dataLayerDoctena;

@Injectable()
export class TrackingService {
  selectedAgenda: Agenda;
  trackers: Array<any>; // Allow us to send same data to different google Analytics tracking Ids
  googleTagManagerOn: boolean;

  constructor() {
    this.selectedAgenda = null;
    this.trackers = [];
    this.googleTagManagerOn = false;
  }

  /**
   * Called when user change step inside the stepper
   *
   * @param step
   * @param trackingId
   */
  eventRequestBooking(step, trackingId?) {


    // If this parameter is set we disable Google
    if ((disableAnalyticsCookies || environment.environmentName !== '') || (typeof ga === 'undefined')) {
      return;
    }

    if (typeof _paq !== 'undefined') {
      this.manageMatomoBookingEvents(step);
    }

    this.manageGoogleAnalyticsBookingEvents(step);
  }

  /**
   * Send event to Matomo based on booking steps
   *
   * @param step
   * @private
   */
  private manageMatomoBookingEvents(step) {
      switch (step) {
        case 'booking_patient_info_step1': // First step of stepper : Patient info
          _paq.push(['trackPageView', `/booking/request/widget/step1/${this.selectedAgenda.doctor.mainSpeciality.name}`]);
          break;
        case 'booking_patient_info_step2': // Personal info
          _paq.push(['trackPageView', `/booking/request/widget/step1.5/${this.selectedAgenda.doctor.mainSpeciality.name}`]);
          break;
        case 'booking_file_upload': // File upload step
          _paq.push(['trackPageView', `/booking/request/widget/step-file-upload/${this.selectedAgenda.doctor.mainSpeciality.name}`]);
          break;
        case 'booking_verification': // Confirmation step
          _paq.push(['trackPageView', `/booking/request/widget/step2/${this.selectedAgenda.doctor.mainSpeciality.name}`]);
          break;
        case 'booking_finished': // Success step
          _paq.push(['trackPageView', `/booking/request/widget/confirmed/${this.selectedAgenda.doctor.mainSpeciality.name}`]);
          _paq.push(['trackEvent', 'patients', 'appointment request confirmed_plugin', `Specialty: ${this.selectedAgenda.doctor.mainSpeciality.name}, Name: ${this.selectedAgenda.doctor.firstname} ${this.selectedAgenda.doctor.lastname}`]);
          break;
        default:
          break;
      }
  }

  /**
   * Send event to GA based on booking steps
   *
   * @param step
   * @private
   */
  private manageGoogleAnalyticsBookingEvents(step) {
    // Send events to each google Analytics tracker depending on the
    // current step of booking
    this.trackers.forEach(({trackingId, name}) => {
      const dataAnalytics = [];
      switch (step) {
        case 'booking_patient_info_step1': // First step of stepper : Patient info
          dataAnalytics.push([name + '.set', 'page', '/booking/request/widget/step1/' + this.selectedAgenda.doctor.mainSpeciality.name]);
          dataAnalytics.push([name + '.send', 'pageview']);
          break;
        case 'booking_patient_info_step2': // Personal info
          dataAnalytics.push([name + '.set', 'page', '/booking/request/widget/step1.5/' + this.selectedAgenda.doctor.mainSpeciality.name]);
          dataAnalytics.push([name + '.send', 'pageview']);
          break;
        case 'booking_file_upload': // File upload step
          dataAnalytics.push([name + '.set', 'page', '/booking/request/widget/step-file-upload/' + this.selectedAgenda.doctor.mainSpeciality.name]);
          dataAnalytics.push([name + '.send', 'pageview']);
          break;
        case 'booking_verification': // Confirmation step
          dataAnalytics.push([name + '.set', 'page', '/booking/request/widget/step2/' + this.selectedAgenda.doctor.mainSpeciality.name]);
          dataAnalytics.push([name + '.send', 'pageview']);
          break;
        case 'booking_finished': // Success step
          dataAnalytics.push([name + '.set', 'page', '/booking/request/widget/confirmed/' + this.selectedAgenda.doctor.mainSpeciality.name]);
          dataAnalytics.push([name + '.send', 'pageview']);
          dataAnalytics.push([name + '.send', 'event', {
            eventCategory: 'patients',
            eventAction: 'appointment request confirmed_plugin',
            eventLabel: 'Specialty: ' + this.selectedAgenda.doctor.mainSpeciality.name
              + ' ,Name: ' + this.selectedAgenda.doctor.firstname + ' ' + this.selectedAgenda.doctor.lastname,
            transport: 'beacon'
          }]);
          break;
        default:
          break;
      }

      // Send requests
      this.executeGoogleAnalytics(dataAnalytics);
    });
  }

  /**
   * Called when user load the widget
   *
   * @param profileName
   * @param profileData
   */
  eventRequestWidgetLoaded(profileName, profileData?) {

    // If this parameter is set we disable Google
    if (disableAnalyticsCookies || environment.environmentName !== '') {
      return;
    }

    this.loadMatomoTracker();

    setTimeout(() => {
      if (typeof _paq !== 'undefined') {
        _paq.push(['trackPageView', '/booking/request/widget/step0/']);
        _paq.push(['trackEvent', 'patients', 'booking widget loaded', profileName]);
      }
    }, 1000);

    // Google Analytics must be loaded only once
    if (typeof ga === 'undefined' && gaTrackingId) {
      this.loadGoogleAnalytics();
    }

    // Google Tag Manager must be loaded only once
    if (gtmTrackingId && !this.googleTagManagerOn) {
      this.loadGoogleTagManager();
    }

    // Send events to each Google Analytics trackers
    this.trackers.forEach(({trackingId, name}) => {
      const dataAnalytics = [
        [
          'create', trackingId, 'auto',
          {
            name: name,
            allowLinker: true
          }
        ],
        [
          name + '.set', 'anonymizeIp', true
        ],
        [
          name + '.set', 'page', '/booking/request/widget/step0/'
        ],
        [
          name + '.send', 'pageview'
        ],
        [
          name + '.send', 'event', {
          eventCategory: 'patients',
          eventAction: 'booking widget loaded',
          eventLabel: profileName,
          transport: 'beacon'
        }
        ]
      ];

      // Send requests
      this.executeGoogleAnalytics(dataAnalytics);
    });
  }

  /**
   * Execute Google analytics requests
   *
   * @param data
   */
  executeGoogleAnalytics(data) {
    // Send data to parent in case of Iframe is used
    window.top.postMessage({googleAnalyticsDoctena: data}, '*');
    data.forEach(param => {
      // Execute Google Analytics
      ga.apply(null, param);
    });
  }

  /**
   * Load google analytics script and set the trackers
   */
  loadGoogleAnalytics() {
    // Set widget user's Google Analytics tracking ID
    if (gaTrackingId) {
      this.trackers.push({
        name: 'client',
        trackingId: gaTrackingId
      });
    }

    const currentDate: any = new Date();

    // Google analytics
    (function (i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r;
      i[r] = i[r] || function () {
        (i[r].q = i[r].q || []).push(arguments);
      }, i[r].l = 1 * currentDate;
      a = s.createElement(o), m = s.getElementsByTagName(o)[0];
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
  }

  /**
   * Load google tag manager script with the widget user's tracking Id
   */
  loadGoogleTagManager() {
    // Send trackerId to main site
    window.top.postMessage({googleTagManagerDoctenaId: gtmTrackingId}, '*');

    // Google tag manager
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    // @ts-ignore
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayerDoctena',gtmTrackingId)
    this.googleTagManagerOn = true;

    // Overwrite function push for Array to send data to main site
    dataLayerDoctena.push = function() {
      Array.prototype.push.apply(this, arguments);
      const data = arguments[0];

      // Convert function into string to pass it to postMessage method
      if (data.eventCallback) {
        data.eventCallback = data.eventCallback.toString();
      }

      // Object send to postMessage
      const obj = {
        googleTagManagerDoctena: {
          ...data,
          ...{'gtm.element': data['gtm.element'] ? data['gtm.element'].outerHTML : 'undefined'} // Put html element to string to send it to main site
        }
      };

      // Send data to parent in case of Iframe is used
      window.top.postMessage(obj, '*');
    };
  }

  private loadMatomoTracker() {
    const matomoScript = document.createElement('script');
    matomoScript.text = `
 var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://cdn.matomo.cloud/doctena.matomo.cloud/container_BCtUi0xW.js'; s.parentNode.insertBefore(g,s);
`;
    document.getElementsByTagName('head')[0].appendChild(matomoScript);
  }
}
