export class Slot {
  id: number;
  bookingExternalSlotEid: string;
  bookingUrl: string;
  start: any;
  end: any;
  status: Slot.StatusEnum;
}

export namespace Slot {
  export enum StatusEnum {
    Available = 1,
    Locked = 2,
    Gateway = 3,
    Cancel = 4,
    Archived = 5,
    Walkin = 6,
    DoxterTerminland = 12,
    Timeframe = 16,
    HomeVisit = 32
  }
}
