<div *ngIf="false">
  <input type="hidden" i18n-placeholder="@@__cookie_info_explanation"
         placeholder="{{ '__cookie_info_explanation' | translate}}">
  <input type="hidden" i18n-placeholder="@@__cookie_cg_accept"
         placeholder="{{ '__cookie_cg_accept' | translate}}">
</div>
<div
  *ngIf="showCookieInfo"
  [ngStyle]="{'background-color' : extendedColorBg}"
  [ngClass]="{
    'cookie__container' : true,
    'cookie--hidden' : hideCookie
  }">
  <div class="container text-center">
    <span
      class="cookie__desc"
      i18n="@@__cookie_info_explanation"
      [innerHTML]="'__cookie_info_explanation' | translate | sprintf: policyUrl">
    </span>
    <button
      class="btn btn-outline-info btn-sm cookie__button"
      (click)="hideCookieInfo()" i18n="@@__cookie_cg_accept">
      {{'__cookie_cg_accept' | translate}}
    </button>
  </div>
</div>
