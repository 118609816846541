import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CustomField, CustomFieldGroup} from '../models/customFields';
import { UntypedFormGroup } from '@angular/forms';
import {NgForOf, NgIf} from '@angular/common';
import {FieldComponent} from './field.component';


@Component({
  selector: 'app-fieldset',
  templateUrl: './fieldset.component.html',
  imports: [
    NgIf,
    NgForOf,
    FieldComponent
  ],
  standalone: true
})
export class FieldsetComponent {
  @Input() fields: (CustomField|CustomFieldGroup)[];
  @Input() parentForm: UntypedFormGroup;
  @Input() isPristine: boolean;
  @Output() keyDownEvent = new EventEmitter<any>();

  onKeyDown(e) {
    this.keyDownEvent.emit({e, inputValue: e.inputValue});
  }
}
