import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Auth} from '../../models/auth';

export let appToken: string;
@Injectable()
export class AuthenticationService {

  user;

  constructor(private http: HttpClient) {
    this.user = null;
  }

  authApp() {
    const apiUrl = environment.apiUrl;
    const bodyAppAuth = {
      grant_type: 'client_credentials',
      client_id: environment.clientId,
      client_secret: environment.clientSecret
    };

    return this.http.post(apiUrl + '/oauth', bodyAppAuth, {reportProgress: true}).pipe(
      map((auth: Auth) => {
        // login successful if there's a jwt token in the response
        const hasToken = auth && auth.access_token;
        if (hasToken) {
          // Set token property
          appToken = auth.access_token;

          // Return true to indicate successful login
          return true;
        }
        // return false to indicate failed login
        return false;
      }),
      catchError(this.handleError<any>('login'))
    );
  }

  login(username: string, password: string) {
    const apiUrl = environment.apiUrl;
    const bodyLogin = {
      grant_type: 'password',
      client_id: environment.patientClientId,
      username,
      password
    };

    return this.http.post(apiUrl + '/oauth', bodyLogin).pipe(
      map((auth: Auth) => {
        // login successful if there's a jwt token in the response
        const hasToken = auth && auth.access_token;
        if (hasToken) {
          // Set token property
          appToken = auth.access_token;

          // Return true to indicate successful login
          return true;
        }
        // return false to indicate failed login
        return false;
      }),
      catchError(this.handleError<any>('login'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
