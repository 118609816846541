import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Alert } from '../models/alert';
import { AlertService } from '../service/alert/alert.service';
import {NgClass, NgIf} from '@angular/common';
import {TranslatePipe} from '../pipe/translate/translate.pipe';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    TranslatePipe,
    NgIf
  ],
  encapsulation: ViewEncapsulation.None
})
export class AlertComponent implements OnInit {

  alert: Alert;
  constructor(public alertService: AlertService) { }

  getAlert(): void {
    this.alertService.getAlert().subscribe(alert => this.alert = alert);
  }

  ngOnInit() {
    this.getAlert();
  }

}
