import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  gtmTrackingUrl: SafeResourceUrl;
  hasFullWidth: boolean;

  constructor(public sanitizer: DomSanitizer,
              private matIconRegistry: MatIconRegistry) {
    this.hasFullWidth = !!parseInt(this.getQueryParameterByName('fullWidth'), 10);
    this.matIconRegistry.addSvgIcon('upload', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/upload.svg'));
  }

  ngOnInit() {
    if (this.getQueryParameterByName('gtmTrackingId')) {
      this.gtmTrackingUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.googletagmanager.com/ns.html?id=${this.getQueryParameterByName('gtmTrackingId')}`);
    }
  }

  /**
   * Get the value of a query parameter in the URL
   *
   * @param name  string  Name of the parameter to fetch
   * @param url  string  URL to look for it
   */
  getQueryParameterByName(name: string, url?: string) {
    if (!url) {
      url = window.location.href;
    }

    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);

    if (!results) {
      return null;
    }

    if (!results[2]) {
      return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
