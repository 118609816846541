<div *ngIf="false">
  <input type="hidden" i18n-placeholder="@@__notification_other_doctor"
         placeholder="{{ '__notification_other_doctor' | translate}}">
  <input type="hidden" i18n-placeholder="@@__notification_online_payment"
         placeholder="{{ '__notification_online_payment' | translate}}">
</div>
<div class="booking-modal">
  <div class="booking-modal__content" mat-dialog-content>
    <span>
      {{ data.text | translate }}
    </span>
  </div>
  <div class="booking-modal__buttons" mat-dialog-actions>

    <button
      *ngIf="data.continue"
      class="booking-modal__accept-btn booking-modal__btn btn btn-success btn-sm"
      [mat-dialog-close]="true"
      (click)="data.continue()"
      i18n="@@__continue">
      {{ '__continue' | translate }}
    </button>

    <button
      *ngIf="data.backButton"
      class="booking-modal__back-btn booking-modal__btn btn btn-light"
      (click)="onClose()"
      i18n="@@__label_back">
      {{ '__label_back' | translate }}
    </button>
  </div>
</div>
