import {commonEnv} from './environment.common';
import {merge} from 'lodash';

const env: Partial<typeof commonEnv> = {
  environmentName: 'testing',
  apiUrl: 'https://api.testing.doctena.lu',
  gatewayUrl: null
};

// Export all settings of common replaced by dev options
export const environment = merge(commonEnv, env);
