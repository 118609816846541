import {Injectable} from '@angular/core';

@Injectable()
export class HelpersService {

  // Returns true if the value conforms to the format of a GUID
  isGUID(val: string) {
    const guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return guidPattern.test(val);
  }

}
