export class BingliTrait {

  completeBingliSurvey = false;
  reasonOfVisitDoctenaWithTriageKey = [];
  preselectBingliRov = null;
  isBingliRovSelected = false;

  /**
   * Bingli survey is complete
   * @param state
   */
  isCompleteSurvey(state: boolean) {
    this.completeBingliSurvey = state;
  }

  /**
   * store reasons of visit from doctena with triage key
   * @param reasonOfVisits
   */
  setReasonOfVisitDoctenaWithTriageKey(reasonOfVisits) {
    // If no reason of visit Doctena with TriageKey property
    if (!reasonOfVisits.length) {
      this.completeBingliSurvey = true;
    }
    this.reasonOfVisitDoctenaWithTriageKey = reasonOfVisits;
  }

  /**
   * Extract reason of visit from bingli
   * @param reasonOfVisit
   */
  extractReasonOfVisitBingli(reasonOfVisit) {
    if (this.reasonOfVisitDoctenaWithTriageKey.length && reasonOfVisit.reasonOfVisit) {
      this.preselectBingliRov = this.reasonOfVisitDoctenaWithTriageKey.find(element => reasonOfVisit.reasonOfVisit.includes(element.triageKey));
    }
  }

  /**
   * Set isBingliRovSelected
   * @param event
   */
  setIsBingliRovSelected(event) {
    // If patient select diagnosis ROV set the completeBingliSurvey to false
    this.completeBingliSurvey = !event;
    this.isBingliRovSelected = event;
  }
}
