<app-alert></app-alert>
<app-loader></app-loader>

<div class="booking" *ngIf="calendarService.selectedSlot && calendarService.selectedAgenda">
  <mat-stepper (click)="waitBeforeNewStep()" (selectionChange)="changeStep($event)" [linear]="true" #stepper>
    <mat-step *ngFor="let step of steppers" [stepControl]="step.inputs.formGroup" [optional]="step.isOptional">
      <ng-template matStepLabel>
        {{ step.label | translate }}
      </ng-template>

      <ng-template matStepContent>
        <ng-container *ngComponentOutlet="
        step.component;
        inputs: step.inputs;
      "/>
      </ng-template>
    </mat-step>
  </mat-stepper>
</div>
