import {Doctor} from './doctor';
import {Speciality} from './speciality';
import {Practice} from './practice';

export class Agenda {
  id: number;
  color: string;
  createdDate: Date;
  doctor: Doctor;
  externalId: string;
  mainSpeciality: Speciality;
  practice: Practice;
  subscribed_lma: boolean;
  status: Agenda.StatusEnum;
  teleconsultation: boolean;
  updatedDate: Date;
  webBookingText: Array<Remark>;
  features: Object;
}

export namespace Agenda {
  export enum StatusEnum {
    Offline = <any>'0',
    Online = <any>'1',
    Private = <any>'2',
    SemiPrivate = <any>'4',
    Draft = <any>'8'
  }

  export const displayableStatuses = [
    StatusEnum.Online,
    StatusEnum.SemiPrivate,
  ];
}

export class Remark {
  practice_eid: string;
  messages: any;
}
