import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/nl';
import 'moment/locale/pt';
import 'moment/locale/it';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {Settings} from 'luxon';

export let extendedColorBtn: string;
export let extendedColorBg: string;
export let extendedColorDay: string;
export let gaTrackingId: string;
export let gtmTrackingId: string;
export let ignoreDoctors: string;
export let preSelectedReason: string;
export let preSelectedSpeciality: string;
export let preSelectedDoctor: string;
export let preSelectedPractice: string;
export let hidePatientRelation: boolean;
export let disableAnalyticsCookies: boolean;
export let hideDoctenaAccount: boolean; // deprecated but still working
export let bookingLoginDoctenaAccount: boolean;
export let hideCookieBanner: boolean;
export let homeLoginDoctenaAccount: boolean;
export let userData: string;
export let hidePowerBy: boolean;
export let gatewayName: string;
export let hideColleaguesAvailabilities: boolean;
export let rovOrdering: string;

// Group
export let hideSpecialityFilter: boolean;
export let hidePractitionerFilter: boolean;
export let practiceOrdering: string;
export let customPracticeLabel: string;
export let showMessageNotificationWebNoSlot: boolean;

@Component({
  selector: 'app-routing',
  templateUrl: './routing.component.html',
  styleUrls: ['./routing.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}
  ]
})
export class RoutingComponent {

  selectedLanguage: string;
  gtmTrackingId: string;

  availableLanguages = [
    'en',
    'fr',
    'de',
    'nl',
    'pt',
    'it'
  ];

  constructor(public translate: TranslateService,
              public adapter: DateAdapter<any>,
              private route: ActivatedRoute) {
    extendedColorBtn = '#' + this.route.snapshot.queryParamMap.get('color1');
    extendedColorDay = '#' + this.route.snapshot.queryParamMap.get('color2');
    extendedColorBg = '#' + this.route.snapshot.queryParamMap.get('color3');
    gaTrackingId = this.route.snapshot.queryParamMap.get('gaTrackingId');
    gtmTrackingId = this.route.snapshot.queryParamMap.get('gtmTrackingId');
    ignoreDoctors = this.route.snapshot.queryParamMap.get('ignoreDoctors');
    preSelectedReason = this.route.snapshot.queryParamMap.get('preSelectedReason');
    preSelectedSpeciality = this.route.snapshot.queryParamMap.get('preSelectedSpeciality');
    preSelectedDoctor = this.route.snapshot.queryParamMap.get('preSelectedDoctor');
    preSelectedPractice = this.route.snapshot.queryParamMap.get('preSelectedPractice');
    userData = this.route.snapshot.queryParamMap.get('user');
    disableAnalyticsCookies = !(gaTrackingId || gtmTrackingId);
    hidePowerBy = false; // Disable this param until we get the authorization (DK-19)
    // hidePowerBy = this.route.snapshot.queryParamMap.get('hidePowerBy') === '1';
    hideDoctenaAccount = this.route.snapshot.queryParamMap.get('hideDoctenaAccount') === '1'; // deprecated but still working
    bookingLoginDoctenaAccount = !(this.route.snapshot.queryParamMap.get('bookingLoginDoctenaAccount') === '0');
    hideCookieBanner = this.route.snapshot.queryParamMap.get('hideCookieBanner') === '1';
    homeLoginDoctenaAccount = this.route.snapshot.queryParamMap.get('homeLoginDoctenaAccount') === '1';
    hidePatientRelation = this.route.snapshot.queryParamMap.get('hidePatientRelation') === '1';
    hideColleaguesAvailabilities = this.route.snapshot.queryParamMap.get('hideColleaguesAvailabilities') === '1';
    gatewayName = this.route.snapshot.queryParamMap.get('gatewayName');
    rovOrdering = this.route.snapshot.queryParamMap.get('rovOrdering');

    // Group
    hideSpecialityFilter = this.route.snapshot.queryParamMap.get('hideSpecialityFilter') === '1';
    hidePractitionerFilter = this.route.snapshot.queryParamMap.get('hidePractitionerFilter') === '1';
    showMessageNotificationWebNoSlot = this.route.snapshot.queryParamMap.get('showMessageNotificationWebNoSlot') === '1';
    practiceOrdering = this.route.snapshot.queryParamMap.get('practiceOrdering');
    customPracticeLabel = this.route.snapshot.queryParamMap.get('customPracticeLabel');

    // Keep legacy compatibility
    if (hideDoctenaAccount) {
      bookingLoginDoctenaAccount = !hideDoctenaAccount;
    }

    this.selectedLanguage = this.route.snapshot.paramMap.get('language');

    if (this.selectedLanguage === null) {
      this.selectedLanguage = navigator.language;
    }

    // If language is not in the list
    if (this.availableLanguages.indexOf(this.selectedLanguage) === -1) {
      this.selectedLanguage = 'en';
    }

    if (this.selectedLanguage !== null) {
      this.translate.use(this.selectedLanguage);
      this.translate.currentLang = this.selectedLanguage;

      // get local for moment lib
      if (this.selectedLanguage === 'en') {
        Settings.defaultLocale = 'en-gb';
        this.adapter.setLocale('en');
      } else {
        Settings.defaultLocale = this.selectedLanguage;
        this.adapter.setLocale(this.selectedLanguage);
      }
    }

    Settings.defaultZone = 'Europe/Luxembourg';
  }
}
