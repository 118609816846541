
<!-- if CustomFieldGroup, iterate on elements -->
<span *ngFor="let field of fields; let isOdd = odd">

  <div *ngIf="field.group_label">
    <label class="col-12">
      <strong>{{ field.group_label }}</strong>
    </label>

    <app-field
      *ngFor="let el of field.elements; let isOddGroupEl = odd"
      [hasParentGroup]="true"
      [field]="el"
      [parentForm]="parentForm"
      [isPristine]="isPristine"
      (keyDownEvent)="onKeyDown($event)"
      [isOdd] = isOddGroupEl
    >
    </app-field>
  </div>

  <app-field
    *ngIf="!field.group_label"
    [field]="field"
    [parentForm]="parentForm"
    [isPristine]="isPristine"
    (keyDownEvent)="onKeyDown($event)"
    [isOdd] = isOdd
  >
  </app-field>
</span>
