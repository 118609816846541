import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpService} from '../http/http.service';
import {Appointment} from '../../models/appointment';
import BlueBirdPromise from 'bluebird';

@Injectable()
export class AppointmentService extends HttpService {

  constructor(http: HttpClient) {
    super(http);
  }

  /**
   * Call to validate a SSN number
   *
   * @param ssn
   * @param ssnIso
   */
  validateSsn(ssn: string, ssnIso: string) {
    return new BlueBirdPromise((resolve) => {
      this.get(this.apiUrl + '/validateSsn', new HttpParams({fromObject: {ssn, ssnIso}}))
        .subscribe({
          next: () => {
            resolve(true);
          },
          error: () => {
            resolve(false);
          }
        });
    });
  }

  /**
   * Call to create an appointment
   *
   * @param appointment
   * @param doctorId
   * @param callbackSuccess
   * @param callbackError
   */
  newAppointment(appointment, doctorId, callbackSuccess?, callbackError?) {

    this.post(this.apiUrl + '/doctors/' + doctorId + '/appointments', appointment)
      .subscribe(data => {
        if (callbackSuccess) {
          callbackSuccess(this.stripUselessData(data));
        }
      }, error => {
        if (callbackError) {
          callbackError(error);
        }
      });
  }

  /**
   * Call to confirm an appointment with the sms code
   *
   * @param {Appointment} appointment
   * @param body
   * @param callback
   * @param callbackError
   */
  confirmAppointment(appointment: Appointment, body, callback?, callbackError?) {
    this.patch(this.apiUrl + '/doctors/' + appointment.doctor_id + '/appointments/' + appointment.id, body)
      .subscribe(data => {
        if (callback) {
          callback(this.stripUselessData(data));
        }
      }, error => {
        if (callbackError) {
          callbackError(error);
        }
      });
  }

  /**
   * Call to get an appointment by eid
   *
   * @param eid
   * @param callback
   * @param params
   */
  getAppointment(eid, callback, params?: HttpParams) {
    if (typeof params === 'undefined') {
      params = new HttpParams();
    }

    this.get(this.apiUrl + '/doctors/appointments/' + eid, params)
      .subscribe(data => {
        callback(this.stripUselessData(data));
      });
  }
}
