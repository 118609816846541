import {Injectable} from '@angular/core';

@Injectable()
export class LoaderService {

  protected visibility: boolean;
  protected loadingCounter: number;

  constructor() {
    this.visibility = false;
    this.loadingCounter = 0;
  }

  newLoading() {
    this.loadingCounter++;
    if (this.loadingCounter === 1) {
      this.showLoader();
    }
  }

  endLoading() {
    if (this.loadingCounter > 0) {
      this.loadingCounter--;
      if (this.loadingCounter === 0) {
        this.hideLoader();
      }
    }
  }

  showLoader() {
    this.visibility = true;
  }

  hideLoader() {
    this.visibility = false;
  }

  isShowed(): boolean {
    return this.visibility;
  }

}
