import {ChangeDetectorRef, Pipe} from '@angular/core';
import {TranslatePipe as NgTranslate, TranslateService} from '@ngx-translate/core';
import {TrackingService} from '../../service/tracking/tracking.service';

@Pipe({
  standalone: true,
  name: 'translate',
  pure: false
})
export class TranslatePipe extends NgTranslate {

  constructor(translate: TranslateService, ref: ChangeDetectorRef, public trackingService: TrackingService) {
    super(translate, ref);
  }

  transform(query: string, ...args: any[]): any {
    // If no selected agenda we do the default behaviour
    if (!this.trackingService.selectedAgenda) {
      return super.transform(query, ...args);
    }

    const countryKey = query + `_${this.trackingService.selectedAgenda.practice.country_iso}`,
      countryTranslation = super.transform(countryKey, ...args);

    // Check if translation for a specific country exist
    if (countryKey === countryTranslation) {
      // Default process
      return super.transform(query, ...args);
    }

    return countryTranslation;
  }

}
