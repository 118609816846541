import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';

import {LoaderService} from '../loader/loader.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private loaderService: LoaderService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('./') !== -1) {
      return next.handle(req);
    }

    this.loaderService.newLoading();
    return next.handle(req).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.loaderService.endLoading();
          }
        },
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) {
            this.loaderService.endLoading();
          }
        }
      }));
  }

}
