import {Doctor} from './doctor';
import {DateTime} from 'luxon';

export class Patient {
  avatar: string;
  birthday: Object;
  city: string;
  createdAt: Object;
  doctors: Array<Doctor>;
  email: string;
  externalId: string;
  firstName: string;
  gender: string;
  hasAcceptedDateTerms: boolean;
  houseNumber: number;
  id: number;
  insuranceAccess: number;
  lastLoginAt: Object;
  lastName: string;
  loginAttempts: number;
  mailValidated: boolean;
  matricule: number;
  mobile: string;
  mobileAppNotification: boolean;
  mobileValidated: boolean;
  name: string;
  notificationAppAppointmentReminder: boolean;
  notificationAppHealthReminder: boolean;
  notificationAppInformational: boolean;
  notificationAppLma: boolean;
  notificationEmailHealthReminder: boolean;
  notificationSmsMessage: boolean;
  postBoxNumber: number;
  role: string;
  showMyProfile: boolean;
  status: number;
  street: string;
  updatedPwdAt: Object;
  userName: string;
  zipCode: number;
  address: any;
}

export namespace Patient {
  /**
   * Adapt patient data to bingli
   * @param data
   */
  export const patientDataToBingliAdaptor = (data) => {
    const patientInformation = {};
    if (!data.patient) {
      return patientInformation;
    }
    patientInformation['email'] = data.patient.email;
    patientInformation['firstName'] = data.patient.firstName;
    patientInformation['lastName'] = data.patient.lastName;
    if (data.patient.gender) {
      patientInformation['gender'] = (data.patient.gender === 'male') ? 'm' : 'f';
    }
    if (data.patient.birthday) {
      patientInformation['birthday'] = DateTime.fromFormat(data.patient.birthday['date'], 'yyyy-LL-dd TT').toFormat('yyyy-LL-dd');
    }
    if (data.patient.mobile) {
      patientInformation['phoneNumber'] = data.patient.mobile;
    }
    return patientInformation;
  };

  /**
   * Adapt patient data to doctena
   * @param data
   */
  export const patientDataToDoctenaAdaptor = (data) => {
    return {
      patientGender: data.Gender === 'm' ? 'male' : 'female',
      patientFirstName: data.FirstName ?? '',
      patientLastName: data.LastName ?? '',
      patientBirthday: DateTime.fromISO(data.birthday) ?? '',
      userEmail: data.email ?? '',
      userMobile: data.PhoneNumber ?? '',
    };
  };
}

