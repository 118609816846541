import {Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter} from '@angular/core';
import {Validators, UntypedFormGroup, UntypedFormControl, FormBuilder, ReactiveFormsModule} from '@angular/forms';
import {Alert} from '../models/alert';
import {DateTime} from 'luxon';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from '../service/alert/alert.service';
import {AuthenticationService} from '../service/authentication/authentication.service';
import {PatientService} from '../service/patient/patient.service';
import {environment} from '../../environments/environment';
import {BookingService} from '../service/booking/booking.service';
import {TranslatePipe} from '../pipe/translate/translate.pipe';
import {NgIf} from '@angular/common';
import {MatFormField} from '@angular/material/form-field';
import {MatButton} from '@angular/material/button';
import {MatInput} from '@angular/material/input';

@Component({
  selector: 'app-patient-login',
  templateUrl: './patient-login.component.html',
  styleUrls: ['./patient-login.component.scss'],
  imports: [
    TranslatePipe,
    ReactiveFormsModule,
    NgIf,
    MatFormField,
    MatButton,
    MatInput
  ],
  standalone: true
})

export class PatientLoginComponent implements OnInit, OnChanges {

  /**
   * consultationGroup
   * @type {UntypedFormGroup}
   */
  @Input() consultationGroup: UntypedFormGroup;

  /**
   * Show patient login form
   * @type {boolean}
   */
  @Input() showPatientLoginForm = false;
  /**
   * Country ISO: example: lu | be | de | at | ...
   */
  @Input() countryIso: string;

  @Output() isLogged = new EventEmitter<boolean>;


  constructor(private _formBuilder: FormBuilder,
              public translateService: TranslateService,
              public alertService: AlertService,
              public authenticationService: AuthenticationService,
              public patientService: PatientService,
              public bookingService: BookingService) {
  }

  ngOnInit(): void {
    {
      if (this.patientService.patient) {
        this.isLogged.emit(true);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showPatientLoginForm) {
      this.isShowPatientForm();
    }
  }

  /**
   * Check if we need to show the patient form or not
   */
  isShowPatientForm() {
    if (this.consultationGroup.value.isDoctenaUser) {
      this.consultationGroup.addControl('login', new UntypedFormControl(''));
      this.consultationGroup.addControl('password', new UntypedFormControl(''));
      return;
    }

    this.consultationGroup.removeControl('login');
    this.consultationGroup.removeControl('password');
  }

  /**
   * Send a request to validate user login and get his info to fill out the form
   *
   * @param login
   * @param password
   */
  login(login, password) {
    // We check first if the email has the correct format
    const validateEmail = Validators.email(this.consultationGroup.controls.login),
      validatePassword = Validators.required(this.consultationGroup.controls.password),
      validatedLogin = !validateEmail && !validatePassword;

    // We check if email and password are valids
    if (validatedLogin) {
      // Send request for step 1 of login -> get a token
      this.authenticationService.login(login, password)
        .subscribe(loginResult => {
          // Login failed
          if (loginResult !== true) {
            const alert = new Alert();
            alert.type = 'error';
            alert.text = '__login_credentials_error';
            this.alertService.setAlert(alert);

            return;
          }

          // Step 2 to login, call to get request 'Me'
          this.patientService.me()
            .subscribe(meResult => {
              if (!meResult) {
                // Me failed
                const alert = new Alert();
                alert.type = 'error';
                alert.text = '__me_request_error';
                this.alertService.setAlert(alert);

                return;
              }

              this.patientService.patient = meResult;

              // Formatting the data for the form and appointment request
              this.bookingService.setPatientData(Object.assign(meResult, {
                patientBirthday: meResult.birthday ? DateTime.fromFormat(meResult.birthday.date, 'yyyy-LL-dd TT') : null,
                userEmail: meResult.email,
                patientFirstName: meResult.firstName,
                patientLastName: meResult.lastName,
                patientGender: meResult.gender,
                patientAddress: {
                  'street': meResult.street || '',
                  'houseNumber': meResult.houseNumber || '',
                  'city': ((meResult._embedded || {}).city || {}).name || '',
                  'zipCode': meResult.zipCode || '',
                },
                userMobile: meResult.mobile,
                createPatientAccount: null,
                notifyLma: null
              }));
              this.showPatientLoginForm = false;
              this.isLogged.emit(true);
            });
        });
    }
  }

  /**
   * Empty the patient data from patientService and get a new token
   *
   */
  logout() {
    this.isLogged.emit(false);
    this.patientService.patient = null;
    this.bookingService.setPatientData(null);
    // this.fillFormWithData(null); // We empty the form
    // Get bearer for the app
    this.authenticationService.authApp().subscribe(result => {
      if (result !== true) {
        // Login failed
        const alert = new Alert();
        alert.type = 'error';
        alert.text = '__title_error_occurred';
        this.alertService.setAlert(alert);
      }
    });
  }

  /**
   * Redirect to the page to change the password
   */
  urlChangePwd() {
    let env = environment.environmentName;
    if ((env.length === 0) && (this.countryIso === 'at')) {
      env = 'patient';
    }
    env = (env.length > 0) ? '.' + env : '';
    return `http://www${env}.doctena.${this.countryIso}/${this.translateService.currentLang}/patient/change-password`;
  }
}

