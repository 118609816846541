import { Injectable } from '@angular/core';
import {HttpService} from '../http/http.service';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService extends HttpService {

  /**
   * Service's constructor
   *
   * @param http
   */
  constructor(http: HttpClient) {
    super(http);
  }

  /**
   * Get specialities alias name for some gateways
   *
   * @param gatewayName
   * @param callbackSuccess
   * @param callbackError
   */
  getGatewaySpecialitiesAliasName(gatewayName: string, callbackSuccess, callbackError) {
    if (environment.gatewayUrl) {
      this.get(`https://${gatewayName}${environment.gatewayUrl}/api/v1/specialities`)
        .subscribe({
          next: (data) => {
            if (callbackSuccess) {
              callbackSuccess(this.stripUselessData(data));
            }
          }, error: (error) => {
            if (callbackError) {
              callbackError(error);
            }
          }
        });
    }
  }

  /**
   * Get custom labels for some gateways
   *
   * @param gatewayName
   * @param callbackSuccess
   * @param callbackError
   */
  getGatewayCustomLabels(gatewayName: string, callbackSuccess, callbackError) {
    if (environment.gatewayUrl) {
      this.get(`https://${gatewayName}${environment.gatewayUrl}/api/v1/labels`)
        .subscribe({
          next: (data) => {
            if (callbackSuccess) {
              callbackSuccess(this.stripUselessData(data));
            }
          }, error: (error) => {
            if (callbackError) {
              callbackError(error);
            }
          }
        });
    }
  }

  /**
   * Send token to server to decrypt user data
   *
   * @param token
   * @param callbackSuccess
   * @param callbackError
   */
  decryptUserData(token, callbackSuccess, callbackError) {
    this.post(this.apiUrl + '/decrypt/patient-data-ibis', {token: token})
      .subscribe({
        next: (data) => {
          if (callbackSuccess) {
            callbackSuccess(this.stripUselessData(data));
          }
        }, error: (error) => {
          if (callbackError) {
            callbackError(error);
          }
        }
      });
  }
  /**
   * If an image comes from Cloudinary services, we can pass params to it
   * to adapt it to our needs.
   * See https://cloudinary.com/documentation/image_transformations
   */
  getCloudinaryImageWithParams(url?: string | null, params?: string) {
    if (!url || !params || !url.includes(`res.cloudinary.com`)) {
      return url;
    }
    const splitUrl = url.split(`/`);
    const startIndex = splitUrl.findIndex((part) => part === `upload`);
    if (startIndex === -1) {
      return url;
    }
    const newSplitUrl = [...splitUrl.slice(0, startIndex + 1), params, ...splitUrl.slice(startIndex + 1)];
    return (newSplitUrl).join(`/`);
  }

}
