<div class="weekSelector col-12 position-relative">
  <a class="arrowIcons position-absolute"
    (click)="previous()"
    *ngIf='isPreviousVisible()'>
    <mat-icon>keyboard_arrow_left</mat-icon>
  </a>

  <mat-list *ngIf='isWeekListVisible()' role="list" class="weekDaysList">
    <mat-list-item
      role="listitem"
      *ngFor='let day of weekDays'>
      <div>{{day.toFormat('ccc') | capitalize}}</div>
      <small>{{getDateFormat(day)}}</small>
    </mat-list-item>
  </mat-list>

  <a
    class="arrowIcons position-absolute"
    (click)="next()">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </a>
</div>
