import {Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, OnChanges, ElementRef} from '@angular/core';
import {FormBuilder, UntypedFormGroup, Validators, UntypedFormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatStepper} from '@angular/material/stepper';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {BookingService} from '../service/booking/booking.service';
import {CalendarService} from '../service/calendar/calendar.service';
import {AlertService} from '../service/alert/alert.service';
import {AuthenticationService} from '../service/authentication/authentication.service';
import {PatientService} from '../service/patient/patient.service';
import {ActivatedRoute, Router} from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import {Location} from '@angular/common';
import {Alert} from '../models/alert';
import {Agenda} from '../models/agenda';
import {Appointment, QuestionAnswer} from '../models/appointment';
import {Doctor} from '../models/doctor';
import {Slot} from '../models/slot';
import {CustomFieldsResponse, CustomField, CustomFieldGroup} from '../models/customFields';
import {environment} from '../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {bookingLoginDoctenaAccount, hidePatientRelation, userData} from '../routing/routing.component';
import moment from 'moment-timezone';
import {find, forEach, filter, isEmpty, debounce} from 'lodash';
import 'intl-tel-input';
import {AppointmentService} from '../service/appointment/appointment.service';
import {TrackingService} from '../service/tracking/tracking.service';
import {UtilitiesService} from '../service/utilities/utilities.service';
import {BingliService} from '../service/bingli/bingli.service';

declare let $: any;
declare let ga: Function;
declare let intlTelInputUtils: any;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class BookingComponent implements OnInit, AfterViewInit, OnChanges, ElementRef {

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private _location: Location,
              public calendarService: CalendarService,
              public bookingService: BookingService,
              public appointmentService: AppointmentService,
              public translateService: TranslateService,
              public alertService: AlertService,
              public utilitiesService: UtilitiesService,
              public authenticationService: AuthenticationService,
              public patientService: PatientService,
              public trackingService: TrackingService,
              private ref: ChangeDetectorRef,
              public dialog: MatDialog,
              public bingliService: BingliService,
              private elementRef: ElementRef) {
    this.isLinear = true;
    this.isCompleted = false;
    this.isHomeVisitSlot = false;
    this.hasPrefilledUserData = false;
    this.currentStep = 0;
    this.loaderOn = false;
    this.firstStepPristine = true;
    this.secondStepPristine = true;
    this.showPatientReturningInput = true;
    this.resendSms = false;
    this.selectedReason = '';
    this.appointmentAlreadyAccepted = false;
    this.datePickerDelimeter = moment().format('L')[2];
    this.requiredFields = {};
    this.locationFields = ['houseNumber', 'city', 'street', 'zipCode'];
    this.countryIso = this.calendarService.selectedAgenda.practice.country_iso;
    this.isShowCoreProcessing = false;
    this.isReschedule = false;
    this.majorityAgeMin = environment.underageDefinition[this.countryIso];
    this.customFields = {};
    this.showBingliForm = false;

    // Method to try and limit the input listener on matricule
    this.handleMatriculeChange = debounce((e) => {
      const matricule = this.personnalGroup.controls.matricule;
      const otherErrors = matricule.hasError('pattern') || matricule.hasError('minlength');
      if (!otherErrors) {
        this.validateSsn(e);
      }
    }, 1000);
  }

  @ViewChild('phoneSelect', {static: false}) phoneSelect;
  @ViewChild('stepper', {static: false}) stepper: MatStepper;
  isLinear: boolean;
  isCompleted: boolean;
  isHomeVisitSlot: boolean;
  currentStep: number;
  loaderOn: boolean;
  firstStepPristine: boolean;
  secondStepPristine: boolean;
  showPatientReturningInput: boolean;
  consultationGroup: UntypedFormGroup;
  personnalGroup: UntypedFormGroup;
  confirmationGroup: UntypedFormGroup;
  selectedReason;
  currentAppointment: Appointment;
  datePickerDelimeter: string;
  appointmentAlreadyAccepted: boolean;
  majorityAgeMin: number;
  termsOfUseUrl;
  policyUrl;
  gdprUrl;
  teleconsultationInfoUrl: string;
  termsLinksTags: Array<string>;
  resendSms: boolean;
  patientNoteEnable: boolean;
  requiredFields: any;
  locationFields: Array<string>;
  countryIso: string;
  isShowCoreProcessing: boolean;
  isReschedule: boolean;
  bookingLoginDoctenaAccount = bookingLoginDoctenaAccount;
  handleMatriculeChange: any;
  systemLanguageId: number;
  customFields: CustomFieldsResponse;
  userData = userData;
  hidePatientRelation = hidePatientRelation;
  hasPrefilledUserData: boolean;
  coreProcessingTags: Array<string>;
  showBingliForm: boolean;
  surveyId = false;

  nativeElement: any;

  protected readonly String = String;

  ngOnChanges() {
    this.ref.detectChanges();
  }

  ngAfterViewInit() {
    $('#userMobileInput').intlTelInput({
      initialCountry: this.countryIso,
      hiddenInput: 'userMobile',
      selectedLanguage: this.translateService.currentLang,
    });

    if (this.customFields.matricule || this.userData) {
      const matriculeInput = $('#matriculeInput'),
        isUltragenda = this.calendarService.selectedAgenda.doctor.ultragendaGateway;
      let initialCountry = isUltragenda ? 'be' : this.countryIso;
      if (this.isReschedule) {
        const rescheduleData = this.calendarService.appointmentReschedule;
        initialCountry = rescheduleData.matriculeIso || initialCountry;
      }

      matriculeInput.intlTelInput({
        initialCountry,
        hiddenInput: 'matricule',
        onlyCountries: isUltragenda ? ['be'] : ['lu', 'de', 'fr', 'be', 'at', 'it', 'ch', 'nl'],
        preferredCountries: [],
        selectedLanguage: this.translateService.currentLang,
      });

      matriculeInput.on('countrychange', () => {
        const isoSsnCountry = matriculeInput.intlTelInput('getSelectedCountryData').iso2;
        this.personnalGroup.controls.matriculeIso.setValue(isoSsnCountry);
        this.handleMatriculeChange({target: {value: this.personnalGroup.value.matricule}});
      });

      // Hide dial code for matricule input
      matriculeInput.on('open:countrydropdown', () => {
        $('.dial-code').hide();
      });

      // Show them again just in case for the phone input
      matriculeInput.on('close:countrydropdown', () => {
        $('.dial-code').show();
      });
    }

    if (this.elementRef.nativeElement.querySelector('.coreProcessing')) {
      this.elementRef.nativeElement
        .querySelector('.coreProcessing')
        .addEventListener('click', this.toggleCoreProcessing.bind(this));
    }

    this.ref.detectChanges();
  }

  ngOnInit() {
    this.isReschedule = (typeof this.calendarService.appointmentReschedule !== 'undefined');

    const languageSelected = this.translateService.currentLang,
      hasChoosingApt = this.calendarService.selectedSlot || this.calendarService.selectedAgenda;

    // if (!hasChoosingApt) {
    //   console.error('You cannot access booking without choosing an appointment first.');
    //   // Go to "Page not found"
    //   this.router.navigate([''], {relativeTo: this.route, skipLocationChange: true});
    //   return;
    // }

    this.bookingService.getSystemLanguages(
      response => this.systemLanguagesSuccessCallback(response),
      responseError => this.systemLanguagesErrorCallback()
    );

    this.patientNoteEnable = this.calendarService.selectedAgenda.doctor.portalVersion !== Doctor.PortalVersionEnum.Doxter;

    this.calendarService.bookingParams['reasonOfVisit'].forEach((reason) => {
      if (this.calendarService.selectedAgenda.externalId === reason.agendaEid) {
        this.selectedReason = reason;
      }
    });

    const initReason = (this.selectedReason.name || ''),
      consultationForm = {
        isDoctenaUser: [''],
        returningPatient: ['false', Validators.required],
        reason: [initReason],
        patientNote: ['']
      } as any;

    if (this.calendarService.customMessage && this.calendarService.customMessage.hasReadCheckbox) {
      consultationForm.customMessageAgreement = [false, Validators.requiredTrue];
    }

    // TeleConsult checkbox if teleConsult is enabled
    const hasTeleConsult = this.calendarService.selectedAgenda.teleconsultation && this.selectedReason.teleconsultation;
    if (hasTeleConsult) {
      consultationForm.teleConsultationValidation = [false, Validators.requiredTrue];
    }

    // We initialize our forms with default values and validators
    this.consultationGroup = this._formBuilder.group(consultationForm);

    this.personnalGroup = this._formBuilder.group({
      patientGender: ['', Validators.required],
      patientFirstName: ['', Validators.compose([Validators.required, Validators.pattern(/^.{2,}$/)])],
      patientLastName: ['', Validators.compose([Validators.required, Validators.pattern(/^.{2,}$/)])],
      patientBirthday: ['', Validators.required],
      userEmail: ['', Validators.compose([Validators.required, Validators.email])],
      userMobile: ['', Validators.compose([Validators.required, Validators.pattern(/^\+*[0-9\s]*$/)])],
      createPatientAccount: [false],
      notifyLma: [false],
      cguAgreement: ['', Validators.requiredTrue],
      privacyPolicyAgreement: ['', Validators.requiredTrue]
    });

    this.confirmationGroup = this._formBuilder.group({
      code: ['']
    });

    this.isHomeVisitSlot = this.calendarService.selectedSlot.status === 32;

    // ****** SPECIAL CASES ********

    // Generate form fields from customFields data from CPP API
    this.initCustomFields();
    this.termsOfUseUrl = environment.termsOfUse[languageSelected];
    this.policyUrl = environment.policy[languageSelected];
    this.gdprUrl = environment.gdpr[languageSelected];
    this.teleconsultationInfoUrl = environment.teleconsultationInfo[languageSelected];
    const agenda = this.calendarService.selectedAgenda;
    // Managing features
    // if (agenda.features) {
    // Feature Bingli
    // this.manageBingliFeature(agenda.features);
    // }

    // Link tags to place in translation
    this.termsLinksTags = [
      '<a target="_blank" rel="nofollow" href="' + this.termsOfUseUrl + '">',
      '<a target="_blank" rel="nofollow" href="' + this.policyUrl + '">',
      '<a target="_blank" rel="nofollow" href="' + this.gdprUrl + '">',
      '<span>' + agenda.practice.contact + '</span>',
      '</a>'
    ];

    // Link tags to place in translation
    this.coreProcessingTags = [
      '<a class="coreProcessing" href=".">',
      '</a>'
    ];

    if (this.bookingService.patientData === null || this.bookingService.patientData === undefined) {
      this.bookingService.patientDataSubject.subscribe({
        next: (patientData) => this.fillFormWithData(patientData)
      });
    } else {
      this.fillFormWithData(this.bookingService.patientData);
    }

    // If user is rescheduling, we autofill the form with the data of the appointment
    if (this.isReschedule) {
      const rescheduleData = this.calendarService.appointmentReschedule,
        birthday = rescheduleData.patientBirthday,
        phone = rescheduleData.userMobile;

      this.fillFormWithData(Object.assign(rescheduleData, {
        patientBirthday: birthday ? moment(birthday.date) : '',
        patientNote: rescheduleData.patientNote || '',
        userMobile: phone ? phone.replace(/\s/g, '') : '',
        terms: true
      }), true);
    }

    // If a booking rules specifying if the patient is a returning patient has been filled
    // We switch the returningPatient input accordingly
    const bookingRules = this.calendarService.bookingParams['bookingRules'];
    let practiceRelation: any;
    practiceRelation = bookingRules ? find(bookingRules, {name: 'practiceRelation'}) : null;

    if (practiceRelation) {
      this.showPatientReturningInput = false;
      if (practiceRelation.selectedValue === 'EXISTING') {
        this.consultationGroup.controls.returningPatient.patchValue('true');
      }
    }

    this.trackingService.eventRequestBooking(0);
    this.trackingService.selectedAgenda = this.calendarService.selectedAgenda;

    if (this.userData) {
      this.utilitiesService.decryptUserData(this.userData, response => this.decryptUserDataSuccess(response),
        error => this.decryptUserDataError(error));
    }
  }

  /**
   * Error callback for bookingService - getSystemLanguages
   */
  private systemLanguagesErrorCallback() {
    const alert = new Alert();
    alert.type = 'error';
    alert.text = '__title_error_occurred';
    this.alertService.setAlert(alert);
  }

  /**
   * Success callback for bookingService - getSystemLanguages
   *
   * @param result
   */
  private systemLanguagesSuccessCallback(result) {
    result.languages.forEach((language, key) => {
      if (this.translateService.currentLang === language.code) {
        this.systemLanguageId = language.id;
      }
    });
  }

  /**
   * Use custom fields returned by the CPP API and integrate them in our view
   */
  private initCustomFields() {
    const customFields = this.calendarService.customFields;

    Object.keys(customFields).forEach((fieldsetKey) => {
      if (fieldsetKey === '_links') {
        return;
      }

      const fieldset = (customFields[fieldsetKey] || {}).fields || [];
      fieldset.forEach((field) => {

        // Iterate on elements contained inside a group
        const group = field as CustomFieldGroup;
        if (group.group_label) {

          // Format group name for submission to API
          const groupNameSplit = group.name.split('.');
          const groupName = groupNameSplit.length > 1 ? groupNameSplit.reduce((pre, cur, i) => {
            return pre + (i > 0 ? cur.replace(/./, cur[0].toUpperCase()) : cur);
          }) : groupNameSplit[0];
          group.name = groupName;

          group.elements.forEach((el) => {
            this.initCustomField(el, fieldsetKey, groupName);
          });
          // If not a group, send it
        } else {
          this.initCustomField(field as CustomField, fieldsetKey);
        }
      });
    });

    // Adding matriculeIso if matricule is part of the custom fields
    if (this.personnalGroup.value.matricule !== undefined) {
      const isUltragenda = this.calendarService.selectedAgenda.doctor.ultragendaGateway;
      const initialValue = isUltragenda ? 'be' : this.countryIso;
      this.personnalGroup.addControl('matriculeIso', new UntypedFormControl(initialValue, Validators.required));
    }

    this.customFields = this.calendarService.customFields;
  }

  /**
   * Init custom field by checking which validators it needs
   *
   * @param customField  CustomField  Custom Field to init
   * @param fieldsetKey  string  correspond to the place in the view the custom field should appear
   * @param groupName  string  formatted group name in case the field belongs to a group
   */
  private initCustomField(customField: CustomField, fieldsetKey: string, groupName?: string) {

    // Format the name so it can later be processed by the API when submitted
    const fieldNameSplit = customField.name.split('.');
    const fieldName = fieldNameSplit[fieldNameSplit.length - 1];
    customField.name = (groupName ? groupName + '.' : '') + fieldName;

    // Construct custom fields for form
    const validators = [];

    // Check minimum number of characters validator
    if (customField?.ui_properties.min) {
      validators.push(Validators.minLength(customField.ui_properties.min));
    }

    // Check maximum number of characters validator
    if (customField?.ui_properties.max) {
      validators.push(Validators.maxLength(customField.ui_properties.max));
    }

    // Check required validator
    if (customField?.validators.required) {
      validators.push(customField.ui_properties.type === 'checkbox' ? Validators.requiredTrue : Validators.required);
    }

    // Check pattern validator (compare input to a regex)
    if (customField?.validators.pattern) {
      validators.push(Validators.pattern(customField.validators.pattern));
    }

    // Then add a control to the associated form
    if (['matricule', 'patient', 'questions'].indexOf(fieldsetKey) !== -1) {
      this.personnalGroup.addControl(customField.name, new UntypedFormControl('', Validators.compose(validators)));
    }

    if (['appointment'].indexOf(fieldsetKey) !== -1) {
      this.consultationGroup.addControl(customField.name, new UntypedFormControl('', Validators.compose(validators)));
    }
  }

  /**
   * This function allow to fill the form with an object of data
   *
   * @param data
   * @param disableForms
   */
  fillFormWithData(data, disableForms?: boolean) {
    // We first collect every formGroups of our component
    const formGroups = filter(Object.values(this), property => {
      return property && property.controls !== 'undefined';
    });

    // In each formGroups, we set the value of each control (or input) with the
    // corresponding value of our 'data' object
    forEach(formGroups, (formGroup) => {
      forEach(formGroup.controls, (control, controlKey) => {
        const splitControlKey = controlKey.split('.'),
          splitQuestionKey = controlKey.split('question_');

        // If data are empty, we want to reset all to the initial value
        if (!data) {
          this.cleanControls(controlKey, control);
        }
        if (data.patientBirthday !== '' || data.patientBirthday !== null) {
          // force to check the birthday to know if the guardian fields should be displayed
          this.manageBirthdayDate(data.patientBirthday);
        }
        this.manageControlsValue(data, controlKey, splitControlKey, splitQuestionKey, control, disableForms);
      });
    });
  }

  /**
   * Manage controls value
   *
   * @param data
   * @param controlKey
   * @param splitControlKey
   * @param splitQuestionKey
   * @param control
   * @param disableForms
   * @private
   */
  private manageControlsValue(data, controlKey: string, splitControlKey, splitQuestionKey, control, disableForms: boolean) {
    // Otherwise if we have data, we search for the right value to apply to form
    let value;
    switch (true) {
      case ((typeof (data[controlKey]) === 'boolean') && controlKey === 'returningPatient'):
        value = data[controlKey].toString();
        break;
      case (splitControlKey.length === 2): // If it's a nested object from a customField
        value = (data[splitControlKey[0]] || {})[splitControlKey[1]];
        break;
      case (splitQuestionKey.length === 2): // If it's an answer to a custom question, data is a bit harder to fetch
        const answer = (data.customQuestionsAnswers || []).find((ans: QuestionAnswer) => {
          return ans.question_eid === splitQuestionKey[1];
        });
        value = ((answer || {}).answer || {}).value;
        break;
      default:
        value = data[controlKey];
        break;
    }

    // If we have a value, we apply it to the form and disable the input
    if (value) {
      control.patchValue(value);
      if (disableForms) {
        control.disable({onlySelf: true, emitEvent: false});
      }
    }
  }

  /**
   * Clean controls depending on their type
   *
   * @param controlKey
   * @param control
   * @private
   */
  private cleanControls(controlKey: string, control) {
    switch (true) {
      // Checkboxes should be unticked
      case (['createPatientAccount', 'notifyLma'].indexOf(controlKey) > -1):
        control.reset(false);
        break;
      // Consultation data should not be resetted, but the rest should
      case (['reason', 'returningPatient', 'patientNote', 'isDoctenaUser'].indexOf(controlKey) === -1):
        control.reset('');
        break;
    }
  }

  /**
   * If user has already an account, we add the control forms for login in
   */
  toggleLoginForm() {
    if (this.consultationGroup.value.isDoctenaUser) {
      this.consultationGroup.addControl('login', new UntypedFormControl(''));
      this.consultationGroup.addControl('password', new UntypedFormControl(''));
      return;
    }

    this.consultationGroup.removeControl('login');
    this.consultationGroup.removeControl('password');
  }

  /**
   * This function is meant to prevent the stepper from automatically going to the next
   * step when the loader is on. This won't work in the changeStep() output because it would
   * happen before the component naturally change step. The button click event happen slightly
   * after this, so it works this way. Ideally a callback from the stepper component would be welcome
   * but it doesn't exist.
   */
  waitBeforeNewStep() {
    if ( // Only when switching to verification and ending step
      (this.stepper.selectedIndex === 2 && this.currentStep !== 2) ||
      (this.stepper.selectedIndex === 3 && this.currentStep !== 3)
    ) {
      this.stepper.selectedIndex = this.currentStep;
    }
  }

  /**
   * This function activates the errors on checkboxes and radios, that cannot use
   * the mat-form-field function to do so at the right time.
   */
  onClickNext(stepNumber: number) {
    if (stepNumber === 0) {
      this.firstStepPristine = false;
    } else if (stepNumber === 1) {
      this.secondStepPristine = false;
    } else {
      this.waitBeforeNewStep();
    }
  }

  /**
   * This function is meant to go on back on the agenda page
   */
  onClickBack() {
    this._location.back();
    this.calendarService.resetFilters();
  }

  /**
   * Event on keyDown
   *
   * @param e
   * @param inputValue
   * @param canGoToNextStep
   */
  monitorKeyInput(e, inputValue?, canGoToNextStep?) {

    // In case it's coming from an sub-component's Output
    if (e.inputValue) {
      e = e.e;
      inputValue = e.inputValue;
    }

    const key = e.which || e.keyCode;
    // Prevent the enter key command for stepper, which brings back the user to the first
    // step of he stepper. Might probably be a bug linked to the fact the stepper is linear
    if (key === 13) {
      e.preventDefault();
      if (canGoToNextStep) {
        this.changeStep({selectedIndex: 3, previouslySelectedIndex: 2});
      }

      // Prevent using the spacebar as the first character of an input. It will
    } else if (key === 32 && !inputValue) {
      e.preventDefault();
    }
  }

  urlChangePwd() {
    let env = environment.environmentName;

    if ((env.length === 0) && (this.countryIso === 'at')) {
      env = 'patient';
    }

    env = (env.length > 0) ? '.' + env : '';

    return `http://www${env}.doctena.${this.countryIso}/${this.translateService.currentLang}/patient/change-password`;
  }

  /**
   * Called when user modify the birthday input
   *
   * @param e
   */
  onDateChange(e) {
    const newValue = moment(e.targetElement.value, 'DD-MM-YYYY');
    this.manageBirthdayDate(newValue);
  }

  /**
   * Called when user modify the birthday input
   * @param newValue
   */
  manageBirthdayDate(newValue) {
    // We synchronize the date picker with the input value
    this.personnalGroup.controls.patientBirthday.patchValue(newValue);

    // We determine if the patient is minor or major
    const conditionalInputs = ['userFirstName', 'userLastName'];
    const self = this;
    const momentMajorityAgeMin = moment().subtract(this.majorityAgeMin, 'year');
    if (newValue.isAfter(momentMajorityAgeMin) && !this.personnalGroup.controls.userFirstName) {
      // If the user switch from major birthday or no birthday to minor Birthday
      // We inject new inputs in the personnal group Form
      conditionalInputs.forEach((inputName) => {
        const newInput = new UntypedFormControl('', Validators.compose([Validators.required, Validators.pattern(/^.{2,}$/)]));
        self.personnalGroup.addControl(inputName, newInput);
      });

    } else if (newValue.isBefore(momentMajorityAgeMin) && this.personnalGroup.controls.userFirstName) {
      // If the user switch from minor birthday to major Birthday
      // We remove the user inputs from the personnal group Form
      conditionalInputs.forEach((inputName) => {
        self.personnalGroup.removeControl(inputName);
      });

    }
  }

  /**
   * Called when user change step inside the stepper
   *
   * @param e
   */
  changeStep(e) {

    // Reinit alert if we go to the next step
    if (e.selectedIndex > e.previouslySelectedIndex) {
      this.alertService.setAlert(null);
    }

    switch (e.selectedIndex) {
      case 1:
        // We only send an event to the tracker if the user REALLY changed step
        if (!this.loaderOn) {
          this.currentStep = e.selectedIndex;
          this.trackingService.eventRequestBooking(e.selectedIndex);
        }
        break;

      // If we get to the confirmation step, we send a post request for new appointment
      case 2:
        if (
          this.loaderOn || // Prevent sending a new request when one is already processing
          !this.isValidNumber() || !this.isMobileNumber()) {
          return;
        }
        this.createAppointment(e);
        break;
      // If we get to the last step, we send a patch request for confirming the new appointment.
      // We also prevent the stepper from going backwards.
      case 3:
        // Prevent sending a new request when one is already processing
        if (this.loaderOn) {
          return;
        }
        if (this.currentAppointment.status === 0) {
          this.loaderOn = true;
          this.confirmAppointment(e);
        }
        break;
      default:
        this.currentStep = e.selectedIndex;
        this.trackingService.eventRequestBooking(e.selectedIndex);
    }
  }

  /**
   * Get object with selected bookingRules values
   */
  getSelectedBookingRules() {
    const selectedBookingRules = {};
    (this.calendarService.bookingParams['bookingRules'] || []).forEach(function (bookingRule, key) {
      selectedBookingRules[bookingRule.name] = bookingRule.selectedValue;
    });
    return selectedBookingRules;
  }

  /**
   * Get booking rules
   */
  getInsuranceParam() {
    let insurance = null;
    if (this.calendarService.bookingParams['bookingRules']) {
      this.calendarService.bookingParams['bookingRules'].forEach(bookingRule => {
        switch (bookingRule.name) {
          case 'insuranceType':
            insurance = this.getInsuranceValueFromInsuranceType(bookingRule.selectedValue);
            break;
          case 'insurance':
            insurance = ((bookingRule.selectedValue === 'public') || (bookingRule.selectedValue === 'GKV')) ? 2 : 4;
            break;
          default:
            break;
        }
      });
    }

    return insurance;
  }

  /**
   * Get appointment insurance value from insuranceTypeValue
   *
   * @param insuranceType
   */
  protected getInsuranceValueFromInsuranceType(insuranceType) {
    switch (insuranceType) {
      case 'PUBLIC':
        return 2;
      case 'PRIVATE':
        return 4;
      case 'SELFPAYING':
        return 8;
      case 'TRADEASSOCIATION':
        return 32;
      default:
        return null;
    }
  }

  /**
   * Return doctor's remark
   */
  getDoctorRemark() {

    // First we retrieve remarks that are only linked to this practice
    const agenda = this.calendarService.selectedAgenda;
    const remarks = (agenda.webBookingText || []).filter(remark => {
      return !remark.practice_eid || agenda.practice.externalId === remark.practice_eid;
    });

    // If we have results, then we return the correct language value
    if (!isEmpty(remarks)) {
      const language = this.translateService.currentLang;
      return remarks[0].messages[language] || remarks[0].messages['*'];
    }

    return null;
  }

  /**
   * Prepare and format data for new appointment request
   *
   * @param e
   */
  createAppointment(e) {
    const consultationData = this.consultationGroup.getRawValue(); // Use getRawValue to get all data from form even if it's disable
    const personalData = this.personnalGroup.getRawValue();
    this.loaderOn = true;
    const slot = this.calendarService.selectedSlot;
    const agenda = this.calendarService.selectedAgenda;
    let appointmentType = Appointment.TypeEnum.Normal;

    if (slot.status === Slot.StatusEnum.Timeframe) {
      appointmentType = Appointment.TypeEnum.TimeFrame;
    }

    // Define bingli survey ID in data if exists
    if (this.bookingService.surveyId) {
      this.surveyId = this.bookingService.surveyId;
      consultationData.mybingliSurveyData = this.surveyId;
    }
    // Converting the patientBirthday and userMobile to the right format
    personalData.userMobile = $('#userMobileInput').intlTelInput('getNumber');
    const formDatas = Object.assign({}, consultationData, personalData);
    const customQuestionsData = [];

    Object.keys(formDatas).forEach((key) => {
      // Check if data is an answer to a custom question and if so store it in an object
      const splitQuestionKey = key.split('question_');
      if (splitQuestionKey.length === 2) {
        customQuestionsData.push({
          question_eid: splitQuestionKey[1],
          answer: {
            value: formDatas[key],
          }
        });
        delete formDatas[key];
      }

      // Format any input name that has a point in it, and create an object instead.
      const splitKey = key.split('.');
      if (splitKey.length === 2) {
        const parentKey = splitKey[0];
        const childKey = splitKey[1];
        formDatas[parentKey] = formDatas[parentKey] ?
          Object.assign(formDatas[parentKey], {[childKey]: formDatas[key]}) :
          {[childKey]: formDatas[key]};
        delete formDatas[key];
      }
    });

    if (customQuestionsData.length) {
      formDatas.customQuestionsAnswers = customQuestionsData;
    }

    const appointment = this.getAppointmentData(formDatas, slot, agenda, appointmentType);
    this.appointmentService.newAppointment(appointment, agenda.doctor.id,
      response => this.createAppointmentSuccessCallback(response, e),
      response => this.createAppointmentErrorCallback(response, e));
  }

  /**
   * Extract appointment data from forms
   *
   * @param consultationData
   * @param personalData
   * @param slot
   * @param agenda
   * @param appointmentType
   */
  private getAppointmentData(formDatas, slot: Slot, agenda: Agenda,
                             appointmentType: Appointment.TypeEnum.Normal | Appointment.TypeEnum.TimeFrame) {
    const data = Object.assign({}, formDatas, {
      // The only properties specified below are those not treated in the form or
      // those that needs to be modified before being sent
      returningPatient: (formDatas.returningPatient === 'true'), // String to Bool conversion
      slot_id: slot.id,
      bookingExternalSlotEid: slot.bookingExternalSlotEid,
      start: moment(slot.start).format('YYYY-MM-DD HH:mm:ss'),
      end: moment(slot.end).format('YYYY-MM-DD HH:mm:ss'),
      practice: agenda.practice.id,
      doctorReasonOfVisit: this.selectedReason.id,
      userFirstName: !formDatas.userFirstName ? formDatas.patientFirstName : formDatas.userFirstName,
      userLastName: !formDatas.userLastName ? formDatas.patientLastName : formDatas.userLastName,
      patientBirthday: formDatas.patientBirthday ?
        moment(formDatas.patientBirthday, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss') :
        null,
      language: this.systemLanguageId,
      insurance: this.getInsuranceParam(),
      optionFields: {
        booking_rule_selection: this.getSelectedBookingRules(),
        custom_booking_fields: {
          matricule: formDatas.matricule || '',
          matriculeIso: formDatas.matriculeIso || '',
        },
        prescription_number: formDatas.prescriptionNumber || '',
        date_term: formDatas.dateTerm || '',
      },
      type: appointmentType,
    });

    if (this.isReschedule) {
      data.oldApt = this.calendarService.appointmentReschedule.id;
      data.apiClientId = this.calendarService.appointmentReschedule.apiClientId;
    }

    // Data send from verified user so we don't send confirmation sms
    if (this.userData) {
      data.dontSendConfirmationSms = true;
    }

    // Remove from response to not create conflicts
    delete data.dateTerm;
    delete data.prescriptionNumber;

    return data;
  }

  /**
   * Check if the slot is one day or more
   *
   * @returns {boolean}
   */
  isSlotInOneDay() {
    const slot = this.calendarService.selectedSlot;
    const now = moment();
    const start = moment(slot.start);

    const diff = start.diff(now, 'hours');

    return diff > 24;
  }

  /**
   * Callback success action for createAppointment call
   *
   * @param {Appointment} appointment
   * @param event
   */
  createAppointmentSuccessCallback(appointment: Appointment, event) {
    if (typeof appointment !== 'undefined' && appointment.id) {
      this.currentAppointment = appointment;
      let currentIndex = event.selectedIndex;

      if (appointment.status > 0) {
        // Change step in booking process to go to the end
        currentIndex = currentIndex + 1;

        // Add a code in the confirmationGroup to skip control in this case
        const codeApt = Math.floor((Math.random() * 9999) + 1);
        this.confirmationGroup.controls.code.patchValue(codeApt);

        this.isLinear = false;
        this.isCompleted = true;
        this.appointmentAlreadyAccepted = (appointment.status === 2 || appointment.status === 4);
      }

      this.currentStep = currentIndex;
      this.stepper.selectedIndex = currentIndex;
      this.loaderOn = false;

      this.trackingService.eventRequestBooking(currentIndex);
      setTimeout(function () {
        this.resendSms = true;
      }.bind(this), 10000);
    }
  }

  /**
   * Callback error action for createAppointment call
   *
   * @param response
   * @param event
   */
  createAppointmentErrorCallback(response: HttpErrorResponse, event) {
    const alert = new Alert();
    alert.type = 'error';

    if (response.error.detail) {
      alert.text = response.error.detail;
    } else {
      switch (response.status) {
        case 400:
          alert.text = '__error_on_birthday';
          break;
        case 405:
          alert.text = '__max_appointment_for_doctor';
          break;
        case 403:
          alert.text = '__already_waiting_appointment_speciality';
          break;
        default:
          alert.text = '__booking_error_title';
      }
    }

    this.alertService.setAlert(alert);
    this.loaderOn = false;
  }

  /**
   * Prepare and format data for confirm appointment request
   *
   * @param e
   */
  confirmAppointment(e) {
    const confirmationData = Object.assign(this.confirmationGroup.value, {
      bookingExternalSlotEid: this.calendarService.selectedSlot.bookingExternalSlotEid,
    });

    if (this.isReschedule) {
      confirmationData.oldApt = this.calendarService.appointmentReschedule.id;
    }

    this.appointmentService.confirmAppointment(this.currentAppointment, confirmationData,
      response => this.confirmAppointmentSuccessCallback(response, e),
      response => this.confirmAppointmentErrorCallback(response, e));
  }

  /**
   * Callback error action for confirmAppointment call
   *
   * @param response
   * @param event
   */
  confirmAppointmentErrorCallback(response: HttpErrorResponse, event) {
    const alert = new Alert();
    alert.type = 'error';

    if (response.error.detail) {
      alert.text = response.error.detail;
    } else {
      switch (response.status) {
        case 410:
        case 409:
          alert.text = '__booking_slot_not_free_error_description';
          break;
        case 405:
          alert.text = '__booking_code_error';
          break;
        default:
          alert.text = '__booking_error_title';
      }
    }

    this.alertService.setAlert(alert);
    this.loaderOn = false;
  }

  /**
   * Callback action for confirmAppointment call
   *
   * @param {Appointment} appointment
   * @param event
   */
  confirmAppointmentSuccessCallback(appointment: Appointment, event) {
    if (typeof appointment !== 'undefined' && appointment.id) {
      this.isCompleted = true;
      this.currentAppointment = appointment;
      this.stepper.selectedIndex = event.selectedIndex;
      this.currentStep = event.selectedIndex;
      this.loaderOn = false;
      this.appointmentAlreadyAccepted = (appointment.status === 2 || appointment.status === 4);

      this.trackingService.eventRequestBooking(event.selectedIndex);
    }
  }

  /**
   * Action to resend the sms code
   */
  resendSmsCode() {
    this.resendSms = false;
    this.bookingService.resendSmsCode(this.currentAppointment.id, response => this.resendSmsCodeSuccessCallback(response),
      response => this.resendSmsCodeErrorCallback(response));
  }

  /**
   * Callback action for resendSmsCode call
   *
   * @param response
   */
  resendSmsCodeSuccessCallback(response) {
    // Do nothing
    return;
  }

  /**
   * Callback action for resendSmsCode call
   *
   * @param response
   */
  resendSmsCodeErrorCallback(response: HttpErrorResponse) {
    const alert = new Alert();
    alert.type = 'error';
    alert.text = '__sms_not_send';
    this.alertService.setAlert(alert);
  }

  /**
   * Check if it's a valid number
   */
  isValidNumber() {
    return $('#userMobileInput').intlTelInput('isValidNumber');
  }

  /**
   * Check if it's a mobile number
   */
  isMobileNumber() {
    const numberType = $('#userMobileInput').intlTelInput('getNumberType');
    return numberType === intlTelInputUtils.numberType.MOBILE ||
      numberType === intlTelInputUtils.numberType.FIXED_LINE_OR_MOBILE;
  }

  /**
   * Show CNS matricule info or not
   */
  showCnsInfo() {
    return this.customFields.matricule && this.countryIso === 'lu' &&
      this.calendarService.selectedAgenda.teleconsultation &&
      this.selectedReason.teleconsultation;
  }

  /**
   * Validate matricule
   *
   * @param e
   */
  validateSsn(e) {
    const ssn = e.target.value;
    const ssnIso = this.personnalGroup.value.matriculeIso;
    // this.appointmentService.validateSsn(ssn, ssnIso, (isValid) => {
    //   let errors = this.personnalGroup.controls.matricule.errors;
    //   if (errors && !isValid) {
    //     errors.invalid = true;
    //   } else if (!isValid) {
    //     errors = {invalid: true};
    //   } else {
    //     errors = null;
    //   }
    //   this.personnalGroup.controls.matricule.setErrors(errors);
    // });
  }

  /**
   * Success method for decryptUserData call
   *
   * @param response
   * @private
   */
  private decryptUserDataSuccess(response) {
    if (response.result) {
      this.hasPrefilledUserData = true;
      const result = response.result;
      const formattedData = {
        patientGender: result.gender,
        patientFirstName: result.firstName,
        patientLastName: result.lastName,
        patientBirthday: moment(result.birthday, 'DD/MM/YYYY').toDate(),
        userEmail: result.email,
        userMobile: result.mobile,
        matricule: result.matricule
      };
      this.fillFormWithData(formattedData, true);
    }
  }

  /**
   * Error method for decryptUserData call
   *
   * @param error
   * @private
   */
  private decryptUserDataError(error) {
    const alert = new Alert();
    alert.type = 'error';
    alert.text = '__error_decrypt_user_data';
    this.alertService.setAlert(alert);
  }

  /**
   * Get mabel for matricule input
   */
  getMatriculeLabel() {
    const isoMatricule = ['be', 'de', 'nl', 'ch'];


    // @ts-ignore
    if (this.customFields.matricule.fields[0].ui_properties && this.customFields.matricule.fields[0].ui_properties.label) {
      // @ts-ignore
      return this.customFields.matricule.fields[0].ui_properties.label;
    }

    return '__form_appointment_matricule' + (isoMatricule.includes(this.countryIso.toLowerCase()) ? `_${this.countryIso}` : '');
  }

  /**
   * Get mabel for other
   */
  getGenderOtherLabel() {
    const isoMatricule = ['de'];

    return '__gender_other' + (isoMatricule.includes(this.countryIso.toLowerCase()) ? `_${this.countryIso}` : '');
  }

  toggleCoreProcessing(e) {
    e.preventDefault();
    return this.isShowCoreProcessing = !this.isShowCoreProcessing;
  }

  setIsLogged(isLogged: boolean) {
    if (isLogged) {
      this.fillFormWithData(this.bookingService.patientData);
    }
  }

  isBingli() {
    return (this.bookingService.hasOwnProperty('surveyId') && this.bookingService.surveyId != null);
  }
}

