<div class="row header">
  <h1 class="logo">
    <a href="https://www.doctena.com" target="_blank"><img class="logo-img" src="assets/doctena-logo.png" alt="Doctena logo"></a>
  </h1>
</div>
<div class="row">
  <div class="not-found">
    <h1 class="not-found-title" i18n="@@__title_404_error_occurred">
    	{{ '__title_404_error_occurred' | translate }}
  	</h1>
    <h2 class="not-found-desc" i18n="@@__body_404_error_occurred">
      {{ '__body_404_error_occurred' | translate }}
    </h2>
    <p class="not-found-link" i18n="@@__link_404_error_occurred">{{ '__link_404_error_occurred' | translate }} <a href="https://www.doctena.com" target="_blank">Doctena.</a></p>
  </div>
</div>
