import { Pipe, PipeTransform } from '@angular/core';
import {vsprintf} from 'sprintf-js';

@Pipe({
  standalone: true,
  name: 'sprintf'
})
export class SprintfPipe implements PipeTransform {

  transform(value: string, ...args: string[]): string {
    return vsprintf(value, args);
  }

}
