import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

export type SortOrder = 'asc' | 'desc';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {


  constructor(public translateService: TranslateService) {
  }

  transform(arrayToSort: any[], sortOrder: SortOrder | string = 'asc', sortKeys?: string[]): any {
    sortOrder = sortOrder && (sortOrder.toLowerCase() as any);

    if (!arrayToSort || (sortOrder !== 'asc' && sortOrder !== 'desc')) {
      return arrayToSort;
    }

    let numberArray = [],
      stringArray = [];

    if (!sortKeys || (Array.isArray(sortKeys) && sortKeys.length === 0)) {
      numberArray = arrayToSort.filter(item => typeof item === 'number').sort();
      stringArray = arrayToSort.filter(item => typeof item === 'string').sort();
      const sorted = numberArray.concat(stringArray);

      return sortOrder === 'asc' ? sorted : sorted.reverse();
    }

    const sortedArray = this.sortByKeys(arrayToSort, sortKeys);

    return sortOrder === 'asc' ? sortedArray : sortedArray.reverse();
  }

  /**
   * Sort by array of keys, if sort is equal, sort by next key, etc.
   *
   * @param arrayToSort
   * @param sortKeys
   * @param iterator
   * @private
   */
  private sortByKeys(arrayToSort: any[], sortKeys: string[]) {
    return arrayToSort.sort((a, b) => {
      let i = 0,
        result = 0;

      while (result === 0 && i < sortKeys.length) {
        const key = sortKeys[i];
        result = this.compare(a[key], b[key]);
        i++;
      }

      return result;
    });
  }

  /**
   * Compare two values, if both are numbers, compare them, if both are strings, translate them and compare them.
   * If one is a number and the other is a string, return 0.
   * If both are not numbers or strings, return 0.
   *
   * @param a
   * @param b
   * @private
   */
  private compare(a: any, b: any) {
    if (typeof a === 'number' && typeof b === 'number') {
      return a - b;
    } else if (typeof a === 'string' && typeof b === 'string') {
      let firstTranslated = '',
        secondTranslated = '';

      this.translateService.get([a, b]).subscribe(translations => {
        firstTranslated = translations[a];
        secondTranslated = translations[b];
      });

      return firstTranslated.localeCompare(secondTranslated);
    }

    return 0;
  }
}
