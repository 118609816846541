import { Injectable } from '@angular/core';
import {CountryIsoEnum} from '../app/enums/country-iso.enum';

export type CountryData = [
  string,
  string,
  string,
  number?,
  (string | number)[]?
];

@Injectable()
export class CountryCode {
  public allCountries: CountryData[] = [
    ['Afghanistan (‫افغانستان‬‎)', CountryIsoEnum.Afghanistan, '93'],
    ['Albania (Shqipëri)', CountryIsoEnum.Albania, '355'],
    ['Algeria (‫الجزائر‬‎)', CountryIsoEnum.Algeria, '213'],
    ['American Samoa', 'as', '1', 1, ['684']],
    ['Andorra', CountryIsoEnum.Andorra, '376'],
    ['Angola', CountryIsoEnum.Angola, '244'],
    ['Anguilla', 'ai', '1', 1, ['264']],
    ['Antigua and Barbuda', 'ag', '1', 1, ['268']],
    ['Argentina', CountryIsoEnum.Argentina, '54'],
    ['Armenia (Հայաստան)', CountryIsoEnum.Armenia, '374'],
    ['Aruba', CountryIsoEnum.Aruba, '297'],
    ['Australia', CountryIsoEnum.Australia, '61', 0],
    ['Austria (Österreich)', CountryIsoEnum.Austria, '43'],
    ['Azerbaijan (Azərbaycan)', CountryIsoEnum.Azerbaijan, '994'],
    ['Bahamas', 'bs', '1', 1, ['242']],
    ['Bahrain (‫البحرين‬‎)', CountryIsoEnum.Bahrain, '973'],
    ['Bangladesh (বাংলাদেশ)', CountryIsoEnum.Bangladesh, '880'],
    ['Barbados', 'bb', '1', 1, ['246']],
    ['Belarus (Беларусь)', CountryIsoEnum.Belarus, '375'],
    ['Belgium (België)', CountryIsoEnum.Belgium, '32'],
    ['Belize', CountryIsoEnum.Belize, '501'],
    ['Benin (Bénin)', CountryIsoEnum.Benin, '229'],
    ['Bermuda', 'bm', '1', 1, ['441']],
    ['Bhutan (འབྲུག)', CountryIsoEnum.Bhutan, '975'],
    ['Bolivia', CountryIsoEnum.Bolivia, '591'],
    [
      'Bosnia and Herzegovina (Босна и Херцеговина)',
      CountryIsoEnum.BosniaAndHerzegovina,
      '387'
    ],
    ['Botswana', CountryIsoEnum.Botswana, '267'],
    ['Brazil (Brasil)', CountryIsoEnum.Brazil, '55'],
    [
      'British Indian Ocean Territory',
      CountryIsoEnum.BritishIndianOceanTerritory,
      '246'
    ],
    ['British Virgin Islands', 'vg', '1', 1, ['284']],
    ['Brunei', CountryIsoEnum.Brunei, '673'],
    ['Bulgaria (България)', CountryIsoEnum.Bulgaria, '359'],
    ['Burkina Faso', CountryIsoEnum.BurkinaFaso, '226'],
    ['Burundi (Uburundi)', CountryIsoEnum.Burundi, '257'],
    ['Cambodia (កម្ពុជា)', CountryIsoEnum.Cambodia, '855'],
    ['Cameroon (Cameroun)', CountryIsoEnum.Cameroon, '237'],
    [
      'Canada',
      CountryIsoEnum.Canada,
      '1',
      1,
      [
        '204',
        '226',
        '236',
        '249',
        '250',
        '289',
        '306',
        '343',
        '365',
        '387',
        '403',
        '416',
        '418',
        '431',
        '437',
        '438',
        '450',
        '506',
        '514',
        '519',
        '548',
        '579',
        '581',
        '587',
        '604',
        '613',
        '639',
        '647',
        '672',
        '705',
        '709',
        '742',
        '778',
        '780',
        '782',
        '807',
        '819',
        '825',
        '867',
        '873',
        '902',
        '905'
      ]
    ],
    ['Cape Verde (Kabu Verdi)', CountryIsoEnum.CapeVerde, '238'],
    ['Caribbean Netherlands', CountryIsoEnum.CaribbeanNetherlands, '599', 1],
    ['Cayman Islands', 'ky', '1', 1, ['345']],
    [
      'Central African Republic (République centrafricaine)',
      CountryIsoEnum.CentralAfricanRepublic,
      '236'
    ],
    ['Chad (Tchad)', CountryIsoEnum.Chad, '235'],
    ['Chile', CountryIsoEnum.Chile, '56'],
    ['China (中国)', CountryIsoEnum.China, '86'],
    ['Christmas Island', CountryIsoEnum.ChristmasIsland, '61', 2],
    ['Cocos (Keeling) Islands', CountryIsoEnum.Cocos, '61', 1],
    ['Colombia', CountryIsoEnum.Colombia, '57'],
    ['Comoros (‫جزر القمر‬‎)', CountryIsoEnum.Comoros, '269'],
    [
      'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
      CountryIsoEnum.CongoDRCJamhuriYaKidemokrasiaYaKongo,
      '243'
    ],
    [
      'Congo (Republic) (Congo-Brazzaville)',
      CountryIsoEnum.CongoRepublicCongoBrazzaville,
      '242'
    ],
    ['Cook Islands', CountryIsoEnum.CookIslands, '682'],
    ['Costa Rica', CountryIsoEnum.CostaRica, '506'],
    ['Côte d’Ivoire', CountryIsoEnum.CôteDIvoire, '225'],
    ['Croatia (Hrvatska)', CountryIsoEnum.Croatia, '385'],
    ['Cuba', CountryIsoEnum.Cuba, '53'],
    ['Curaçao', CountryIsoEnum.Curaçao, '599', 0],
    ['Cyprus (Κύπρος)', CountryIsoEnum.Cyprus, '357'],
    ['Czech Republic (Česká republika)', CountryIsoEnum.CzechRepublic, '420'],
    ['Denmark (Danmark)', CountryIsoEnum.Denmark, '45'],
    ['Djibouti', CountryIsoEnum.Djibouti, '253'],
    ['Dominica', CountryIsoEnum.Dominica, '1767'],
    [
      'Dominican Republic (República Dominicana)',
      CountryIsoEnum.DominicanRepublic,
      '1',
      2,
      ['809', '829', '849']
    ],
    ['Ecuador', CountryIsoEnum.Ecuador, '593'],
    ['Egypt (‫مصر‬‎)', CountryIsoEnum.Egypt, '20'],
    ['El Salvador', CountryIsoEnum.ElSalvador, '503'],
    [
      'Equatorial Guinea (Guinea Ecuatorial)',
      CountryIsoEnum.EquatorialGuinea,
      '240'
    ],
    ['Eritrea', CountryIsoEnum.Eritrea, '291'],
    ['Estonia (Eesti)', CountryIsoEnum.Estonia, '372'],
    ['Ethiopia', CountryIsoEnum.Ethiopia, '251'],
    ['Falkland Islands (Islas Malvinas)', CountryIsoEnum.FalklandIslands, '500'],
    ['Faroe Islands (Føroyar)', CountryIsoEnum.FaroeIslands, '298'],
    ['Fiji', CountryIsoEnum.Fiji, '679'],
    ['Finland (Suomi)', CountryIsoEnum.Finland, '358', 0],
    ['France', CountryIsoEnum.France, '33'],
    ['French Guiana (Guyane française)', CountryIsoEnum.FrenchGuiana, '594'],
    [
      'French Polynesia (Polynésie française)',
      CountryIsoEnum.FrenchPolynesia,
      '689'
    ],
    ['Gabon', CountryIsoEnum.Gabon, '241'],
    ['Gambia', CountryIsoEnum.Gambia, '220'],
    ['Georgia (საქართველო)', CountryIsoEnum.Georgia, '995'],
    ['Germany (Deutschland)', CountryIsoEnum.Germany, '49'],
    ['Ghana (Gaana)', CountryIsoEnum.Ghana, '233'],
    ['Gibraltar', CountryIsoEnum.Gibraltar, '350'],
    ['Greece (Ελλάδα)', CountryIsoEnum.Greece, '30'],
    ['Greenland (Kalaallit Nunaat)', CountryIsoEnum.Greenland, '299'],
    ['Grenada', CountryIsoEnum.Grenada, '1473'],
    ['Guadeloupe', CountryIsoEnum.Guadeloupe, '590', 0],
    ['Guam', 'gu', '1', 1, ['671']],
    ['Guatemala', CountryIsoEnum.Guatemala, '502'],
    ['Guernsey', CountryIsoEnum.Guernsey, '44', 1, [1481]],
    ['Guinea (Guinée)', CountryIsoEnum.Guinea, '224'],
    ['Guinea-Bissau (Guiné Bissau)', CountryIsoEnum.GuineaBissau, '245'],
    ['Guyana', CountryIsoEnum.Guyana, '592'],
    ['Haiti', CountryIsoEnum.Haiti, '509'],
    ['Honduras', CountryIsoEnum.Honduras, '504'],
    ['Hong Kong (香港)', CountryIsoEnum.HongKong, '852'],
    ['Hungary (Magyarország)', CountryIsoEnum.Hungary, '36'],
    ['Iceland (Ísland)', CountryIsoEnum.Iceland, '354'],
    ['India (भारत)', CountryIsoEnum.India, '91'],
    ['Indonesia', CountryIsoEnum.Indonesia, '62'],
    ['Iran (‫ایران‬‎)', CountryIsoEnum.Iran, '98'],
    ['Iraq (‫العراق‬‎)', CountryIsoEnum.Iraq, '964'],
    ['Ireland', CountryIsoEnum.Ireland, '353'],
    ['Isle of Man', CountryIsoEnum.IsleOfMan, '44', 2, [1624]],
    ['Israel (‫ישראל‬‎)', CountryIsoEnum.Israel, '972'],
    ['Italy (Italia)', CountryIsoEnum.Italy, '39', 0],
    ['Jamaica', 'jm', '1', 1, ['876']],
    ['Japan (日本)', CountryIsoEnum.Japan, '81'],
    ['Jersey', CountryIsoEnum.Jersey, '44', 3, [1534]],
    ['Jordan (‫الأردن‬‎)', CountryIsoEnum.Jordan, '962'],
    ['Kazakhstan (Казахстан)', CountryIsoEnum.Kazakhstan, '7', 1],
    ['Kenya', CountryIsoEnum.Kenya, '254'],
    ['Kiribati', CountryIsoEnum.Kiribati, '686'],
    ['Kosovo', CountryIsoEnum.Kosovo, '383'],
    ['Kuwait (‫الكويت‬‎)', CountryIsoEnum.Kuwait, '965'],
    ['Kyrgyzstan (Кыргызстан)', CountryIsoEnum.Kyrgyzstan, '996'],
    ['Laos (ລາວ)', CountryIsoEnum.Laos, '856'],
    ['Latvia (Latvija)', CountryIsoEnum.Latvia, '371'],
    ['Lebanon (‫لبنان‬‎)', CountryIsoEnum.Lebanon, '961'],
    ['Lesotho', CountryIsoEnum.Lesotho, '266'],
    ['Liberia', CountryIsoEnum.Liberia, '231'],
    ['Libya (‫ليبيا‬‎)', CountryIsoEnum.Libya, '218'],
    ['Liechtenstein', CountryIsoEnum.Liechtenstein, '423'],
    ['Lithuania (Lietuva)', CountryIsoEnum.Lithuania, '370'],
    ['Luxembourg', CountryIsoEnum.Luxembourg, '352'],
    ['Macau (澳門)', CountryIsoEnum.Macau, '853'],
    ['Macedonia (FYROM) (Македонија)', CountryIsoEnum.Macedonia, '389'],
    ['Madagascar (Madagasikara)', CountryIsoEnum.Madagascar, '261'],
    ['Malawi', CountryIsoEnum.Malawi, '265'],
    ['Malaysia', CountryIsoEnum.Malaysia, '60'],
    ['Maldives', CountryIsoEnum.Maldives, '960'],
    ['Mali', CountryIsoEnum.Mali, '223'],
    ['Malta', CountryIsoEnum.Malta, '356'],
    ['Marshall Islands', CountryIsoEnum.MarshallIslands, '692'],
    ['Martinique', CountryIsoEnum.Martinique, '596'],
    ['Mauritania (‫موريتانيا‬‎)', CountryIsoEnum.Mauritania, '222'],
    ['Mauritius (Moris)', CountryIsoEnum.Mauritius, '230'],
    ['Mayotte', CountryIsoEnum.Mayotte, '262', 1],
    ['Mexico (México)', CountryIsoEnum.Mexico, '52'],
    ['Micronesia', CountryIsoEnum.Micronesia, '691'],
    ['Moldova (Republica Moldova)', CountryIsoEnum.Moldova, '373'],
    ['Monaco', CountryIsoEnum.Monaco, '377'],
    ['Mongolia (Монгол)', CountryIsoEnum.Mongolia, '976'],
    ['Montenegro (Crna Gora)', CountryIsoEnum.Montenegro, '382'],
    ['Montserrat', 'ms', '1', 1, ['664']],
    ['Morocco (‫المغرب‬‎)', CountryIsoEnum.Morocco, '212', 0],
    ['Mozambique (Moçambique)', CountryIsoEnum.Mozambique, '258'],
    ['Myanmar (Burma) (မြန်မာ)', CountryIsoEnum.Myanmar, '95'],
    ['Namibia (Namibië)', CountryIsoEnum.Namibia, '264'],
    ['Nauru', CountryIsoEnum.Nauru, '674'],
    ['Nepal (नेपाल)', CountryIsoEnum.Nepal, '977'],
    ['Netherlands (Nederland)', CountryIsoEnum.Netherlands, '31'],
    ['New Caledonia (Nouvelle-Calédonie)', CountryIsoEnum.NewCaledonia, '687'],
    ['New Zealand', CountryIsoEnum.NewZealand, '64'],
    ['Nicaragua', CountryIsoEnum.Nicaragua, '505'],
    ['Niger (Nijar)', CountryIsoEnum.Niger, '227'],
    ['Nigeria', CountryIsoEnum.Nigeria, '234'],
    ['Niue', CountryIsoEnum.Niue, '683'],
    ['Norfolk Island', CountryIsoEnum.NorfolkIsland, '672'],
    ['North Korea (조선 민주주의 인민 공화국)', CountryIsoEnum.NorthKorea, '850'],
    ['Northern Mariana Islands', CountryIsoEnum.NorthernMarianaIslands, '1670'],
    ['Norway (Norge)', CountryIsoEnum.Norway, '47', 0],
    ['Oman (‫عُمان‬‎)', CountryIsoEnum.Oman, '968'],
    ['Pakistan (‫پاکستان‬‎)', CountryIsoEnum.Pakistan, '92'],
    ['Palau', CountryIsoEnum.Palau, '680'],
    ['Palestine (‫فلسطين‬‎)', CountryIsoEnum.Palestine, '970'],
    ['Panama (Panamá)', CountryIsoEnum.Panama, '507'],
    ['Papua New Guinea', CountryIsoEnum.PapuaNewGuinea, '675'],
    ['Paraguay', CountryIsoEnum.Paraguay, '595'],
    ['Peru (Perú)', CountryIsoEnum.Peru, '51'],
    ['Philippines', CountryIsoEnum.Philippines, '63'],
    ['Poland (Polska)', CountryIsoEnum.Poland, '48'],
    ['Portugal', CountryIsoEnum.Portugal, '351'],
    ['Puerto Rico', CountryIsoEnum.PuertoRico, '1', 3, ['787', '939']],
    ['Qatar (‫قطر‬‎)', CountryIsoEnum.Qatar, '974'],
    ['Réunion (La Réunion)', CountryIsoEnum.Réunion, '262', 0],
    ['Romania (România)', CountryIsoEnum.Romania, '40'],
    ['Russia (Россия)', CountryIsoEnum.Russia, '7', 0],
    ['Rwanda', CountryIsoEnum.Rwanda, '250'],
    [
      'Saint Barthélemy (Saint-Barthélemy)',
      CountryIsoEnum.SaintBarthélemy,
      '590',
      1
    ],
    ['Saint Helena', CountryIsoEnum.SaintHelena, '290'],
    ['Saint Kitts and Nevis', CountryIsoEnum.SaintKittsAndNevis, '1869'],
    ['Saint Lucia', 'lc', '1', 1, ['758']],
    [
      'Saint Martin (Saint-Martin (partie française))',
      CountryIsoEnum.SaintMartin,
      '590',
      2
    ],
    [
      'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
      CountryIsoEnum.SaintPierreAndMiquelon,
      '508'
    ],
    ['Saint Vincent and the Grenadines', 'vc', '1', 1, ['784']],
    ['Samoa', CountryIsoEnum.Samoa, '685'],
    ['San Marino', CountryIsoEnum.SanMarino, '378'],
    [
      'São Tomé and Príncipe (São Tomé e Príncipe)',
      CountryIsoEnum.SãoToméAndPríncipe,
      '239'
    ],
    [
      'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
      CountryIsoEnum.SaudiArabia,
      '966'
    ],
    ['Senegal (Sénégal)', CountryIsoEnum.Senegal, '221'],
    ['Serbia (Србија)', CountryIsoEnum.Serbia, '381'],
    ['Seychelles', CountryIsoEnum.Seychelles, '248'],
    ['Sierra Leone', CountryIsoEnum.SierraLeone, '232'],
    ['Singapore', CountryIsoEnum.Singapore, '65'],
    ['Sint Maarten', 'sx', '1', 1, ['721']],
    ['Slovakia (Slovensko)', CountryIsoEnum.Slovakia, '421'],
    ['Slovenia (Slovenija)', CountryIsoEnum.Slovenia, '386'],
    ['Solomon Islands', CountryIsoEnum.SolomonIslands, '677'],
    ['Somalia (Soomaaliya)', CountryIsoEnum.Somalia, '252'],
    ['South Africa', CountryIsoEnum.SouthAfrica, '27'],
    ['South Korea (대한민국)', CountryIsoEnum.SouthKorea, '82'],
    ['South Sudan (‫جنوب السودان‬‎)', CountryIsoEnum.SouthSudan, '211'],
    ['Spain (España)', CountryIsoEnum.Spain, '34'],
    ['Sri Lanka (ශ්‍රී ලංකාව)', CountryIsoEnum.SriLanka, '94'],
    ['Sudan (‫السودان‬‎)', CountryIsoEnum.Sudan, '249'],
    ['Suriname', CountryIsoEnum.Suriname, '597'],
    ['Svalbard and Jan Mayen', CountryIsoEnum.SvalbardAndJanMayen, '47', 1],
    ['Swaziland', CountryIsoEnum.Swaziland, '268'],
    ['Sweden (Sverige)', CountryIsoEnum.Sweden, '46'],
    ['Switzerland (Schweiz)', CountryIsoEnum.Switzerland, '41'],
    ['Syria (‫سوريا‬‎)', CountryIsoEnum.Syria, '963'],
    ['Taiwan (台灣)', CountryIsoEnum.Taiwan, '886'],
    ['Tajikistan', CountryIsoEnum.Tajikistan, '992'],
    ['Tanzania', CountryIsoEnum.Tanzania, '255'],
    ['Thailand (ไทย)', CountryIsoEnum.Thailand, '66'],
    ['Timor-Leste', CountryIsoEnum.TimorLeste, '670'],
    ['Togo', CountryIsoEnum.Togo, '228'],
    ['Tokelau', CountryIsoEnum.Tokelau, '690'],
    ['Tonga', CountryIsoEnum.Tonga, '676'],
    ['Trinidad and Tobago', 'tt', '1', 1, ['868']],
    ['Tunisia (‫تونس‬‎)', CountryIsoEnum.Tunisia, '216'],
    ['Turkey (Türkiye)', CountryIsoEnum.Turkey, '90'],
    ['Turkmenistan', CountryIsoEnum.Turkmenistan, '993'],
    ['Turks and Caicos Islands', CountryIsoEnum.TurksAndCaicosIslands, '1649'],
    ['Tuvalu', CountryIsoEnum.Tuvalu, '688'],
    ['U.S. Virgin Islands', 'vi', '1', 1, ['340']],
    ['Uganda', CountryIsoEnum.Uganda, '256'],
    ['Ukraine (Україна)', CountryIsoEnum.Ukraine, '380'],
    [
      'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
      CountryIsoEnum.UnitedArabEmirates,
      '971'
    ],
    ['United Kingdom', CountryIsoEnum.UnitedKingdom, '44', 0],
    ['United States', CountryIsoEnum.UnitedStates, '1', 0],
    ['Uruguay', CountryIsoEnum.Uruguay, '598'],
    ['Uzbekistan (Oʻzbekiston)', CountryIsoEnum.Uzbekistan, '998'],
    ['Vanuatu', CountryIsoEnum.Vanuatu, '678'],
    ['Vatican City (Città del Vaticano)', CountryIsoEnum.VaticanCity, '39', 1],
    ['Venezuela', CountryIsoEnum.Venezuela, '58'],
    ['Vietnam (Việt Nam)', CountryIsoEnum.Vietnam, '84'],
    ['Wallis and Futuna', CountryIsoEnum.WallisAndFutuna, '681'],
    ['Western Sahara (‫الصحراء الغربية‬‎)', CountryIsoEnum.WesternSahara, '212', 1],
    ['Yemen (‫اليمن‬‎)', CountryIsoEnum.Yemen, '967'],
    ['Zambia', CountryIsoEnum.Zambia, '260'],
    ['Zimbabwe', CountryIsoEnum.Zimbabwe, '263'],
    ['Åland Islands', CountryIsoEnum.ÅlandIslands, '358', 1]
  ];
}
