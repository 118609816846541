import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {HttpClient} from '@angular/common/http';
import BlueBirdPromise from 'bluebird';
import axios from 'axios';
import {v4 as uuidv4} from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService extends HttpService {

  constructor(http: HttpClient) {
    super(http);
  }

  /**
   * Get signed url data for file upload
   */
  getSignedUrlData() {
    return new BlueBirdPromise((resolve, reject) => {
      this.get(`${this.apiUrl}/pre-signed-post`)
        .subscribe({
          next: (data) => {
            resolve(data);
          },
          error: (error) => {
            reject(error);
          }
        });
    });
  }

  /**
   * Upload files
   *
   * @param formData
   * @param signedUrl
   */
  uploadFile(formData: FormData, signedUrl: string) {
    return new BlueBirdPromise((resolve, reject) => {
      return axios.post(signedUrl, formData, {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*'
        }
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  uploadAppointmentFileAttachments(fileAttachmentsData: any, doctorEid: string) {
    const fileAttachments = [],
      promises = [];

    // Loop through the form data to get the files for each category
    return BlueBirdPromise.each(Object.keys(fileAttachmentsData), (key: string) => {
      const uploadedFiles = fileAttachmentsData[key]?.files ?? [];

      if (uploadedFiles.length === 0) {
        return;
      }

      // If there are files, append them to the FormData
      // Loop through the files and append them to the FormData
      return BlueBirdPromise.each(uploadedFiles, (file: any) => {
        const externalId = uuidv4(),
          fileExtension = file.name.split('.').pop(),
          objectName = `${doctorEid}_${externalId}.${fileExtension}`,
          categoryData = key.replace('fileUpload_', ''),
          uploadData = new FormData();

        let mimeType = file.type;

        // If file is HEIC or HEIF and mimeType is empty, set the mimeType to image/heic
        if ((fileExtension === 'heic' || fileExtension === 'heif') && mimeType === '') {
          mimeType = `image/${fileExtension}`;
        }

        // Push the file data to the fileAttachments array
        fileAttachments.push({
          eid: externalId,
          originalFilename: file.name,
          source: 'patient',
          category: categoryData.split('##')[0],
          file_attachment_setting_id: parseInt(categoryData.split('##')[1]),
          size: file.size,
        });

        return promises.push(
          this.getSignedUrlData()
            .then((data: any) => {
              uploadData.append('key', objectName);
              uploadData.append('X-Amz-Credential', data?.formInputs?.['X-Amz-Credential']);
              uploadData.append('X-Amz-Algorithm', data?.formInputs?.['X-Amz-Algorithm']);
              uploadData.append('X-Amz-Date', data?.formInputs?.['X-Amz-Date']);
              uploadData.append('Policy', data?.formInputs?.Policy);
              uploadData.append('X-Amz-Signature', data?.formInputs?.['X-Amz-Signature']);
              uploadData.append('Content-Type', mimeType);
              uploadData.append('file', file);

              return this.uploadFile(uploadData, data?.formAttributes?.action)
                .then(() => console.log('File uploaded successfully', file.name));
            })
        );
      });
    })
      .then(() => {
        return BlueBirdPromise.all(promises)
          .then(() => {
            console.log('All files uploaded successfully');
            return fileAttachments;
          });
      });
  }
}
