import {Pipe, PipeTransform} from '@angular/core';
import {Agenda} from '../../models/agenda';
import {sortBy} from 'lodash';
import {DateTime} from 'luxon';

@Pipe({
  name: 'orderPracticeAgendas'
})
export class OrderPracticeAgendasPipe implements PipeTransform {

  transform(
    unsortedAgendas: Array<Agenda>,
    defaultOrderAgendas: Array<any>,
    agendaSlots: any
  ): Array<Agenda> {

    let sortedAgendas = [];

    // First sort agendas in their default way
    defaultOrderAgendas.forEach(function (agendaBase, keyBase) {
      unsortedAgendas.forEach(function (agenda, key) {
        if (agendaBase.id === agenda.id) {
          sortedAgendas.push(agenda);
        }
      });
    });

    // Then sort them by availabilities
    // First we make sure we have downloaded the slots for every agenda
    if (agendaSlots.slots && (Object.keys(agendaSlots.slots).length === unsortedAgendas.length)) {
      sortedAgendas = sortBy(sortedAgendas, agenda => {

        const nextSlot = (agendaSlots.nextSlots[agenda.externalId] ? // Else have nextSlot ?
          DateTime.fromJSDate(agendaSlots.nextSlots[agenda.externalId]).toUnixInteger() : null); // Else have nothing...

        // Return timestamp of 1st slot or 1st nextSlot
        return agendaSlots.slots[agenda.externalId].length > 0 ? // Have slots ?
          agendaSlots.slots[agenda.externalId][0].startTimestamp : nextSlot; // Have next slot ?
      });
    }

    return sortedAgendas;
  }

}
