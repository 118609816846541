import {User} from './user';
import {Speciality} from './speciality';

export class Doctor extends User {
  constructor() {
    super();
    this.omnihisGateway = false;
  }

  portalVersion: Doctor.PortalVersionEnum;
  terminlandGateway: boolean;
  ultragendaGateway: boolean;
  omnihisGateway: boolean;
  otherDoctor: boolean;
  publicUri: string;
  specialities: Speciality[];
  name: string;
}

export namespace Doctor {
  export enum PortalVersionEnum {
    DoctenaGo = <any> 1,
    DoctenaPro = <any> 2,
    Sanmax = <any> 3,
    Doxter = <any> 4,
  }
}
