import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {omit, pick, merge} from 'lodash';
import {appToken} from '../authentication/authentication.service';
import {environment} from '../../../environments/environment';

@Injectable()
export class HttpService {

  httpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Doctena': 'booking-app',
    }),
    withCredentials: true,
  };

  apiUrl: string;
  ratingsApiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
    this.ratingsApiUrl = environment.ratingsApiUrl;

    // If we're on local env we don't send the header 'Doctena'
    if (environment.environmentName === 'local') {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        withCredentials: true,
      };
    }
  }

  get(url: string, params?: HttpParams, headers: HttpHeaders = this.getAuthHeaders()): Observable<any> {
    return this.http.get<any>(url, {params: params, headers: headers})
      .pipe();
  }

  patch(url: string, body, headers: HttpHeaders = this.getAuthHeaders()): Observable<any> {
    return this.http.patch(url, body, {headers: headers}).pipe();
  }

  post(url: string, body?, headers: HttpHeaders = this.getAuthHeaders()): Observable<any> {
    return this.http.post<any>(url, body, {headers: headers}).pipe();
  }

  put(url: string, body?, headers: HttpHeaders = this.getAuthHeaders()): Observable<any> {
    return this.http.put<any>(url, body, {headers: headers}).pipe();
  }

  /**
   * Strip the data object of the useless _embedded and _links attributes
   *
   * @param data
   * @returns {any}
   */
  stripUselessData(data) {
    const component = this;
    if (typeof data !== 'undefined' && (data._embedded || typeof data._embedded !== 'undefined')) {
      const embeddedData = pick(data._embedded, Object.keys(data._embedded));

      const extractData = Object.keys(embeddedData).map(function (itm) {
        return embeddedData[itm];
      });

      extractData.forEach(function (embedded, key) {
        component.stripUselessData(embedded);
      });

      data = merge(data, embeddedData);

      return omit(data, ['_embedded', '_links']);
    }

    return data;

  }

  private log(message: string) {
    // console.log('HttpService: ' + message);
  }

  /**
   * Get headers with bearer
   *
   * @returns {HttpHeaders}
   */
  private getAuthHeaders() {
    let headers = this.httpOptions.headers;

    if (appToken) {
      headers = headers.append('Authorization', 'Bearer ' + appToken);
    }

    return headers;
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead

      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }

}
