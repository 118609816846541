<div id="reschedule-view">
	<app-alert></app-alert>
	<app-loader></app-loader>

	<app-filters [agenda]="agenda" *ngIf="agenda" [isPracticeView]="isPracticeView" [readOnly]="true"></app-filters>

	<app-doctor-details [agenda]="agenda" *ngIf="agenda" [hasDoctorImage]="!hidePicture" [isPracticeView]="isPracticeView"></app-doctor-details>
</div>

<app-cookie-info *ngIf="agenda && !isHideCookieBanner()" [countryIso]="countryIso"></app-cookie-info>
