<mat-card class="row doctorDetails" [ngStyle]="{'background-color' : extendedColorBg}">
  <div class="col-12 col-md-5 doctorImage" *ngIf="hasDoctorImage">
    <div class="row">
      <div class="col-4 col-md-5">
          <img alt="avatar" src="{{avatarPicture}}" class="img-thumbnail" />
      </div>
      <div class="col-8 col-md-7">
        <h5 matLine class="drName text-uppercase">
          {{doctor.name}}
        </h5>

        <app-ratings
          class="ratings__container"
          [doctorEid] = "doctor.externalId"
          [countryIso] = "agenda.practice.country_iso">
        </app-ratings>

        <p matLine *ngIf="shouldShowPracticeLink()">
          <a
            class="link drLink"
            (click)="loadPracticeView()"
            i18n="@@__practice_go_page">
            {{'__practice_go_page' | translate}}
          </a>
        </p>

        <p matLine class="drSpecialty">
          {{getSpecialityName()}}
        </p>

        <address matLine class="drAddress" [innerHtml]="practice.address | nl2br">
        </address>
      </div>
    </div>
  </div>

<!--  Practice/Group view without picture-->
  <div class="col-6 col-md-3 doctorImage" *ngIf="!hasDoctorImage && isPracticeView">
    <div class="row">
      <div class="col-8 col-md-7">
        <h5 matLine class="drName text-uppercase">
          {{doctor.name}}
        </h5>

        <app-ratings
          class="ratings__container"
          [doctorEid] = "doctor.externalId"
          [countryIso] = "agenda.practice.country_iso">
        </app-ratings>

        <p matLine *ngIf="shouldShowPracticeLink()">
          <a
            class="link drLink"
            (click)="loadPracticeView()"
            i18n="@@__practice_go_page">
            {{'__practice_go_page' | translate}}
          </a>
        </p>

        <p matLine class="drSpecialty">
          {{getSpecialityName()}}
        </p>

        <address matLine class="drAddress" [innerHtml]="practice.address | nl2br">
        </address>
      </div>
    </div>
  </div>
  <div class="col-12 doctorCalendar"
       [ngClass]="{'col-md-7':hasDoctorImage, 'col-md-12':(!hasDoctorImage && !isPracticeView), 'col-md-9':(!hasDoctorImage && isPracticeView)}">
    <div class="row" *ngIf="!isPracticeView">
      <app-week-selector>
      </app-week-selector>
    </div>
    <div class="alert alert-info" *ngIf="calendarService.notificationMessage[agenda.externalId] && showMessageNotificationWebNoSlot">
      <p class="no-mg-bottom newlines">{{ calendarService.notificationMessage[agenda.externalId] }}</p>
    </div>
    <div *ngIf="!(calendarService.notificationMessage[agenda.externalId])">
      <div class="row">
        <app-calendar
          [agenda]="agenda"
          [isPracticeView]="isPracticeView"
          *ngIf="agenda">
        </app-calendar>
      </div>
    </div>
  </div>
</mat-card>
