<!--List of generated translation by booking rules generation-->
<div *ngIf="false">
  <input type="hidden" i18n-placeholder="@@__select_insurance_explanation"
         placeholder="{{ '__select_insurance_explanation' | translate}}">
  <input type="hidden" i18n-placeholder="@@__name_insurancetype_selfpaying"
         placeholder="{{ '__name_insurancetype_selfpaying' | translate}}">
  <input type="hidden" i18n-placeholder="@@__name_insurancetype_tradeassociation"
         placeholder="{{ '__name_insurancetype_tradeassociation' | translate}}">
  <input type="hidden" i18n-placeholder="@@__select_patient_explanation"
         placeholder="{{ '__select_patient_explanation' | translate}}">
  <input type="hidden" i18n-placeholder="@@__name_insurancetype_private"
         placeholder="{{ '__name_insurancetype_private' | translate}}">
  <input type="hidden" i18n-placeholder="@@__name_insurancetype_public"
         placeholder="{{ '__name_insurancetype_public' | translate}}">
  <input type="hidden" i18n-placeholder="@@__name_insurancetype_public_at"
         placeholder="{{ '__name_insurancetype_public_at' | translate}}">
  <input type="hidden" i18n-placeholder="@@__name_practicerelation_new"
         placeholder="{{ '__name_practicerelation_new' | translate}}">
  <input type="hidden" i18n-placeholder="@@__name_practicerelation_existing"
         placeholder="{{ '__name_practicerelation_existing' | translate}}">
  <input type="hidden" i18n-placeholder="@@__name_insurance_private"
         placeholder="{{ '__name_insurance_private' | translate}}">
  <input type="hidden" i18n-placeholder="@@__name_insurance_public"
         placeholder="{{ '__name_insurance_public' | translate}}">
  <input type="hidden" i18n-placeholder="@@__name_insurance_pkv"
         placeholder="{{ '__name_insurance_pkv' | translate}}">
  <input type="hidden" i18n-placeholder="@@__name_insurance_gkv"
         placeholder="{{ '__name_insurance_gkv' | translate}}">
  <input type="hidden" i18n-placeholder="@@__name_newpatient_1"
         placeholder="{{ '__name_newpatient_1' | translate}}">
  <input type="hidden" i18n-placeholder="@@__name_newpatient_0"
         placeholder="{{ '__name_newpatient_0' | translate}}">
  <input type="hidden" i18n-placeholder="@@__bingli_diagnosis"
         placeholder="{{ '__bingli_diagnosis' | translate}}">
</div>

<div class="row">
  <!--List of bookingRules-->
  <div class="selectFields">
    <div class="col-12 selectFieldProfile booking-rule-filter flex-container" *ngFor="let bookingRule of bookingRules">
      <div class="touchedFilters">
        <i *ngIf="touchedFilters[bookingRule.name] && bookingRule.show" class="material-icons">done</i>
        <i *ngIf="!touchedFilters[bookingRule.name] && bookingRule.show" class="pulsing-dot"></i>
      </div>
      <div class="item-flex">
        <mat-form-field *ngIf="bookingRule.show">
          <mat-select class="md-no-underline" *ngIf="bookingRule.type == 'ENUM'" name="select{{bookingRule.name}}"
                      id="select{{bookingRule.name}}" [disabled]="(readOnly || preselectRov !== null) || isDisabled"
                      [(ngModel)]="bookingRule.selectedValue"
                      placeholder="{{ touchedFilters[bookingRule.name] ? '' : bookingRule.message | translate }}"
                      (selectionChange)="onChangeBookingRules(bookingRule.name)">
            <mat-option *ngFor="let value of bookingRule.values" [value]="value.data">
              {{ value.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- List of ROV's -->
    <div class="col-12 selectFieldProfile mg-bottom flex-container" *ngIf="showRovList()">
      <div class="touchedFilters">
        <i *ngIf="touchedFilters['reasonOfVisit']" class="material-icons">done</i>
        <i *ngIf="!touchedFilters['reasonOfVisit']" class="pulsing-dot"></i>
      </div>
      <div class="item-flex">
        <mat-form-field>
          <mat-select id="selectrov" class="mat-select- -no-underline" i18n-placeholder="@@__search_reason_placeholder"
                      placeholder="{{ touchedFilters['reasonOfVisit'] ? '' : '__search_reason_placeholder' | translate}}"
                      [(ngModel)]="selectedReasonOfVisits" [disabled]="readOnly || preselectRov !== null"
                      (selectionChange)="onChangeReasonOfVisits($event)">
            <mat-option *ngFor="let rov of reasonOfVisits | sort: 'asc' : rovOrdering" [value]="rov.reasonData">
              {{ rov.reasonData.length && rov.reasonData[0].teleconsultation ? '🎦' : '' }}
              {{ rov.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- Doctor custom message -->
    <div
      class="alert alert-info semi-mg-bottom"
      *ngIf="calendarService.customMessage && !isPracticeView && !showMessageNotificationWebNoSlot">
      <p class="semi-mg-bottom"><strong i18n="@@__custom_notification_title">
        {{ '__custom_notification_title' | translate | sprintf: calendarService.customMessage.practitionerName }}
      </strong></p>
      <p class="no-mg-bottom newlines">{{ calendarService.customMessage.message }}</p>
    </div>
    <!-- teleconsultation information -->
    <p *ngIf="selectedReasonOfVisits?.[0]?.teleconsultation"
       class="alert alert-success">
        <span i18n="@@__teleconsultation_booking_warning">
          {{ '__teleconsultation_booking_warning' | translate }}
        </span>
      <a [href]="teleconsultationDiscoverPdf" target="_blank" i18n="@@__learn_more">
        {{ '__learn_more' | translate }}
      </a>
    </p>
  </div>
</div>
