import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {FormGroup, ReactiveFormsModule, FormBuilder, Validators, UntypedFormControl} from '@angular/forms';
import {CustomFieldsResponse} from '../models/customFields';
import {CalendarService} from '../service/calendar/calendar.service';
import {TranslateService} from '@ngx-translate/core';
import {PatientService} from '../service/patient/patient.service';
import {BookingService} from '../service/booking/booking.service';
import {environment} from '../../environments/environment';
import {NgClass, NgIf} from '@angular/common';
import {TranslatePipe} from '../pipe/translate/translate.pipe';
import {MatError, MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatOption as MatOptionAutoComplete} from '@angular/material/autocomplete';
import {MatOption, MatSelect} from '@angular/material/select';
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {DateTime} from 'luxon';
import {bookingLoginDoctenaAccount} from '../routing/routing.component';
import {FieldsetComponent} from '../fieldset/fieldset.component';
import {SprintfPipe} from '../pipe/sprintf/sprintf.pipe';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatButton} from '@angular/material/button';
import {MatStepperNext, MatStepperPrevious} from '@angular/material/stepper';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {PhoneInputComponent} from '../phone-input/phone-input.component';
import {MatriculeInputComponent} from '../matricule-input/matricule-input.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@Component({
  selector: 'app-booking-information-step',
  standalone: true,
  imports: [
    NgIf,
    TranslatePipe,
    ReactiveFormsModule,
    MatError,
    MatFormField,
    MatInput,
    MatOption,
    MatOptionAutoComplete,
    MatSelect,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatLabel,
    MatSuffix,
    FieldsetComponent,
    SprintfPipe,
    NgClass,
    MatCheckbox,
    MatButton,
    MatStepperNext,
    MatStepperPrevious,
    PhoneInputComponent,
    MatriculeInputComponent
  ],
  templateUrl: './booking-information-step.component.html',
  styleUrl: './booking-information-step.component.scss',
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class BookingInformationStepComponent implements OnInit, AfterViewInit {

  constructor(public calendarService: CalendarService,
              public translateService: TranslateService,
              private formBuilder: FormBuilder,
              public patientService: PatientService,
              private ref: ChangeDetectorRef,
              private elementRef: ElementRef,
              public bookingService: BookingService,
              @Inject(MAT_DATE_FORMATS) private dateFormats: any) {

    this.countryIso = this.calendarService?.selectedAgenda?.practice?.country_iso ?? 'lu';
    this.bookingService.majorityAgeMin = environment.underageDefinition[this.countryIso];
    this.isShowCoreProcessing = false;
    this.isPristine = false;

    this.datePickerDelimeter = this.translateService.currentLang === 'de' ? '.' : '/';
    this.dateFormats.display.dateInput = `DD${this.datePickerDelimeter}MM${this.datePickerDelimeter}YYYY`;
  }

  /**
   * Booking login doctena account
   */
  bookingLoginDoctenaAccount = bookingLoginDoctenaAccount;

  /**
   * Visible matricule country
   */
  matriculeVisibleCountries = ['de', 'at', 'ch', 'be', 'nl', 'lu', 'fr'];

  /**
   * Phone preferred countries
   */
  phonePreferredCountries = ['de', 'at', 'ch', 'be', 'nl', 'lu'];

  /**
   * Date picker delimeter
   */
  datePickerDelimeter: string;

  /**
   * Show core processing
   */
  isShowCoreProcessing: boolean;

  /**
   * Country iso
   */
  countryIso: string;

  /**
   * Terms of use url
   */
  termsOfUseUrl: string;

  /**
   * Policy url
   */
  policyUrl: string;

  /**
   * Gdpr url
   */
  gdprUrl: string;

  /**
   * Teleconsultation info url
   */
  teleconsultationInfoUrl: string;

  /**
   * Core processing tags
   */
  coreProcessingTags: string[];

  /**
   * Terms links tags
   */
  termsLinksTags: string[];

  /**
   * Is reschedule
   */
  isReschedule: boolean;

  /**
   * Is pristine
   */
  isPristine: boolean;

  /**
   * Custom fields
   */
  customFields: CustomFieldsResponse;

  /**
   * String
   */
  String = String;

  /**
   * User data
   */
  userData: any;

  /**
   * Is logged patient
   */
  isLoggedPatient: boolean;

  /**
   * Initial country
   */
  initialMatriculeCountry: string;

  /**
   * Form group
   */
  @Input() formGroup: FormGroup = this.formBuilder.group({});

  /**
   * Phone select
   */
  @ViewChild('phoneSelect', {static: false}) phoneSelect: any;

  /**
   * Initialize the component
   */
  ngOnInit(): void {
    const languageSelected = this.translateService.currentLang,
      agenda = this.calendarService.selectedAgenda;

    this.userData = this.bookingService.formattedUserData;
    this.isLoggedPatient = this.bookingService.patientData !== null && this.bookingService.patientData !== undefined;
    this.isReschedule = (typeof this.calendarService.appointmentReschedule !== 'undefined');

    // Generate form fields from customFields data from CPP API
    this.termsOfUseUrl = environment.termsOfUse[languageSelected];
    this.policyUrl = environment.policy[languageSelected];
    this.gdprUrl = environment.gdpr[languageSelected];
    this.teleconsultationInfoUrl = environment.teleconsultationInfo[languageSelected];

    // Link tags to place in translation
    this.coreProcessingTags = [
      '<a class="coreProcessing" href=".">',
      '</a>'
    ];

    // Link tags to place in translation
    this.termsLinksTags = [
      '<a target="_blank" rel="nofollow" href="' + this.termsOfUseUrl + '">',
      '<a target="_blank" rel="nofollow" href="' + this.policyUrl + '">',
      '<a target="_blank" rel="nofollow" href="' + this.gdprUrl + '">',
      '<span>' + agenda?.practice?.contact + '</span>',
      '</a>'
    ];

    const controls = [
      {
        name: 'patientGender',
        control: this.formBuilder.control('', Validators.required)
      },
      {
        name: 'patientFirstName',
        control: this.formBuilder.control('', Validators.compose([Validators.required, Validators.pattern(/^.{2,}$/)]))
      },
      {
        name: 'patientLastName',
        control: this.formBuilder.control('', Validators.compose([Validators.required, Validators.pattern(/^.{2,}$/)]))
      },
      {
        name: 'patientBirthday',
        control: this.formBuilder.control('', Validators.required)
      },
      {
        name: 'userEmail',
        control: this.formBuilder.control('', Validators.compose([Validators.required, Validators.email]))
      },
      {
        name: 'userMobile',
        control: this.formBuilder.control('', Validators.compose([Validators.required, Validators.pattern(/^\+*[0-9\s]*$/)]))
      },
      {
        name: 'createPatientAccount',
        control: this.formBuilder.control(false)
      },
      {
        name: 'notifyLma',
        control: this.formBuilder.control(false)
      },
      {
        name: 'cguAgreement',
        control: this.formBuilder.control(false, Validators.compose([Validators.requiredTrue]))
      },
      {
        name: 'privacyPolicyAgreement',
        control: this.formBuilder.control(false, Validators.compose([Validators.requiredTrue]))
      }
    ];
    controls.forEach(control => this.formGroup.addControl(control.name, control.control));

    this.customFields = this.bookingService.initCustomFields(this.formGroup, ['matricule', 'patient', 'questions']);

    // If user is rescheduling, we autofill the form with the data of the appointment
    if (this.isReschedule) {
      const rescheduleData = this.calendarService.appointmentReschedule,
        birthday = rescheduleData.patientBirthday,
        phone = rescheduleData.userMobile;

      this.bookingService.fillFormWithData(this.formGroup, Object.assign(rescheduleData, {
        patientBirthday: birthday ? DateTime.fromFormat(birthday.date, 'yyyy-LL-dd TT') : '',
        userMobile: phone ? phone.replace(/\s/g, '') : '',
        terms: true
      }), true);
    }

    // If userData we fill the form with the data
    if (Object.keys(this.userData).length) {
      this.bookingService.fillFormWithData(this.formGroup, this.userData, !this.isLoggedPatient);
    }
  }

  /**
   * After view init
   */
  ngAfterViewInit(): void {
    if (this.customFields.matricule || this.userData) {
      const isUltragenda = this.calendarService.selectedAgenda.doctor.ultragendaGateway;
      this.initialMatriculeCountry = isUltragenda ? 'be' : this.countryIso;
      this.formGroup.addControl('matriculeIso', new UntypedFormControl(this.initialMatriculeCountry, Validators.required));

      if (this.isReschedule) {
        const rescheduleData = this.calendarService.appointmentReschedule;
        this.initialMatriculeCountry = rescheduleData.matriculeIso || this.initialMatriculeCountry;
      }
    }

    if (this.elementRef.nativeElement.querySelector('.coreProcessing')) {
      this.elementRef.nativeElement
        .querySelector('.coreProcessing')
        .addEventListener('click', this.toggleCoreProcessing.bind(this));
    }

    if (this.bookingService.patientData === null || this.bookingService.patientData === undefined) {
      this.bookingService.patientDataSubject.subscribe({
        next: (patientData) => {
          this.isLoggedPatient = true;
          this.bookingService.fillFormWithData(this.formGroup, patientData);
        }
      });
    }

    this.ref.detectChanges();
  }

  /**
   * Check if Bingli form was filled
   */
  isBingli() {
    return (this.bookingService.hasOwnProperty('surveyId') && this.bookingService.surveyId != null);
  }

  /**
   * Get mabel for other
   */
  getGenderOtherLabel() {
    const isoMatricule = ['de'];

    return '__gender_other' + (isoMatricule.includes(this?.countryIso?.toLowerCase()) ? `_${this.countryIso}` : '');
  }

  /**
   * Called when user modify the birthday input
   *
   * @param e
   */
  onDateChange(e) {
    const newValue = DateTime.fromFormat(e.targetElement.value, `dd${this.datePickerDelimeter}LL${this.datePickerDelimeter}yyyy`);
    this.bookingService.manageBirthdayDate(this.formGroup, newValue);
  }

  /**
   * Get mabel for matricule input
   */
  getMatriculeLabel() {
    const isoMatricule = ['be', 'de', 'nl', 'ch'];

    // @ts-ignore
    if (this.customFields?.matricule?.fields[0]?.ui_properties?.label) {
      // @ts-ignore
      return this.customFields.matricule.fields[0].ui_properties.label;
    }

    return '__form_appointment_matricule' + (isoMatricule.includes(this.countryIso.toLowerCase()) ? `_${this.countryIso}` : '');
  }

  /**
   * Check if the slot is one day or more
   *
   * @returns {boolean}
   */
  isSlotInOneDay() {
    const slot = this.calendarService.selectedSlot,
      now = DateTime.now(),
      start = slot.start,
      diff = start.diff(now, 'hours').toObject();

    return diff.hours > 24;
  }

  private toggleCoreProcessing(e) {
    e.preventDefault();
    this.isShowCoreProcessing = !this.isShowCoreProcessing;
    return this.isShowCoreProcessing;
  }

  /**
   * On click for next step
   */
  onClickNext() {
    this.isPristine = true;
  }
}
