<div *ngIf="false" xmlns="http://www.w3.org/1999/html">
  <input type="hidden" i18n-placeholder="@@__booking_file_upload_title"
         placeholder="{{ '__booking_file_upload_title' | translate}}">
  <input type="hidden" i18n-placeholder="@@__booking_file_upload_step"
         placeholder="{{ '__booking_file_upload_step' | translate}}">
</div>

<div class="file-upload-block" [matBadge]="'__booking_file_upload_title' | translate" matBadgePosition="before"
     matBadgeSize="medium" style="margin-top: 20px;">
  <mat-card>
    <mat-card-content>
      <span class="restriction_note">{{ '__file_type_restrictions_notice' | translate }}</span>
      <mat-list>
        <mat-list-item *ngFor="let fileCategory of fileCategories" style="height: auto">
          <app-file-upload [fileMaxNumber]="5" [fileAccept]="'image/heic,image/jpeg,image/png,application/pdf'"
                           [appearance]="'outline'" [uploadGroup]="formGroup"
                           [required]="fileCategory.mandatory"
                           [placeholder]="fileCategory.categoryTranslations[this.currentLang] ?? (fileCategory.categoryTranslations['en'] ?? fileCategory.category)"
                           [description]="fileCategory.descriptionTranslations[this.currentLang] ?? (fileCategory.descriptionTranslations['en'] ?? null)"
                           [controlName]="'fileUpload_' + fileCategory.category + '##' + fileCategory.fileAttachmentSettingId"></app-file-upload>
        </mat-list-item>
      </mat-list>
      <div class="col-12 action-button-block">
        <button class="btn-secondary float-left" mat-button matStepperPrevious
                i18n="@@__label_back">{{ '__label_back' | translate }}
        </button>
        <button class="btn-success float-right" mat-button matStepperNext (click)="onClickNext()"
                i18n="@@__label_next">{{ '__label_next' | translate }}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
