import {Component, Input, OnInit} from '@angular/core';
import {FileValidator, MaterialFileInputModule} from 'ngx-material-file-input';
import {MatIcon} from '@angular/material/icon';
import {MatError, MatFormField, MatHint, MatLabel, MatSuffix} from '@angular/material/form-field';
import {TranslateModule} from '@ngx-translate/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {NgClass, NgForOf, NgIf} from '@angular/common';
import {MatIconButton} from '@angular/material/button';
import {TranslatePipe} from '../pipe/translate/translate.pipe';
import {SprintfPipe} from '../pipe/sprintf/sprintf.pipe';

@Component({
  selector: 'app-file-upload',
  standalone: true,
  imports: [
    MaterialFileInputModule,
    MatIcon,
    MatSuffix,
    TranslateModule,
    MatLabel,
    MatFormField,
    MatHint,
    ReactiveFormsModule,
    NgIf,
    MatIconButton,
    MatError,
    NgForOf,
    TranslatePipe,
    SprintfPipe,
    NgClass
  ],
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss'
})
export class FileUploadComponent implements OnInit {

  /**
   * Count files
   */
  selectedFiles: File[] = [];
  /**
   * File max number
   */
  @Input() fileMaxNumber = 1;

  /**
   * File max size
   */
  @Input() fileMaxSize = 10 * 1024 * 1024; // 5MB

  /**
   * File accept
   */
  @Input() fileAccept = 'image/*';

  /**
   * Required field
   */
  @Input() required = false;

  /**
   * Form control name
   */
  @Input() controlName = 'fileUpload';

  /**
   * Placeholder
   */
  @Input() placeholder: string | null = '__file_upload_placeHolder';

  /**
   * Description
   */
  @Input() description: string | null = '';

  /**
   * Appearance of the file input
   */
  @Input() appearance: 'fill' | 'outline' = 'fill';

  /**
   * Accept upload of multiple files
   */
  acceptMultipleFiles = this.fileMaxNumber > 1;

  /**
   * Form for file upload
   */
  @Input() uploadGroup: FormGroup = new FormGroup<any>({});

  /**
   * Boolean to know if the user try to upload too much files
   */
  tooMuchFiles = false;

  /**
   * Boolean to know if the file is too big
   */
  fileTooBig = false;

  /**
   * Constructor
   */
  constructor(private formBuilder: FormBuilder) {
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.acceptMultipleFiles = this.fileMaxNumber > 1;

    const validators = [];
    // Add required validator
    if (this.required) {
      validators.push(Validators.required);
    }

    // Add file size validator
    if (this.fileMaxSize) {
      validators.push(FileValidator.maxContentSize(this.fileMaxSize));
    }

    // Add file number validator
    if (this.fileMaxNumber) {
      validators.push(Validators.max(this.fileMaxNumber));
    }

    this.uploadGroup.addControl(this.controlName, this.formBuilder.control(null, validators));
  }

  /**
   * On change event
   *
   * @param event
   */
  onChangeEvent(event: any) {
    this.selectedFiles = [...this.selectedFiles, ...(this.uploadGroup.get(this.controlName).value.files ?? [])];
    this.updateFromValue();
    event.target.value = null;
    this.checkErrors();
  }

  /**
   * Update form value
   *
   * @private
   */
  private updateFromValue() {
    // Update form value
    const fileNames: string = this.selectedFiles ?
        this.selectedFiles.map((file: File) => file.name).join(this.uploadGroup.get(this.controlName).value.delimiter) : '',
      patchedValue = this.selectedFiles.length > 0 ? {
        files: this.selectedFiles,
        fileNames: fileNames,
        delimiter: this.uploadGroup.get(this.controlName).value.delimiter
      } : null;
    this.uploadGroup.get(this.controlName)
      .patchValue(patchedValue);
  }

  /**
   * Check errors
   *
   * @private
   */
  private checkErrors() {
    this.tooMuchFiles = this.selectedFiles.length > this.fileMaxNumber;
    let errors = this.uploadGroup.get(this.controlName)?.errors ?? {};

    if (this.tooMuchFiles) {
      errors.maxFiles = true;
      this.uploadGroup.get(this.controlName).setErrors({maxFiles: true});
    } else {
      delete errors.maxFiles;
    }

    // Check file size
    this.fileTooBig = this.selectedFiles.some(file => file.size > this.fileMaxSize);
    if (this.fileTooBig) {
      errors.maxSize = true;
    } else {
      delete errors.maxSize;
    }

    /**
     * If there are no errors, set errors to null
     */
    if (Object.keys(errors).length === 0) {
      errors = null;
    }

    this.uploadGroup.get(this.controlName).setErrors(errors);
  }

  /**
   * Remove file
   *
   * @param file
   */
  removeFile(file: File) {
    // Remove file from selected files
    this.selectedFiles = this.selectedFiles.filter(selectedFile => selectedFile !== file);
    this.updateFromValue();
    this.checkErrors();
  }
}
