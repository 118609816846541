import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {FileUploadComponent} from '../file-upload/file-upload.component';
import {MatBadgeModule} from '@angular/material/badge';
import {MatCard, MatCardContent} from '@angular/material/card';
import {TranslatePipe} from '../pipe/translate/translate.pipe';
import {NgForOf, NgIf} from '@angular/common';
import {MatButton} from '@angular/material/button';
import {MatStepperNext, MatStepperPrevious} from '@angular/material/stepper';
import {CalendarService} from '../service/calendar/calendar.service';
import {MatList, MatListItem} from '@angular/material/list';

@Component({
  selector: 'app-booking-file-upload-step',
  standalone: true,
  imports: [
    FileUploadComponent,
    MatBadgeModule,
    MatCard,
    MatCardContent,
    TranslatePipe,
    NgIf,
    MatButton,
    MatStepperNext,
    MatStepperPrevious,
    NgForOf,
    MatList,
    MatListItem
  ],
  templateUrl: './booking-file-upload-step.component.html',
  styleUrl: './booking-file-upload-step.component.scss'
})
export class BookingFileUploadStepComponent implements OnInit {

  /**
   * Is pristine
   */
  isPristine: boolean;

  /**
   * Selected reason
   */
  selectedReason: any;

  /**
   * Current language
   */
  currentLang: string;

  /**
   * File categories
   */
  fileCategories: any[];

  constructor(public translateService: TranslateService,
              public calendarService: CalendarService,
              private formBuilder: FormBuilder) {
    this.isPristine = false;
    this.selectedReason = null;
    this.fileCategories = [];
    this.currentLang = this.translateService.currentLang;
  }

  /**
   * Form group
   */
  @Input() formGroup: FormGroup = this.formBuilder.group({});

  ngOnInit() {
    if (this.calendarService?.bookingParams['reasonOfVisit']) {
      this.calendarService.bookingParams['reasonOfVisit'].forEach((reason: any) => {
        if (this.calendarService.selectedAgenda.externalId === reason.agendaEid) {
          this.selectedReason = reason;
          this.fileCategories = reason?.fileAttachmentSettings ?? [];
        }
      });
    }
  }

  /**
   * On click for next step
   */
  onClickNext() {
    this.isPristine = true;
  }
}
