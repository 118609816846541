import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpService} from '../http/http.service';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Patient} from '../../models/patient';

@Injectable()
export class PatientService {

  patient: Patient;

  constructor(private httpService: HttpService) {
    this.patient = null;
  }

  me() {
    const apiUrl = environment.apiUrl;
    return this.httpService.get(apiUrl + '/me').pipe(
      catchError(this.handleError<any>('me'))
    );
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
