import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpService} from '../http/http.service';
import {Subject} from 'rxjs/internal/Subject';
import {AbstractControl, FormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {forEach} from 'lodash';
import {DateTime} from 'luxon';
import {Appointment, QuestionAnswer} from '../../models/appointment';
import {CustomField, CustomFieldGroup} from '../../models/customFields';
import {CalendarService} from '../calendar/calendar.service';


@Injectable()
export class BookingService extends HttpService {
  patientData: any = null;

  /**
   * Survey id from Bingli
   */
  surveyId: any = null;

  /**
   * Current appointment
   */
  currentAppointment: Appointment;

  /**
   * Subject to notify the patient data
   */
  patientDataSubject = new Subject<any>();

  /**
   * Formatted user data
   */
  formattedUserData: any = {};

  /**
   * Subject to notify the formatted user data
   */
  formattedUserDataSubject = new Subject<any>();

  /**
   * Majority age min
   */
  majorityAgeMin: number;

  constructor(http: HttpClient,
              public calendarService: CalendarService) {
    super(http);
  }

  setPatientData(patientData: any) {
    this.patientData = patientData;
    this.patientDataSubject.next(patientData);
  }

  setFormattedUserData(formattedUserData: any) {
    this.formattedUserData = formattedUserData;
    this.formattedUserDataSubject.next(formattedUserData);
  }

  setSurveyId(surveyId: any) {
    this.surveyId = surveyId;
  }

  /**
   * Call to resend a sms code
   *
   * @param resendSmsCode
   * @param callback
   * @param callbackError
   */
  resendSmsCode(resendSmsCode, callback?, callbackError?) {

    this.post(this.apiUrl + '/resendSmsCode/' + resendSmsCode, {})
      .subscribe(data => {
        if (callback) {
          callback(data);
        }
      }, error => {
        if (callbackError) {
          callbackError(error);
        }
      });
  }

  /**
   * Call to get all languages display on doctena
   *
   * @param callback
   * @param callbackError
   */
  getSystemLanguages(callback?, callbackError?) {

    let params = new HttpParams();
    params = params.append('system', '1');

    this.get(this.apiUrl + '/languages', params)
      .subscribe(data => {
        if (callback) {
          callback(this.stripUselessData(data));
        }
      }, error => {
        if (callbackError) {
          callbackError(error);
        }
      });
  }

  /**
   * This function allow to fill the form with an object of data
   *
   * @param formGroup
   * @param data
   * @param disableForms
   */
  fillFormWithData(formGroup: FormGroup, data: any, disableForms = false) {

    // In each formGroups, we set the value of each control (or input) with the
    // corresponding value of our 'data' object
    forEach(formGroup.controls, (control, controlKey) => {
      const splitControlKey = controlKey.split('.'),
        splitQuestionKey = controlKey.split('question_');

      // If data are empty, we want to reset all to the initial value
      if (!data) {
        this.cleanControls(controlKey, control);
      }

      if (data.patientBirthday !== '' || data.patientBirthday !== null) {
        // force to check the birthday to know if the guardian fields should be displayed
        this.manageBirthdayDate(formGroup, data.patientBirthday);
      }

      this.manageControlsValue(data, controlKey, splitControlKey, splitQuestionKey, control, disableForms);
    });
  }

  /**
   * Use custom fields returned by the CPP API and integrate them in our view
   */
  initCustomFields(formGroup: FormGroup, modules: string[]) {
    const customFields = this.calendarService.customFields,
      countryIso = this.calendarService?.selectedAgenda?.practice?.country_iso;

    Object.keys(customFields).forEach((fieldsetKey) => {
      if (fieldsetKey === '_links') {
        return;
      }

      const fieldset = (customFields[fieldsetKey] ? customFields[fieldsetKey] : {}).fields || [];
      fieldset.forEach((field) => {

        // Iterate on elements contained inside a group
        const group = field as CustomFieldGroup;
        if (group.group_label) {

          // Format group name for submission to API
          const groupNameSplit = group.name.split('.'),
            groupName = groupNameSplit.length > 1 ? groupNameSplit.reduce((pre, cur, i) => {
              return pre + (i > 0 ? cur.replace(/./, cur[0].toUpperCase()) : cur);
            }) : groupNameSplit[0];
          group.name = groupName;

          group.elements.forEach((el) => {
            this.initCustomField(el, fieldsetKey, modules, formGroup, groupName);
          });
          // If not a group, send it
        } else {
          this.initCustomField(field as CustomField, fieldsetKey, modules, formGroup);
        }
      });
    });

    // Adding matriculeIso if matricule is part of the custom fields
    if (formGroup.value.matricule !== undefined) {
      const isUltragenda = this.calendarService.selectedAgenda.doctor.ultragendaGateway,
        initialValue = isUltragenda ? 'be' : countryIso;
      formGroup.addControl('matriculeIso', new UntypedFormControl(initialValue, Validators.required));
    }

    return this.calendarService.customFields;
  }

  /**
   * Init custom field by checking which validators it needs
   *
   * @param customField  CustomField  Custom Field to init
   * @param fieldsetKey  string  correspond to the place in the view the custom field should appear
   * @param modules
   * @param formGroup
   * @param groupName  string  formatted group name in case the field belongs to a group
   */
  private initCustomField(customField: CustomField, fieldsetKey: string, modules: string[], formGroup: FormGroup, groupName?: string) {

    // Check if the custom field is part of the modules
    if (modules.indexOf(fieldsetKey) === -1) {
      return;
    }

    // Format the name so it can later be processed by the API when submitted
    const fieldNameSplit = customField.name.split('.'),
      fieldName = fieldNameSplit[fieldNameSplit.length - 1],
      validators = [];

    customField.name = (groupName ? groupName + '.' : '') + fieldName;

    // Check minimum number of characters validator
    if (customField?.ui_properties.min) {
      validators.push(Validators.minLength(customField.ui_properties.min));
    }

    // Check maximum number of characters validator
    if (customField?.ui_properties.max) {
      validators.push(Validators.maxLength(customField.ui_properties.max));
    }

    // Check required validator
    if (customField?.validators.required) {
      validators.push(customField.ui_properties.type === 'checkbox' ? Validators.requiredTrue : Validators.required);
    }

    // Check pattern validator (compare input to a regex)
    if (customField?.validators.pattern) {
      validators.push(Validators.pattern(customField.validators.pattern));
    }

    // Then add a control to the associated form
    formGroup.addControl(customField.name, new UntypedFormControl('', Validators.compose(validators)));
  }

  /**
   * Manage controls value
   *
   * @param data
   * @param controlKey
   * @param splitControlKey
   * @param splitQuestionKey
   * @param control
   * @param disableForms
   * @private
   */
  private manageControlsValue(data: any, controlKey: string, splitControlKey: string[], splitQuestionKey: string[],
                              control: AbstractControl, disableForms: boolean) {
    // Otherwise if we have data, we search for the right value to apply to form
    let value;
    switch (true) {
      case ((typeof (data[controlKey]) === 'boolean') && controlKey === 'returningPatient'):
        value = data[controlKey].toString();
        break;
      case (splitControlKey.length === 2): // If it's a nested object from a customField
        value = (data[splitControlKey[0]] ? data[splitControlKey[0]] : {})[splitControlKey[1]];
        break;
      case (splitQuestionKey.length === 2): { // If it's an answer to a custom question, data is a bit harder to fetch
        const answer = (data.customQuestionsAnswers ? data.customQuestionsAnswers : []).find((ans: QuestionAnswer) => {
          return ans.question_eid === splitQuestionKey[1];
        });
        value = ((answer ? answer : {}).answer || {}).value;
        break;
      }
      default:
        value = data[controlKey];
        break;
    }

    // If we have a value, we apply it to the form and disable the input
    if (value) {
      control.patchValue(value);
      if (disableForms) {
        control.disable({onlySelf: true, emitEvent: false});
      }
    }
  }

  /**
   * Called when user modify the birthday input
   *
   * @param formGroup
   * @param newValue
   */
  manageBirthdayDate(formGroup: FormGroup, newValue: DateTime) {
    const mandatoryKeys = ['patientBirthday'];
    if (mandatoryKeys.every(key => Object.keys(formGroup.controls).includes(key))) {
      const jsDate = newValue && (typeof newValue.toJSDate === 'function') ? newValue.toJSDate() : newValue;
      // We synchronize the date picker with the input value
      formGroup.controls.patientBirthday.patchValue(jsDate);

      // We determine if the patient is minor or major
      const conditionalInputs = ['userFirstName', 'userLastName'],
        momentMajorityAgeMin = DateTime.now().minus({years: this.majorityAgeMin});
      if ((newValue > momentMajorityAgeMin) && !formGroup.controls?.userFirstName) {
        // If the user switch from major birthday or no birthday to minor Birthday
        // We inject new inputs in the personnal group Form
        conditionalInputs.forEach((inputName) => {
          const newInput = new UntypedFormControl('', Validators.compose([Validators.required, Validators.pattern(/^.{2,}$/)]));
          formGroup.addControl(inputName, newInput);
        });

        return;
      }
      // If the user switch from minor birthday to major Birthday
      // We remove the user inputs from the personnal group Form
      conditionalInputs.forEach((inputName) => {
        formGroup.removeControl(inputName);
      });
    }
  }

  /**
   * Event on keyDown
   *
   * @param e
   * @param inputValue
   * @param canGoToNextStep
   */
  monitorKeyInput(e, inputValue?, canGoToNextStep?) {

    // In case it's coming from an sub-component's Output
    if (e.inputValue) {
      e = e.e;
      inputValue = e.inputValue;
    }

    const key = e.which || e.keyCode;
    // Prevent the enter key command for stepper, which brings back the user to the first
    // step of he stepper. Might probably be a bug linked to the fact the stepper is linear
    if (key === 13) {
      e.preventDefault();
      if (canGoToNextStep) {
        // @todo check if this is still needed
        // this.changeStep({selectedIndex: 3, previouslySelectedIndex: 2});
      }

      // Prevent using the spacebar as the first character of an input. It will
    } else if (key === 32 && !inputValue) {
      e.preventDefault();
    }
  }

  /**
   * Clean controls depending on their type
   *
   * @param controlKey
   * @param control
   * @private
   */
  private cleanControls(controlKey: string, control: AbstractControl) {
    switch (true) {
      // Checkboxes should be unticked
      case (['createPatientAccount', 'notifyLma'].indexOf(controlKey) > -1):
        control.reset(false);
        break;
      // Consultation data should not be resetted, but the rest should
      case (['reason', 'returningPatient', 'patientNote', 'isDoctenaUser'].indexOf(controlKey) === -1):
        control.reset('');
        break;
    }
  }
}
