<form class="flex-container" [formGroup]="consultationGroup" *ngIf="showPatientLoginForm">
  <!-- Login form -->
  <div class="formElement card-logout" *ngIf="!patientService.patient">

    <label class="col-12" for="login" i18n="@@__form_appointment_login_label">
      {{ '__form_appointment_login_label' | translate }}
    </label>

    <div class="login-form">
      <!-- User email -->
      <mat-form-field class="col-12 col-md-4 input item-flex">
        <input matInput type="email" id="login" i18n-placeholder="@@__form_appointment_email"
               placeholder="{{ '__form_appointment_email' | translate}}" formControlName="login" required>
        <mat-error *ngIf="consultationGroup.controls.login.hasError('email')">
          {{ '__legend_valid_email' | translate }}
        </mat-error>
      </mat-form-field>
      <!-- User password -->
      <mat-form-field class="col-12 col-md-4 d-inline-block input item-flex">
        <input matInput type="password" id="password" i18n-placeholder="@@__form_appointment_password"
               placeholder="{{ '__form_appointment_password' | translate}}" formControlName="password" required>
        <mat-error *ngIf="consultationGroup.controls.password.hasError('required')">
          {{ '__legend_field_required' | translate }}
        </mat-error>
      </mat-form-field>
      <button
        class="btn-success col-md-4 d-inline-block item-flex" mat-button
        (click)="login(consultationGroup.value.login, consultationGroup.value.password)"
        i18n="@@__form_appointment_login_button">
        {{ '__form_appointment_login_button' | translate }}
      </button>
    </div>

    <p class="col-12">
      <a rel="nofollow"
         [href]="urlChangePwd()"
         target="_blank"
         i18n="@@__form_appointment_forgotten_password">
        {{ '__form_appointment_forgotten_password' | translate }}
      </a></p>
  </div>

  <div class="formElement card-logged" *ngIf="patientService.patient">
    <!-- if user is logged in -->
    <div class="col-12">
            <span i18n="@@__label_user_logged_in">
              {{ '__label_user_logged_in' | translate }}
            </span>

      <a (click)="logout()"
         rel="nofollow" href="javascript:void(0)"
         i18n="@@__log_out">
        {{ '__log_out' | translate }}
      </a>
    </div>
  </div>
</form>
