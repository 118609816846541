import BlueBirdPromise from 'bluebird';
import { HttpClient, HttpParams } from '@angular/common/http';
import {HttpService} from '../http/http.service';
import {Injectable} from '@angular/core';

@Injectable()
export class BingliService extends HttpService {

  constructor(http: HttpClient) {
    super(http);
  }

  /**
   * Get Bingli survey
   * @param surveyId
   * @param params
   */
  getBingliSurvey(surveyId, params?: HttpParams) {
    return new BlueBirdPromise<any>((resolve, reject) => {
      if (typeof params === 'undefined') {
        params = new HttpParams();
      }
      return this.get(`${this.apiUrl}/bingli-feature/${surveyId}`, params)
        .subscribe({
          next: (data) => {
            resolve(this.stripUselessData(data));
          },
          error: (error) => reject(this.stripUselessData(error))
        });
    });
  }

  /**
   * Create Bingli survey
   * @param body
   */
  postBingliSurvey(body: any) {
    return new BlueBirdPromise<any>((resolve, reject) => {
      return this.post(this.apiUrl + '/bingli-feature', body)
        .subscribe({
          next: (data) => resolve(this.stripUselessData(data)),
          error: (error) => reject(this.stripUselessData(error))
        });
    });
  }

  /**
   * Post consent Bingli with survey
   * @param surveyId
   * @param body
   */
  postConsentBingliSurvey(surveyId, body) {
    return new BlueBirdPromise<any>((resolve, reject) => {
      return this.post(`${this.apiUrl}/bingli-consent/${surveyId}`, body)
        .subscribe({
          next: (data) => resolve(this.stripUselessData(data)),
          error: (error) => reject(this.stripUselessData(error))
        });
    });
  }
}
