import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  input,
  model,
  OnDestroy,
  OnInit,
  output,
  signal,
  viewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormControl,
  FormControlStatus,
  FormGroup, FormSubmittedEvent,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {MAT_SELECT_CONFIG, MatSelect, MatSelectModule} from '@angular/material/select';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/internal/operators/takeUntil';
import {take} from 'rxjs/internal/operators/take';
import {Observable} from 'rxjs/internal/Observable';
import {PhoneNumberFormat, PhoneNumberType, PhoneNumberUtil} from 'google-libphonenumber';
import {AsyncPipe, NgClass, NgTemplateOutlet} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatIcon} from '@angular/material/icon';
import {Country} from '../models/country';
import {CountryIsoEnum} from '../enums/country-iso.enum';
import {CountryCode} from '../../data/country-code';
import {GeoIpService} from '../service/geo-ip/geo-ip.service';
import {CountryDataService} from '../service/country-data/country-data.service';
import {TextLabels} from '../types/text-labels.type';
import {GeoData} from '../types/geo.type';
import {debounce} from 'lodash';
import {filter} from 'rxjs/internal/operators/filter';

@Component({
  selector: 'app-phone-input',
  standalone: true,
  imports: [
    AsyncPipe,
    MatSelectModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    NgClass,
    MatFormFieldModule,
    MatInputModule,
    NgTemplateOutlet,
    MatIcon
  ],
  templateUrl: './phone-input.component.html',
  styleUrl: './phone-input.component.scss',
  providers: [
    CountryCode,
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {overlayPanelClass: 'tel-mat-select-pane'}
    },
    GeoIpService,
    CountryDataService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneInputComponent implements OnInit, AfterViewInit, OnDestroy {

  /** control for the selected country prefix */
  public prefixCtrl: FormControl<Country | null> =
    new FormControl<Country | null>(null);

  /** control for the MatSelect filter keyword */
  public prefixFilterCtrl: FormControl<string | null> = new FormControl<
    string | null
  >('');

  /** list of countries filtered by search keyword */
  public filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<
    Country[]
  >(1);

  singleSelect = viewChild<MatSelect>('singleSelect');
  numberInput = viewChild<ElementRef>('numberInput');

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  allCountries: Country[] = [];
  phoneNumberUtil = PhoneNumberUtil.getInstance();

  formGroup = model<FormGroup | null>(new FormGroup({
    prefix: this.prefixCtrl,
    phone: new FormControl('')
  }));
  fieldControlName = input<string>('phone');
  required = model<boolean>(false);
  disabled = model<boolean>(false);
  enablePlaceholder = input<boolean>(true);
  autoIpLookup = input<boolean>(true);
  autoSelectCountry = input<boolean>(true);
  autoSelectedCountry = input<CountryIsoEnum | string>('');
  numberValidation = input<boolean>(true);
  iconMakeCall = input<boolean>(true);
  enableSearch = input<boolean>(true);
  includeDialCode = input<boolean>(false);
  preferredCountries = input<(CountryIsoEnum | string)[]>([]);
  visibleCountries = input<(CountryIsoEnum | string)[]>([]);
  excludedCountries = input<(CountryIsoEnum | string)[]>([]);
  textLabels = input<Partial<TextLabels>>({
    mainLabel: 'Phone number',
    codePlaceholder: 'Code',
    searchPlaceholderLabel: 'Search',
    noEntriesFoundLabel: 'No countries found',
    nationalNumberLabel: 'Number',
    hintLabel: 'Select country and type your phone number',
    invalidNumberError: 'Number is not valid',
    requiredError: 'This field is required'
  });
  currentValue = output<string>();
  isFocused = signal<boolean>(false);
  isLoading = signal<boolean>(true);
  handlePhoneNumberChange: any;

  constructor(
    private countryCodeData: CountryCode,
    private geoIpService: GeoIpService,
    private countryDataService: CountryDataService,
    private controlContainer: ControlContainer
  ) {
    effect(() => {
      this.setRequiredValidators();
      this.setDisabledState();
    });

    this.handlePhoneNumberChange = debounce((e) => {
    });
  }

  /**
   * Initialize the component and perform necessary setup tasks.
   *
   */
  ngOnInit(): void {
    if (!this.formGroup().get('prefix')) {
      this.formGroup().addControl('prefix', this.prefixCtrl);
    }

    this.fetchCountryData();
    this.addValidations();
    // load the initial countries list
    this.filteredCountries.next(this.allCountries.slice());
    // listen for search field value changes
    this.prefixFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountries();
      });
    this.startTelFormValueChangesListener();
    // this.startPrefixValueChangesListener();
    this.startFieldControlValueChangesListener();
    this.startFieldControlStatusChangesListener();

    setTimeout(() => {
      this.setInitialTelValue();
    });
  }

  /**
   * Fetches country data and populates the allCountries array.
   */
  protected fetchCountryData(): void {
    this.allCountries = this.countryDataService.processCountries(
      this.countryCodeData,
      this.enablePlaceholder(),
      this.includeDialCode(),
      this.visibleCountries(),
      this.preferredCountries(),
      this.excludedCountries()
    );
  }

  /**
   * Adds validations to the form field based on the current configuration.
   * It sets required validators and disabled state, and if number validation is enabled,
   * it adds a custom validator to check the validity of the phone number.
   */
  private addValidations(): void {
    this.setRequiredValidators();
    this.setDisabledState();

    if (this.numberValidation()) {
      this.handlePhoneNumberChange = debounce((e) => {
        this.isValidNumber();
      }, 150);
    }
  }

  /**
   * Validate number
   *
   * @private
   */
  private isValidNumber(): void {
    let errors = this.formGroup().get(this.fieldControlName())?.errors ?? {};
    try {
      if (!this.formGroup().get(this.fieldControlName())?.value) {
        return;
      }

      const parsed = this.phoneNumberUtil.parse(
        this.formGroup().get(this.fieldControlName())?.value,
        this.formGroup().get('prefix')?.value?.iso2
      );

      if (this.includeDialCode()) {
        const countryDialCode =
          this.formGroup().get('prefix')?.value?.dialCode || parsed.getCountryCode();
        if (countryDialCode) {
          this.setPrefixControlValue(countryDialCode);
        }
      }

      const formattedOnlyNumber = this.phoneNumberUtil.format(
        parsed,
        this.includeDialCode() || this.formGroup().get('prefix')?.value?.iso2 === 'mp'
          ? PhoneNumberFormat.INTERNATIONAL
          : PhoneNumberFormat.NATIONAL
      );

      this.formGroup()
        .get(this.fieldControlName())
        ?.setValue(formattedOnlyNumber, {emitEvent: false});

      const isValidNumber = this.phoneNumberUtil.isValidNumber(parsed);

      if (
        parsed.getCountryCode() &&
        parsed.getCountryCode()?.toString() !==
        this.formGroup().get('prefix')?.value?.dialCode
      ) {
        this.setPrefixControlValue(parsed.getCountryCode());
      }

      if (!isValidNumber) {
        errors.invalidNumber = true;
      } else {
        delete errors.invalidNumber;
      }

      // Validate mobile number
      if (isValidNumber) {
        const isValidMobileNumber = this.phoneNumberUtil.getNumberType(parsed) === PhoneNumberType.MOBILE;

        if (!isValidMobileNumber) {
          errors.invalidMobileNumber = true;
        } else {
          delete errors.invalidMobileNumber;
        }
      }

      /**
       * If there are no errors, set errors to null
       */
      if (Object.keys(errors).length === 0) {
        errors = null;
      }

      this.formGroup().get(this.fieldControlName())?.setErrors(errors);
      this.formGroup().get('prefix')?.setErrors(errors);
    } catch {
      errors.invalidNumber = true;
      this.formGroup().get(this.fieldControlName())?.setErrors(errors);
      this.formGroup().get('prefix')?.setErrors(errors);
    }
  }

  /**
   * Prefix control value
   *
   * @param countryDialCode
   * @private
   */
  private setPrefixControlValue(countryDialCode: number | string): void {
    const country = this.allCountries?.find(
      (c) => c.dialCode === `${countryDialCode}`
    );

    if (country) {
      this.formGroup().get('prefix').setValue(country, {emitEvent: false});
    }
  }

  /**
   * Sets the required validators for the field control based on the 'required' input property.
   * If 'required' is true, adds a 'Validators.required' validator to the field control.
   * If 'required' is false, removes the 'Validators.required' validator from the field control.
   */
  setRequiredValidators(): void {
    if (this.required()) {
      this.formGroup().get(this.fieldControlName())?.addValidators(Validators.required);
    }
  }

  /**
   * Sets the disabled state of the telForm and fieldControl based on the 'disabled' input property.
   * If 'disabled' is true, both telForm and fieldControl are disabled.
   * If 'disabled' is false, both telForm and fieldControl are enabled.
   */
  setDisabledState(): void {
    if (this.disabled()) {
      this.formGroup()?.disable();
      this.formGroup().get(this.fieldControlName())?.disable();
    } else {
      this.formGroup()?.enable();
      this.formGroup().get(this.fieldControlName())?.enable();
    }
  }

  /**
   * A lifecycle hook that is called after Angular has fully initialized a component's view.
   *
   * @return {void}
   */
  ngAfterViewInit(): void {
    this.setInitialPrefixValue();

    // Check if form has been submitted
    this.formGroup().events
      .pipe(filter((event: any) => event instanceof FormSubmittedEvent))
      .subscribe((event) => {
        this.isValidNumber();
        this.formGroup().get(this.fieldControlName())?.markAsTouched();
      });

    // Extend error on country dropdown
    this.formGroup().get(this.fieldControlName()).events
      .subscribe((event) => {
        this.formGroup().get('prefix')?.setErrors(this.formGroup().get(this.fieldControlName()).errors);
      });
  }

  /**
   * Method called when the component is destroyed.
   *
   */
  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Performs a geo IP lookup and sets the prefix control value based on the country retrieved.
   */
  private geoIpLookup(): void {
    this.geoIpService.geoIpLookup().subscribe({
      next: (data: GeoData) => {
        const country =
          this.allCountries?.find(
            (c) => c.iso2 === data.country_code?.toLowerCase()
          ) || null;
        if (country) {
          this.formGroup().get('prefix').setValue(country);
        } else {
          this.setAutoSelectedCountry();
        }
      },
      error: () => {
        this.setAutoSelectedCountry();
      },
      complete: () => {
        this.isLoading.set(false);
      }
    });
  }

  /**
   * Sets the initial value after the filteredCountries are loaded initially
   */
  protected setInitialPrefixValue(): void {
    this.filteredCountries
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredCountries are loaded initially
        // and after the mat-option elements are available
        const singleSelectInstance = this.singleSelect() as MatSelect;
        singleSelectInstance.compareWith = (a: Country, b: Country) =>
          a && b && a.iso2 === b.iso2;
      });
  }

  /**
   * Method to filter the list of countries based on a search keyword.
   *
   */
  protected filterCountries(): void {
    if (!this.allCountries) {
      return;
    }
    // get the search keyword
    let search = this.prefixFilterCtrl.value || '';

    if (!search) {
      this.filteredCountries.next(this.allCountries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the countries
    this.filteredCountries.next(
      this.allCountries.filter(
        (country) => country?.name?.toLowerCase()?.indexOf(search) > -1
      )
    );
  }

  /**
   * A method that handles the focus event for the input.
   *
   */
  onInputFocus(): void {
    this.isFocused.set(true);
  }

  /**
   * A method that handles the blur event for the input.
   */
  onInputBlur(): void {
    this.isFocused.set(false);
  }

  /**
   * Listens for changes in the telForm value and updates the fieldControl accordingly.
   */
  private startTelFormValueChangesListener(): void {
    const valueChanges = this.formGroup().valueChanges as Observable<string>;

    valueChanges.pipe(takeUntil(this._onDestroy))
      .subscribe((data: any) => {
        if (data[this.fieldControlName()] && data[this.fieldControlName()].value) {
          this.formGroup().get(this.fieldControlName())?.markAsDirty();
          let value = data[this.fieldControlName()];

          if (
            data?.prefix?.dialCode &&
            !this.includeDialCode() &&
            data?.prefix?.iso2 !== 'mp'
          ) {
            value = '+' + data.prefix.dialCode + data[this.fieldControlName()];
          }

          try {
            const parsed = this.phoneNumberUtil.parse(
                value,
                data?.prefix?.iso2
              ),
              formatted = this.phoneNumberUtil.format(
                parsed,
                PhoneNumberFormat.INTERNATIONAL
              );

            this.formGroup().get(this.fieldControlName())?.setValue(formatted);
          } catch (error) {
            this.formGroup().get(this.fieldControlName())?.setValue(value);
          }
        }
      });
  }

  /**
   * When we change the country we change only the prefix in the form control
   */
  onCountryChange(event: any): void {
    if (this.includeDialCode() && event.value?.dialCode) {
      this.formGroup()
        .get(this.fieldControlName())
        ?.setValue('+' + event.value?.dialCode, {emitEvent: false});
      this.formGroup()
        .get(this.fieldControlName()).markAsUntouched();
      this.formGroup().get(this.fieldControlName()).markAsPristine();
      this.isValidNumber();
    }

    if (!this.isLoading()) {
      setTimeout(() => {
        this.numberInput()?.nativeElement?.focus();
      });
    }
  }

  /**
   * Sets the initial telephone value based on the initial value.
   */
  private setInitialTelValue(): void {
    if (!this.formGroup().get(this.fieldControlName()).value) {
      // set initial selection
      if (this.autoSelectCountry()) {
        if (this.autoIpLookup()) {
          this.geoIpLookup();
        } else {
          this.setAutoSelectedCountry();
          this.isLoading.set(false);
        }
      } else {
        this.isLoading.set(false);
      }
    } else {
      try {
        const parsedNumber = this.phoneNumberUtil.parse(this.formGroup().get(this.fieldControlName()).value),
          countryCode = parsedNumber.getCountryCode(),
          country = this.allCountries?.find(
            (c) => c.dialCode === `${countryCode}`
          );

        if (country) {
          this.formGroup().get('prefix').setValue(country);
        }
        const nationalNumber =
          parsedNumber?.getNationalNumber()?.toString() || '';
        if (nationalNumber) {
          this.formGroup().get(this.fieldControlName())?.setValue(nationalNumber);
          this.formGroup().get(this.fieldControlName())?.markAsTouched();
        }
      } catch {
        this.formGroup().get(this.fieldControlName())?.setValue(this.formGroup().get(this.fieldControlName()).value);
        this.formGroup().get(this.fieldControlName())?.markAsDirty();
      } finally {
        this.isLoading.set(false);
      }
    }
  }

  /**
   * Set the auto selected country based on the specified criteria.
   *
   */
  private setAutoSelectedCountry(): void {
    const autoSelectedCountry = this.allCountries?.find(
      (country) => country?.iso2 === this.autoSelectedCountry()
    );
    if (autoSelectedCountry) {
      this.formGroup().get('prefix').setValue(autoSelectedCountry);
    } else {
      const defaultCountry = this.allCountries?.find(
        (country) => country?.iso2 === CountryIsoEnum.Luxembourg
      );
      if (defaultCountry) {
        this.formGroup().get('prefix').setValue(defaultCountry);
      } else {
        this.formGroup().get('prefix').setValue(this.allCountries?.[0]);
      }
    }
  }

  /**
   * Listens to changes in the field control value and updates it accordingly.
   * If the value is valid, it parses and formats it using the phoneNumberUtil.
   * If the value is not valid, it sets the value as is.
   * Finally, emits the currentValue signal with the updated field control value.
   */
  private startFieldControlValueChangesListener(): void {
    const valueChanges = this.formGroup().get(this.fieldControlName())
      ?.valueChanges as Observable<string>;

    valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((data: string) => {
      if (data) {
        try {
          const parsed = this.phoneNumberUtil.parse(
            data,
            this.formGroup().get('prefix')?.value?.iso2
          );
          const formatted = this.phoneNumberUtil.format(
            parsed,
            PhoneNumberFormat.INTERNATIONAL
          );
          this.formGroup().get(this.fieldControlName())?.setValue(formatted, {emitEvent: false});
        } catch {
          this.formGroup().get(this.fieldControlName())?.setValue(data, {emitEvent: false});
        }
      }
      this.currentValue?.emit(this.formGroup().get(this.fieldControlName())?.value || data);
    });
  }

  /**
   * Listens to changes in the status of the field control and updates the 'disabled' model accordingly.
   * If the status is 'DISABLED', sets the 'disabled' model to true; otherwise, sets it to false.
   */
  private startFieldControlStatusChangesListener(): void {
    const statusChanges = this.formGroup().get(this.fieldControlName())
      ?.statusChanges;

    statusChanges.pipe(takeUntil(this._onDestroy))
      .subscribe((status: FormControlStatus) => {
        if (status === 'DISABLED') {
          this.disabled.set(true);
        } else {
          this.disabled.set(false);
        }
      });
  }
}
