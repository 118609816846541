import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  input,
  model,
  OnDestroy,
  OnInit,
  output,
  signal,
  viewChild
} from '@angular/core';
import {
  FormControl,
  FormControlStatus,
  FormGroup, FormSubmittedEvent,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {Country} from '../models/country';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {MAT_SELECT_CONFIG, MatSelect, MatSelectModule} from '@angular/material/select';
import {Subject} from 'rxjs/internal/Subject';
import {CountryIsoEnum} from '../enums/country-iso.enum';
import {TextLabels} from '../types/text-labels.type';
import {CountryCode} from '../../data/country-code';
import {GeoIpService} from '../service/geo-ip/geo-ip.service';
import {CountryDataService} from '../service/country-data/country-data.service';
import {takeUntil} from 'rxjs/internal/operators/takeUntil';
import {take} from 'rxjs/internal/operators/take';
import {filter} from 'rxjs/internal/operators/filter';
import {Observable} from 'rxjs/internal/Observable';
import {AsyncPipe, NgClass, NgTemplateOutlet} from '@angular/common';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIcon} from '@angular/material/icon';
import {AppointmentService} from '../service/appointment/appointment.service';
import {debounce} from 'lodash';

@Component({
  selector: 'app-matricule-input',
  standalone: true,
  imports: [
    AsyncPipe,
    MatSelectModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    NgClass,
    MatFormFieldModule,
    MatInputModule,
    NgTemplateOutlet,
    MatIcon
  ],
  templateUrl: './matricule-input.component.html',
  styleUrl: './matricule-input.component.scss',
  providers: [
    CountryCode,
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {overlayPanelClass: 'matricule-mat-select-pane'}
    },
    GeoIpService,
    CountryDataService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatriculeInputComponent implements OnInit, AfterViewInit, OnDestroy {

  /** control for the selected country prefix */
  public countryIso: FormControl<Country | null> =
    new FormControl<Country | null>(null);

  /** control for the MatSelect filter keyword */
  public countryIsoFilter: FormControl<string | null> = new FormControl<
    string | null
  >('');

  /** list of countries filtered by search keyword */
  public filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<
    Country[]
  >(1);

  singleSelect = viewChild<MatSelect>('singleSelect');
  matriculeInput = viewChild<ElementRef>('matriculeInput');

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  allCountries: Country[] = [];

  formGroup = model<FormGroup | null>(new FormGroup({
    matriculeIso: new FormControl(null),
    matricule: new FormControl('')
  }));
  fieldControlName = input<string>('');
  required = model<boolean>(false);
  disabled = model<boolean>(false);
  enablePlaceholder = input<boolean>(true);
  autoSelectCountry = input<boolean>(true);
  autoSelectedCountry = input<CountryIsoEnum | string>('');
  initialValue = model<string>('');
  enableSearch = input<boolean>(true);
  maxLength = input<string | number | null>(null);
  minLength = input<string | number | null>(null);
  preferredCountries = input<(CountryIsoEnum | string)[]>([]);
  visibleCountries = input<(CountryIsoEnum | string)[]>([]);
  excludedCountries = input<(CountryIsoEnum | string)[]>([]);
  textLabels = input<Partial<TextLabels>>({
    mainLabel: 'Matricule',
    maxLengthError: 'Invalid max length',
    minLengthError: 'Invalid min length',
    invalidSsnError: 'Invalid pattern',
    requiredError: 'This field is required'
  });
  currentValue = output<string>();
  isFocused = signal<boolean>(false);
  isLoading = signal<boolean>(true);
  handleMatriculeChange: any;

  constructor(
    private countryCodeData: CountryCode,
    private countryDataService: CountryDataService,
    private ref: ChangeDetectorRef,
    public appointmentService: AppointmentService
  ) {
    effect(() => {
      this.setRequiredValidators();
      this.setDisabledState();
    });

    this.handleMatriculeChange = debounce((e) => {
      const matricule = this.formGroup().get('matricule'),
        otherErrors = matricule?.hasError('pattern') ||
          matricule?.hasError('maxlength') ||
          matricule?.hasError('minlength');
      if (!otherErrors) {
        const ssn = e.target.value,
          ssnIso = this.formGroup().get('matriculeIso').value;
        this.validateSsn(ssn, ssnIso);
      }
    }, 1000);
  }

  /**
   * Validate matricule
   *
   * @param ssn
   * @param ssnIso
   */
  private validateSsn(ssn: string, ssnIso: string) {
    let errors = this.formGroup().get('matricule')?.errors;
    this.appointmentService.validateSsn(ssn, ssnIso)
      .then((isSsnValid: boolean) => {
        if (errors && !isSsnValid) {
          errors.invalidSsn = true;
        } else if (!isSsnValid) {
          errors = {invalidSsn: true};
        } else {
          errors = null;
        }

        this.isFocused.set(true);
        this.formGroup().get('matricule')?.setErrors(errors);
        this.isFocused.set(false);
      })
      .catch(() => {
        console.error('Error validating ssn');
      });
  }

  /**
   * Initialize the component and perform necessary setup tasks.
   *
   */
  ngOnInit(): void {
    this.fetchCountryData();
    this.addValidations();
    // load the initial countries list
    this.filteredCountries.next(this.allCountries.slice());
    // listen for search field value changes
    this.countryIsoFilter.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountries();
      });
    setTimeout(() => {
      this.setInitialValue();
    });
    this.startFieldControlValueChangesListener();
    this.startFieldControlStatusChangesListener();
  }

  /**
   * Fetches country data and populates the allCountries array.
   */
  protected fetchCountryData(): void {
    this.allCountries = this.countryDataService.processCountries(
      this.countryCodeData,
      this.enablePlaceholder(),
      false,
      this.visibleCountries(),
      this.preferredCountries(),
      this.excludedCountries()
    );
  }

  /**
   * Adds validations to the form field based on the current configuration.
   * It sets required validators and disabled state, and if number validation is enabled,
   * it adds a custom validator to check the validity of the phone number.
   */
  private addValidations(): void {
    this.setRequiredValidators();
    this.setDisabledState();
    const validators = [];

    if (this.maxLength()) {
      validators.push(Validators.maxLength(this.maxLength() as number));
    }

    if (this.minLength()) {
      validators.push(Validators.minLength(this.minLength() as number));
    }

    this.formGroup().get('matricule')?.addValidators(validators);
  }

  /**
   * Sets the required validators for the field control based on the 'required' input property.
   * If 'required' is true, adds a 'Validators.required' validator to the field control.
   * If 'required' is false, removes the 'Validators.required' validator from the field control.
   */
  setRequiredValidators(): void {
    if (this.required() && this.formGroup().get('matricule')?.hasValidator(Validators.required)) {
      this.formGroup().get('matricule')?.addValidators(Validators.required);
    }
  }

  /**
   * Sets the disabled state of the telForm and fieldControl based on the 'disabled' input property.
   * If 'disabled' is true, both telForm and fieldControl are disabled.
   * If 'disabled' is false, both telForm and fieldControl are enabled.
   */
  setDisabledState(): void {
    if (this.disabled()) {
      this.formGroup()?.disable();
      this.formGroup().get('matricule')?.disable();
    } else {
      this.formGroup()?.enable();
      this.formGroup().get('matricule')?.enable();
    }
  }

  /**
   * A lifecycle hook that is called after Angular has fully initialized a component's view.
   *
   * @return {void}
   */
  ngAfterViewInit(): void {
    this.setInitialPrefixValue();

    // Check if form has been submitted
    this.formGroup().events
      .pipe(filter((event: any) => event instanceof FormSubmittedEvent))
      .subscribe((event) => {
        this.formGroup().get('matricule')?.markAsTouched();
      });

    // Extend error on country dropdown
    this.formGroup().get('matricule').events
      .subscribe((event) => {
        this.formGroup().get('matriculeIso')?.setErrors(this.formGroup().get('matricule').errors);
      });
    this.ref.detectChanges();
  }

  /**
   * Method called when the component is destroyed.
   *
   */
  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredCountries are loaded initially
   */
  protected setInitialPrefixValue(): void {
    this.filteredCountries
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredCountries are loaded initially
        // and after the mat-option elements are available
        const singleSelectInstance = this.singleSelect() as MatSelect;
        singleSelectInstance.compareWith = (a: Country, b: Country) =>
          a && b && a.iso2 === b.iso2;
      });
  }

  /**
   * Method to filter the list of countries based on a search keyword.
   *
   */
  protected filterCountries(): void {
    if (!this.allCountries) {
      return;
    }
    // get the search keyword
    let search = this.countryIsoFilter.value || '';
    if (!search) {
      this.filteredCountries.next(this.allCountries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the countries
    this.filteredCountries.next(
      this.allCountries.filter(
        (country) => country?.name?.toLowerCase()?.indexOf(search) > -1
      )
    );
  }

  /**
   * A method that handles the focus event for the input.
   *
   */
  onInputFocus(): void {
    this.isFocused.set(true);
  }

  /**
   * A method that handles the blur event for the input.
   */
  onInputBlur(): void {
    this.isFocused.set(false);
  }

  onCountryChange(): void {
    const ssn = this.formGroup().get('matricule')?.value,
      ssnIso = this.formGroup().get('matriculeIso').value;
    this.validateSsn(ssn, ssnIso);
  }

  /**
   * Returns the country class based on the country ISO.
   *
   * @param countryIso
   */
  getCountryClass(countryIso: string): string {
    return this.allCountries.find((country) => country.iso2 === countryIso)?.flagClass || '';
  }

  /**
   * Sets the initial telephone value based on the initial value.
   */
  private setInitialValue(): void {
    if (!this.initialValue()) {
      // set initial selection
      if (this.autoSelectCountry()) {
        this.setAutoSelectedCountry();
        this.isLoading.set(false);
      } else {
        this.isLoading.set(false);
      }
    } else {
      this.setAutoSelectedCountry();
      this.formGroup().get('matricule')?.setValue(this.initialValue());
      this.formGroup().get('matricule')?.markAsDirty();
      this.isLoading.set(false);
    }
  }

  /**
   * Set the auto selected country based on the specified criteria.
   *
   */
  private setAutoSelectedCountry(): void {
    const autoSelectedCountry = this.allCountries?.find(
      (country) => country?.iso2 === this.autoSelectedCountry()
    );

    if (autoSelectedCountry) {
      this.formGroup().get('matriculeIso').setValue(autoSelectedCountry.iso2);
    } else {
      const defaultCountry = this.allCountries?.find(
        (country) => country?.iso2 === CountryIsoEnum.Luxembourg
      );
      if (defaultCountry) {
        this.formGroup().get('matriculeIso').setValue(defaultCountry.iso2);
      } else {
        this.formGroup().get('matriculeIso').setValue(this.allCountries?.[0].iso2);
      }
    }
  }

  /**
   * Listens to changes in the field control value and updates it accordingly.
   * If the value is valid, it parses and formats it using the phoneNumberUtil.
   * If the value is not valid, it sets the value as is.
   * Finally, emits the currentValue signal with the updated field control value.
   */
  private startFieldControlValueChangesListener(): void {
    const valueChanges = this.formGroup().get('matricule')
      ?.valueChanges as Observable<string>;

    valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((data: string) => {
      if (data) {
        // this.form.get('matricule')?.setValue(data);
        this.formGroup().get('matricule')?.setValue(data, {emitEvent: false});
      }
      this.currentValue?.emit(this.formGroup().get('matricule')?.value || data);
    });
  }

  /**
   * Listens to changes in the status of the field control and updates the 'disabled' model accordingly.
   * If the status is 'DISABLED', sets the 'disabled' model to true; otherwise, sets it to false.
   */
  private startFieldControlStatusChangesListener(): void {
    const statusChanges = this.formGroup().get('matricule')?.statusChanges;

    statusChanges.pipe(takeUntil(this._onDestroy))
      .subscribe((status: FormControlStatus) => {
        if (status === 'DISABLED') {
          this.disabled.set(true);
        } else {
          this.disabled.set(false);
        }
      });
  }
}
