import { Component, OnInit, Input } from '@angular/core';
import { ReviewsService } from '../service/reviews/reviews.service';
import { Review } from '../models/review';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-ratings',
  templateUrl: './ratings.component.html',
  styleUrls: ['./ratings.component.scss']
})
export class RatingsComponent implements OnInit {
  @Input() doctorEid: string;
  @Input() countryIso: string;
  reviews: Array<Review>;
  ratingsType: string;

  constructor(public reviewsService: ReviewsService) {
    this.reviews = [];

    reviewsService.reviews$.subscribe((newReviews: Array<Review>) => {
      this.reviews = newReviews;
      clearInterval(reviewsService.interval);
    });
  }

  ngOnInit() {
    // Get rating Type depending on the practice country iso and the configuration in the environment files
    this.ratingsType = environment.countryRatings[this.countryIso];
  }

  /**
   * Return an array of empty members, where its length correspond to the number of half stars the rating has.
   * It allows the template to iterate on something when displaying the half star image
   */
  getFullStarsNumber() {
    const gradeOutOf10 = Math.round(this.reviews[this.doctorEid].averageRating * 2);
    const fullStarsCount = new Array(
      Math.floor(gradeOutOf10/2)
    );

    if (fullStarsCount.length > 5) {
      console.error(
        'Error : The average rating of doctor with eid ' +
          this.doctorEid +
          ' is too damn high.'
      );
      return new Array(5);
    } else return fullStarsCount;
  }

  hasHalfStar() {
    return Math.round(this.reviews[this.doctorEid].averageRating * 2) % 2 === 1;
  }
}
